import React, { useEffect, useState } from "react";
import {
  Button,
  message,
  Select,
  Table,
  Drawer,
  Tabs,
  Tooltip,
  Dropdown,
  Menu,
  Modal,
  Tag
} from "antd";
import { useDispatch, useSelector } from "react-redux";
import {
  getJobApplicationAction,
  multipleAction
} from "../../../redux/actions/common";
import { updateInterviewDetailsAction } from "../../../redux/actions/interviewer";
import { TailSpin } from "react-loader-spinner";
import moment from "moment";
import { Timeline, Avatar, Badge } from "antd";
import InterviewDetailsDrawer from "../../InterviewScheduling/InterviewDetailsModal";
import { CandidateProfile } from "../../Dashboard/CandidateProfile/CandidateProfile";
import { EditOutlined, MoreOutlined, UserOutlined } from "@ant-design/icons";
import { JOB_INTERVIEW_STATUS } from "../../../Assets/data/common";
import { useNavigate } from "react-router-dom";
import InterviewScheduling from "../../InterviewScheduling";
import AssignBadge from "../modal/AssignBadge";
import { useIsMobileView } from "../../../Utils/CustomHooks/windowSize";

const { Option } = Select;

const { TabPane } = Tabs;
const { confirm } = Modal;

const DetailSection = ({ openDrawer, setOpenDrawer }) => {
  const dispatch = useDispatch();
  const { data, loading, error } = useSelector(
    (state) => state.getJobApplicationReducer
  );
  const assignToRecruiterReducer = useSelector(
    (state) => state.assignToRecruiterReducer
  );
  const updateInterviewDetailsReducer = useSelector(
    (state) => state.updateInterviewDetailsReducer
  );

  useEffect(() => {
    if (openDrawer?.apply_id) {
      dispatch(getJobApplicationAction({ apply_id: openDrawer?.apply_id }));
    }
    return () => {
      dispatch({ type: "GET_JOB_APPLICATION_RESET" });
    };
  }, [
    openDrawer,
    dispatch,
    updateInterviewDetailsReducer,
    assignToRecruiterReducer
  ]);

  useEffect(() => {
    if (!loading && !data && error) {
      message.error(error);
    }
  }, [loading, data, error]);
  useEffect(() => {
    const { data, error, loading } = assignToRecruiterReducer;
    if (!loading && data && !error) {
      message.success("Recruiter Updated!");
    }
    if (!loading && data && error) {
      message.error(error);
    }
  }, [loading, data, error]);
  useEffect(() => {
    const { data, error, loading } = updateInterviewDetailsReducer;
    if (!loading && data && !error) {
      message.success("Status Updated!");
    }
    if (!loading && data && error) {
      message.error(error);
    }
  }, [loading, data, error]);

  return (
    <Drawer
      title="Candidate Details"
      placement="right"
      width={useIsMobileView() ? "100%" : "65%"}
      onClose={() =>
        setOpenDrawer((prev) => ({
          ...prev,
          show: false,
          apply_id: ""
        }))
      }
      visible={openDrawer?.show}
      bodyStyle={{ padding: "20px" }}
    >
      {loading ? (
        <div
          style={{
            position: "fixed",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            zIndex: 9999
          }}
        >
          <TailSpin ariaLabel="Loading..." color="#005c53" />
        </div>
      ) : (
        <Tabs defaultActiveKey="profile">
          <TabPane tab="Profile" key="profile">
            <CandidateProfile
              showTabs={false}
              candidate_data={data?.candidate}
            />
          </TabPane>
          <TabPane tab="Interview" key="interview">
            <InterviewTab data={data} />
          </TabPane>
          <TabPane tab="TimeLine" key="timelines">
            <TimelineTab timelineData={data?.timeline_records} />
          </TabPane>
        </Tabs>
      )}
    </Drawer>
  );
};
const InterviewTab = ({ data }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [openInterviewModal, setOpenInterviewModal] = useState({
    show: false,
    data: {}
  });
  const [tableData, setTableData] = useState([]);
  const [openDrawer, setOpenDrawer] = useState({
    show: false,
    interview_id: ""
  });

  useEffect(() => {
    if (data?.interviews) {
      const formattedData = data.interviews.map((item, index) => ({
        interview_id: item.interview_id,
        key: item.interview_id,
        serialNumber: index + 1,
        externalAttendees: item.external_attendees || [],
        startDateTime: moment(item.startDateTime).format("YYYY-MM-DD HH:mm:ss"),
        endDateTime: moment(item.endDateTime).format("YYYY-MM-DD HH:mm:ss"),
        meetingUrl: item.meeting_url,
        status: item.status,
        summary: item.summary,
        interviewRound: item.interviewRound,
        assign_to: item.assignData
      }));
      setTableData(formattedData);
    } else {
      setTableData([]);
    }
  }, [data]);

  const multipleActionReducer = useSelector(
    (state) => state.multipleActionReducer
  );
  const updateInterviewDetailsReducer = useSelector(
    (state) => state.updateInterviewDetailsReducer
  );

  const handleMenuClick = (record) => (e) => {
    const sendReminder = () => {
      confirm({
        title: "Are you sure you want to send reminder to candidate?",
        onOk() {
          dispatch(
            multipleAction({
              action_type: "REMINDER_INTERVIEW",
              data: {
                interview_id: record?.interview_id
              }
            })
          );
        }
      });
    };
    const deleteInterview = () => {
      confirm({
        title: "Are you sure you want to delete interview?",
        onOk() {
          dispatch(
            updateInterviewDetailsAction({
              status: "delete",
              interview_id: record?.interview_id
            })
          );
        }
      });
    };

    switch (e.key) {
      case "view_details":
        setOpenDrawer({
          show: true,
          interview_id: record?.interview_id
        });
        break;
      case "edit_interview":
        setOpenInterviewModal((prev) => ({
          ...prev,
          show: true,
          data: {
            interview_id: record?.interview_id,
            client_id: record?.client_id
          }
        }));
        break;
      case "send_reminder":
        sendReminder();
        break;
      case "delete":
        deleteInterview();
        break;
      default:
        message.error("no found");
    }
  };

  const actionMenu = (record) => (
    <Menu onClick={handleMenuClick(record)} className="actions-menu">
      <Menu.Item key="edit_interview">Rescheduled/Edit Interview</Menu.Item>
      <Menu.Item key="send_reminder">Send Reminder</Menu.Item>
    </Menu>
  );

  useEffect(() => {
    const { loading, data, error } = multipleActionReducer;
    if (!loading && data && !error) {
      message.success(data?.message);
    }
    if (!loading && !data && error) {
      console.warn("Error:", error);
      message.error(error);
    }
  }, [multipleActionReducer]);

  const isMobile = window.innerWidth <= 768;

  const columns = [
    {
      title: "S.No",
      dataIndex: "serialNumber",
      key: "serialNumber",
      width: 80,
      ellipsis: true,
      sorter: (a, b) => a.serialNumber - b.serialNumber,
      sortDirections: ["ascend", "descend"],
      fixed: !isMobile ? "left" : undefined
    },
    {
      title: "Title",
      dataIndex: "summary",
      key: "summary",
      width: 250,
      sorter: (a, b) => a.summary.localeCompare(b.summary),
      sortDirections: ["ascend", "descend"],
      render: (summary, record) => (
        <span>
          <a
            className="text-decoration-underline cursor-pointer"
            style={{ cursor: "pointer", color: "#005c53" }}
            onClick={() => {
                setOpenDrawer({
                  show: true,
                  interview_id: record?.interview_id
                });
              }
            }
          >
            {summary}
          </a>
        </span>
      ),
      fixed: !isMobile ? "left" : undefined
    },
    {
      title: "Round",
      dataIndex: "interviewRound",
      key: "interviewRound",
      width: 80,
      sorter: (a, b) => a.interviewRound.localeCompare(b.interviewRound),
      sortDirections: ["ascend", "descend"]
    },
    {
      title: "Recruiter",
      dataIndex: "assign_to",
      key: "assign_to",
      width: 250,
      render: (text, record) => {
        const defaultData = {
          interview_id: record?.interview_id,
          assign_type: "interview"
        };
        const isErrorResponse =
          text?.success === false && text?.message && text?.statusCode;

        const dataToPass = isErrorResponse ? defaultData : text;

        return <AssignBadge data={dataToPass} client_id={record?.client_id} />;
      }
    },
    {
      title: "DateTime",
      key: "dateTime",
      sorter: (a, b) => new Date(a.startDateTime) - new Date(b.startDateTime),
      sortDirections: ["ascend", "descend"],
      render: (record) => {
        const startDate = new Date(record.startDateTime);
        const endDate = new Date(record.endDateTime);

        return `${moment(startDate).format("DD MMM YYYY, h:mm A")} - ${moment(
          endDate
        ).format("h:mm A")}`;
      },
      width: 300
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      width: 150,
      filters: JOB_INTERVIEW_STATUS,
      onFilter: (value, record) => record.status === value,
      render: (status, record) => {
        const handleStatusChange = (value) => {
          confirm({
            title: "Are you sure you want to update this status?",
            content: `Changing the status to ${value}`,
            okText: "Yes",
            cancelText: "No",
            okButtonProps: {
              loading: updateInterviewDetailsReducer?.loading
            },
            onOk() {
              dispatch(
                updateInterviewDetailsAction({
                  status: value,
                  interview_id: record.key
                })
              );
            },
            onCancel() {
              message.info("Action cancelled!");
            }
          });
        };

        return (
          <Select
            disabled={status === "cancelled"} // Disable the dropdown if status is "cancelled"
            defaultValue={status}
            style={{ width: "100%" }}
            onChange={handleStatusChange}
          >
            {JOB_INTERVIEW_STATUS.map((option) => (
              <Option key={option.value} value={option.value}>
                {option.text}
              </Option>
            ))}
          </Select>
        );
      }
    },
    {
      title: "",
      dataIndex: "key",
      fixed: !isMobile ? "right" : undefined,
      key: "key",
      render: (key, records) => (
        <>
          <Dropdown
            loading={multipleActionReducer?.loading}
            overlay={actionMenu({
              interview_id: records?.interview_id,
              client_id: records?.client_id
            })}
            disabled={records.status === "cancelled"} // Disable the three-dot menu if status is "cancelled"
          >
            <Button
              onClick={(e) => e.preventDefault()}
              shape="circle"
              icon={<MoreOutlined />}
            />
          </Dropdown>
        </>
      ),
      width: 80
    }
  ];

  return (
    <>
      <Table
        columns={columns}
        dataSource={tableData}
        pagination={{ pageSize: 5 }}
        bordered
        size="middle"
        scroll={{ x: 1300 }}
        footer={() => (
          <div style={{ textAlign: "center" }}>
            <a
              href="#"
              onClick={() =>
                setOpenInterviewModal((prev) => ({
                  ...prev,
                  show: true,
                  data: {
                    apply_id: data?.application?.apply_id,
                    candidate_id: data?.application?.candidate_id,
                    client_id: data?.application?.client_id,
                    job_id: data?.application?.job_id,
                    name: `${data?.candidate?.firstName} ${data?.candidate?.lastName}`,
                    job_title: data?.job?.job_title,
                    company_name: data?.client?.company_name
                  }
                }))
              }
              style={{
                color: "#00756a",
                textDecoration: "underline",
                fontWeight: "bold",
                cursor: "pointer"
              }}
            >
              Create Interview
            </a>
          </div>
        )}
      />
      {openDrawer.show && openDrawer.interview_id && (
        <InterviewDetailsDrawer
          openDrawer={openDrawer}
          setOpenDrawer={setOpenDrawer}
        />
      )}
      {openInterviewModal.show && (
        <InterviewScheduling
          visible={openInterviewModal}
          setVisible={setOpenInterviewModal}
        />
      )}
    </>
  );
};

const TimelineTab = ({ timelineData }) => {
  const renderIcon = (activityType) => {
    switch (activityType) {
      case "CREATE":
        return (
          <img
            src="https://assets.codepen.io/285131/hat-man.png"
            alt="Create Icon"
            style={{ width: "24px", height: "24px" }}
          />
        );
      case "UPDATE":
        return (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            width="24"
            height="24"
            fill="#ffb020"
          >
            <path fill="none" d="M0 0h24v24H0z" />
            <path d="M12.9 6.858l4.242 4.243L7.242 21H3v-4.243l9.9-9.9zm1.414-1.414l2.121-2.122a1 1 0 0 1 1.414 0l2.829 2.829a1 1 0 0 1 0 1.414l-2.122 2.121-4.242-4.242z" />
          </svg>
        );
      default:
        return <EditOutlined style={{ fontSize: "24px", color: "#e3e3e3" }} />;
    }
  };

  const renderValue = (value) => {
    if (typeof value === "object" && value !== null) {
      return JSON.stringify(value, null, 2); // Displaying as formatted JSON
    }
    return value;
  };

  const renderMessage = (data) => {
    if (data.activity_type === "UPDATE" && Array.isArray(data.timeline_data)) {
      return data.timeline_data.map((update, index) => {
        const { change_type, previous_value, new_value } = update;
        return (
          <div key={index}>
            {data?.timeline_type} {change_type} Changed from{" "}
            {renderValue(previous_value)} to {renderValue(new_value)} on{" "}
            {moment(data.created_at).format("MMM DD, YYYY")}
          </div>
        );
      });
    } else if (data.activity_type === "CREATE") {
      return (
        <div>
          {data?.timeline_type} Created on{" "}
          {moment(data.created_at).format("MMM DD, YYYY")}
        </div>
      );
    }
    return null;
  };

  return (
    <Timeline
      mode="left"
      style={{
        width: "85%",
        maxWidth: "700px",
        margin: "auto",
        padding: "32px 0",
        borderLeft: "2px solid #e3e3e3",
        fontSize: "1.125rem"
      }}
    >
      {timelineData.map((entry, index) => (
        <Timeline.Item
          key={index}
          dot={
            <Badge
              count={renderIcon(entry?.activity_type)}
              style={{
                backgroundColor: "#fff",
                color: "#000",
                marginRight: "10px",
                borderRadius: "50%",
                width: "40px",
                height: "40px",
                fontSize: "20px",
                border: "2px solid #e3e3e3"
              }}
            />
          }
          color={entry?.activity_type === "CREATE" ? "blue" : "orange"}
          dotColor={entry?.activity_type === "CREATE" ? "#1d72b8" : "#ffb020"}
        >
          <div className="d-flex align-items-center">
            <Tooltip title={entry?.actor_details.name}>
              <Avatar
                src={`${process.env.REACT_APP_IMAGES_BASE_URL}${entry?.actor_details?.photo}`}
                style={{ marginRight: "16px" }}
              />
            </Tooltip>
            <div>{renderMessage(entry)}</div>
          </div>
        </Timeline.Item>
      ))}
    </Timeline>
  );
};

export default DetailSection;
