import React from "react";
import { Alert, Typography, Divider } from "antd";
import { Disclosure } from "@headlessui/react";
import headerLogo from "../../Assets/img/e7s7pbbz.png";

const { Paragraph } = Typography;

const VerificationErrorPage = () => {
  return (
    <>
      <div className="min-h-full header-width">
        <Disclosure as="nav" className="bg-gray-800">
          {({ open }) => (
            <>
              <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
                <div className="flex h-16 items-center justify-between">
                  <div className="flex items-center">
                    <div className="flex-shrink-0">
                      <img
                        style={{ cursor: "pointer" }}
                        className="h-8 w-full"
                        src={headerLogo}
                        alt="Your Company"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </>
          )}
        </Disclosure>        <div style={{ width: "50%", margin: "auto", marginTop: "50px" }}>
          <Alert
            message="Account Verification Required"
            description={
              <>
                <Paragraph>
                  We need to verify your account to restore access. Your account
                  may have been flagged for security purposes, or there may be
                  information required to ensure the safety of your account and
                  personal data.
                </Paragraph>
                <Divider />
                <Paragraph>
                  Please wait for our support team to reach out to you within
                  the next 24 hours. If you have any urgent concerns or
                  questions, you can contact our support team at
                  help@mentorpal.ai .
                </Paragraph>
              </>
            }
            type="error"
            showIcon
          />
        </div>
      </div>
    </>
  );
};

export default VerificationErrorPage;
