import React, { useEffect, useState, useCallback } from "react";
import {
  Button,
  Form,
  Input,
  DatePicker,
  Select,
  message,
  Col,
  Row,
  Drawer,
  Checkbox,
  Modal
} from "antd";
import { CloseOutlined, ExclamationCircleOutlined } from "@ant-design/icons";
import { useDispatch, useSelector } from "react-redux";
import { TailSpin } from "react-loader-spinner";
import moment from "moment";
import "react-quill/dist/quill.snow.css";
import { INTERVIEW_ROUNDS } from "../../Assets/data/common";
import {
  scheduleInterviewAction,
  getInterviewDetailsAction,
  updateInterviewDetailsAction
} from "../../redux/actions/interviewer";
import UserCustomSelect from "../../Utils/ClientSelect/userSelect";
import MultipleAttendeeSelect from "../../Utils/ClientSelect/MultipleAttendeeSelect";
import { useIsMobileView } from "../../Utils/CustomHooks/windowSize";

const { Option } = Select;

const InterviewScheduling = ({ visible, setVisible }) => {
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [selectedRound, setSelectedRound] = useState("Round 1");
  const [interviewDetails, setInterviewDetails] = useState({});
  const [startTime, setStartTime] = useState(null);
  const [endTime, setEndTime] = useState(null);
  const [duration, setDuration] = useState(0);

  const { data: interviewData, loading: fetchingInterview } = useSelector(
    (state) => state.getInterviewDetailsReducer
  );
  const {
    loading: updatingInterview,
    data: updateData,
    error: updateError
  } = useSelector((state) => state.updateInterviewDetailsReducer);
  const {
    loading: schedulingInterview,
    data: scheduleData,
    error: scheduleError
  } = useSelector((state) => state.scheduleInterviewReducer);

  useEffect(() => {
    if (visible?.data) {
      const {
        interview_id,
        candidate_id,
        job_id,
        apply_id,
        client_id,
        company_name,
        name,
        job_title
      } = visible.data;

      if (interview_id) {
        setInterviewDetails({ interview_id });
        dispatch(getInterviewDetailsAction({ interview_id }));
      } else {
        setInterviewDetails({
          candidate_id,
          job_id,
          apply_id,
          client_id,
          company_name,
          name,
          job_title
        });
        form.setFieldsValue({
          summary: `${job_title}_${name}_${selectedRound}_Client:${company_name}`
        });
      }
    }

    return () => {
      dispatch({ type: "GET_INTERVIEW_DETAILS_RESET" });
    };
  }, [visible?.data, dispatch, form, selectedRound]);

  useEffect(() => {
    if (interviewData) {
      const { interview } = interviewData;

      form.setFieldsValue({
        ...interview,
        summary: interview?.summary,
        isCreateCalendar: interview?.isCreateCalendar || false,
        assign_data: interview?.assign_data?.assign_id || {},
        eventDate: moment(interview?.startDateTime),
        startTime: moment(interview?.startDateTime).format("hh:mm A"),
        endTime: moment(interview?.endDateTime).format("hh:mm A"),
        attendees: (interview?.external_attendees || []).map(
          ({ email, user_id }) => ({ email, user_id })
        ),
        interviewRound: interview?.interviewRound || "Round 1"
      });
      const start = moment(interview?.startDateTime);
      const end = moment(interview?.endDateTime);

      setStartTime(start);
      setEndTime(end);

      if (start.isValid() && end.isValid()) {
        const durationInMinutes = end.diff(start, 'minutes');
        setDuration(durationInMinutes);
      }
      setSelectedRound(interview?.interviewRound || "Round 1");
      setInterviewDetails((prev) => ({
        ...prev,
        company_name: interviewData?.client?.company_name,
        job_title: interviewData?.job?.job_title,
        name: interviewData?.candidate?.name,
        candidate_id: interviewData?.interview?.candidate_id,
        job_id: interviewData?.interview?.job_id,
        apply_id: interviewData?.interview?.apply_id,
        client_id: interviewData?.interview?.client_id
      }));
    }
  }, [interviewData, form]);

  useEffect(() => {
    setLoading(fetchingInterview || updatingInterview || schedulingInterview);
    if (updateData || scheduleData) {
      message.success(updateData?.message || scheduleData?.message || "Done");
      onClose();
    } else if (updateError || scheduleError) {
      message.error(updateError || scheduleError);
    }
  }, [
    fetchingInterview,
    updatingInterview,
    schedulingInterview,
    updateData,
    scheduleData,
    updateError,
    scheduleError
  ]);

  const handleDone = useCallback(() => {
    form
      .validateFields()
      .then((values) => {
        const attendees = values.attendees || [];
        const startDateTime = moment(
          `${values.eventDate.format("YYYY-MM-DD")} ${moment(values.startTime, "hh:mm A").format("HH:mm")}`
        )
          .utc()
          .format();

        const endDateTime = moment(
          `${values.eventDate.format("YYYY-MM-DD")} ${moment(values.endTime, "hh:mm A").format("HH:mm")}`
        )
          .utc()
          .format();


        const interviewPayload = {
          summary: values.summary,
          startDateTime,
          endDateTime,
          interviewRound: selectedRound,
          attendees: attendees.map(({ email, user_id }) => ({
            email,
            user_id
          })),
          candidate_id: interviewDetails?.candidate_id,
          job_id: interviewDetails?.job_id,
          apply_id: interviewDetails?.apply_id,
          assign_data: values.assign_data,
          client_id: interviewDetails?.client_id,
          interview_id: interviewDetails?.interview_id,
          isCreateCalendar: values.isCreateCalendar || false
        };

        if (interviewDetails?.interview_id) {
          Modal.confirm({
            title: "Are you sure you want to update this Interview?",
            icon: <ExclamationCircleOutlined />,
            content: "This action will apply changes immediately.",
            okText: "Yes, Update",
            cancelText: "No, Cancel",
            onOk() {
              dispatch(updateInterviewDetailsAction(interviewPayload));
            }
          });
        } else {
          dispatch(scheduleInterviewAction(interviewPayload));
        }
      })
      .catch((error) => {
        console.error("Form validation failed:", error);
      });
  }, [form, selectedRound, interviewDetails, dispatch]);

  const onClose = useCallback(() => {
    dispatch({ type: "GET_INTERVIEW_DETAILS_RESET" });
    form.resetFields();
    setInterviewDetails({});
    setSelectedRound("Round 1");
    setVisible((prev) => ({ ...prev, show: false }));
  }, [form, setVisible, dispatch]);

  const handleRoundChange = useCallback(
    (value) => {
      setSelectedRound(value);
      form.setFieldsValue({
        summary: `${interviewDetails.job_title}_${interviewDetails.name}_${value}_Client:${interviewDetails.company_name}`
      });
    },
    [form, interviewDetails]
  );


  const generateTimeSlots = () => {
    const slots = [];
    let time = moment().startOf("day");
    for (let i = 0; i < 24 * 4; i++) {
      slots.push(time.format("hh:mm A"));
      time = time.add(15, "minutes");
    }
    return slots;
  };

  const timeSlots = generateTimeSlots();

  const handleStartTimeChange = (value) => {
    const start = moment(value, "hh:mm A");
    setStartTime(start);

    if (endTime && start.isSameOrAfter(endTime)) {
      message.error("Start time must be before end time.");
      setEndTime(null);
      setDuration(0);
    }
  };

  const handleEndTimeChange = (value) => {
    const end = moment(value, "hh:mm A");
    setEndTime(end);

    if (startTime && end.isBefore(startTime)) {
      message.error("End time must be after start time.");
      setDuration(0);
    } else if (startTime) {
      const diff = end.diff(startTime, "minutes");
      setDuration(diff);
    }
  };

  const renderEndTimeOptions = () => {
    return timeSlots
      .filter((slot) => {
        const slotMoment = moment(slot, "hh:mm A");
        return slotMoment.isAfter(startTime);
      })
      .map((slot) => {
        const slotMoment = moment(slot, "hh:mm A");
        const diffInMinutes = slotMoment.diff(startTime, "minutes");
  
        let durationText = `${diffInMinutes} minutes`;
  
        if (diffInMinutes >= 60 && diffInMinutes < 24 * 60) {
          const diffInHours = Math.floor(diffInMinutes / 60);
          const remainingMinutes = diffInMinutes % 60;
          durationText = `${diffInHours} hour${diffInHours > 1 ? 's' : ''} ${remainingMinutes} minute${remainingMinutes !== 1 ? 's' : ''}`;
        }
  
        if (diffInMinutes >= 24 * 60) {
          const diffInDays = Math.floor(diffInMinutes / (24 * 60));
          durationText = `${diffInDays} day${diffInDays > 1 ? 's' : ''}`;
        }
  
        return (
          <Option key={slot} value={slot}>
            {slot} ({durationText})
          </Option>
        );
      });
  };
  
  

  return (
    <Drawer
      placement="right"
      closable={false}
      open={visible.show}
      width={useIsMobileView() ? "100%" : "40%"}
      onClose={onClose}
      title={
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center"
          }}
        >
          {`${interviewDetails.job_title}_${interviewDetails.name}_${selectedRound}_Client:${interviewDetails.company_name}`}
          <CloseOutlined
            style={{ fontSize: "20px", cursor: "pointer" }}
            onClick={onClose}
          />
        </div>
      }
      footer={
        <Row justify="space-between">
          <Col>
            <Button className="custom-button" type="button" onClick={onClose}>
              Cancel
            </Button>
          </Col>
          <Col>
            <Button
              loading={loading}
              className="custom-button"
              onClick={handleDone}
            >
              Save
            </Button>
          </Col>
        </Row>
      }
    >
      {loading ? (
        <div className="spinner-container">
          <TailSpin ariaLabel="Loading..." color="#005c53" />
        </div>
      ) : (
        <div className="p-3">
          <Form
            form={form}
            layout="vertical"
            name="EventDetailsStep"
            initialValues={{ isCreateCalendar: false }}
          >
            <Form.Item
              name="summary"
              label="Title"
              rules={[{ required: true, message: "Please enter the Title" }]}
            >
              <Input size="large" placeholder="Enter meeting Title" />
            </Form.Item>

            <Form.Item
              name="interviewRound"
              label="Interview Round"
              rules={[{ required: true, message: "Please select the round" }]}
            >
              <Select size="large" onChange={handleRoundChange}>
                {INTERVIEW_ROUNDS.map(({ label }) => (
                  <Option key={label} value={label}>
                    {label}
                  </Option>
                ))}
              </Select>
            </Form.Item>

            <Form.Item
              name="eventDate"
              label="Event Date"
              rules={[
                { required: true, message: "Please select the event date" }
              ]}
            >
              <DatePicker
                size="large"
                style={{ width: "100%" }}
                format="YYYY-MM-DD"
                disabledDate={(current) =>
                  current && current < moment().startOf("day")
                }
              />
            </Form.Item>

            <Row gutter={8}>
              <Col span={12}>
                <Form.Item
                  name="startTime"
                  label="Start Time"
                  rules={[
                    { required: true, message: "Please select start time" }
                  ]}
                >
                  <Select
                    size="large"
                    style={{ width: "100%" }}
                    onChange={handleStartTimeChange}
                    value={startTime ? startTime.format("hh:mm A") : undefined}
                  >
                    {timeSlots.map((slot) => (
                      <Option key={slot} value={slot}>
                        {slot}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  name="endTime"
                  label="End Time"
                  rules={[
                    { required: true, message: "Please select end time" },
                  ]}
                >
                  <Select
                    size="large"
                    style={{ width: "100%" }}
                    onChange={handleEndTimeChange}
                    value={endTime ? endTime.format("hh:mm A") : undefined}
                  >
                    {renderEndTimeOptions()}
                  </Select>
                </Form.Item>
              </Col>
            </Row>
            <Form.Item
              name="attendees"
              label="Attendees"
              rules={[
                {
                  required: true,
                  message: "Please select at least one attendee"
                }
              ]}
            >
              <MultipleAttendeeSelect
                form={form}
                filter={{
                  selective: true,
                  client_id: interviewDetails?.client_id
                }}
              />
            </Form.Item>

            <Form.Item
              name="assign_data"
              label="Assign to"
              rules={[{ required: true, message: "Please select a user" }]}
            >
              <UserCustomSelect
                form={form}
                multiple={true}
                filter={{
                  selective: true,
                  client_id: interviewDetails?.client_id
                }}
              />
            </Form.Item>

            <Form.Item name="isCreateCalendar" valuePropName="checked">
              <Checkbox>Create Calendar Event</Checkbox>
            </Form.Item>
          </Form>
        </div>
      )}
    </Drawer>
  );
};

export default InterviewScheduling;
