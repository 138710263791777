import React, { useState } from "react";
import {
  Button,
  Form,
  Input,
  Layout,
  Select,
  Collapse,
  Divider,
  Radio,
  Drawer,
} from "antd";
import { useDispatch } from "react-redux";
import { Outlet, useNavigate } from "react-router-dom";
import { TagsAssessment } from "../data";
import { getAllTestListAction } from "../../../redux/actions/assessment";
import("../../../Assets/css/filter.css");

const { Sider } = Layout;
const { Option } = Select;
const { Panel } = Collapse;

const FilterForm = ({ filters, handleFilterChange, handleClearFilters }) => (
  <Form
    layout="vertical"
    onValuesChange={handleFilterChange}
    initialValues={filters}
  >
    <div className="d-flex justify-content-between align-items-center mb-3">
      <span className="d-flex align-items-center">
        <h2 className="m-0 ml-2 mt-3">Filters</h2>
      </span>

      <Button
        type=""
        className="curosor-pointer mt-3"
        onClick={handleClearFilters}
      >
        Reset all
      </Button>
    </div>
    <Collapse
      defaultActiveKey={["1", "2", "3", "4"]}
      expandIconPosition="right"
      style={{ background: "#fff", border: "none" }}
    >
      <Panel
        header="Title"
        key="1"
        style={{ background: "#fff", border: "none" }}
      >
        <Form.Item name="title">
          <Input placeholder="Search questions" size="large" />
        </Form.Item>
      </Panel>
      <Panel
        header="Select Test level"
        key="2"
        style={{ background: "#fff", border: "none" }}
      >
        <Form.Item name="level">
          <Radio.Group
            onChange={(e) => handleFilterChange({ level: e.target.value })}
            value={filters.type}
          >
            <div className="d-flex justify-content-between mb-2">
              <Radio value="">All</Radio>
            </div>
            <div className="d-flex justify-content-between mb-2">
              <Radio value="high">High</Radio>
            </div>
            <div className="d-flex justify-content-between mb-2">
              <Radio value="medium">Medium</Radio>
            </div>
            <div className="d-flex justify-content-between mb-2">
              <Radio value="low">Low</Radio>
            </div>
          </Radio.Group>
        </Form.Item>
      </Panel>
      <Panel
        header="Select Test Type"
        key="3"
        style={{ background: "#fff", border: "none" }}
      >
        <Form.Item name="type">
          <Radio.Group
            onChange={(e) => handleFilterChange({ type: e.target.value })}
            value={filters.type}
          >
            <div className="d-flex justify-content-between mb-2">
              <Radio value="">All</Radio>
            </div>
            <div className="d-flex justify-content-between mb-2">
              <Radio value="mcq">Multiple Choice</Radio>
            </div>
            <div className="d-flex justify-content-between mb-2">
              <Radio value="code">Coding</Radio>
            </div>
            <div className="d-flex justify-content-between mb-2">
              <Radio value="questionnaire">Questionnaire</Radio>
            </div>
          </Radio.Group>
        </Form.Item>
      </Panel>
      <Panel
        header="Tags"
        key="4"
        style={{ background: "#fff", border: "none" }}
      >
        <Form.Item name="tags">
          <Select
            mode="tags"
            style={{ width: "100%" }}
            placeholder="Select tags"
            size="large"
          >
            {TagsAssessment?.map((option) => (
              <Option key={option} value={option}>
                {option}
              </Option>
            ))}
          </Select>
        </Form.Item>
      </Panel>
    </Collapse>
  </Form>
);
const AssessmentTest = () => {
  const navigate = useNavigate();
  const [filters, setFilters] = useState({
    title: "",
    level: "",
    tags: [],
    field: [],
    skills: [],
    type: "",
  });
  const dispatch = useDispatch();

  const [isMobileFilterDrawerVisible, setIsMobileFilterDrawerVisible] =
    useState(false);

  const handleFilterChange = (changedValues, allValues) => {
    setFilters((prevFilters) => {
      const newFilters = { ...prevFilters, ...changedValues };
      handleFilterSubmit(newFilters);
      return newFilters;
    });
  };

  const handleFilterSubmit = (newFilters) => {
    dispatch(getAllTestListAction({ page: 1, limit: 100000, ...newFilters }));
  };

  const handleClearFilters = () => {
    const initialFilters = {
      title: "",
      level: "",
      tags: [],
      field: [],
      skills: [],
      type: "",
    };
    setFilters(initialFilters);
    handleFilterSubmit(initialFilters);
  };

  const toggleFilters = () =>
    setIsMobileFilterDrawerVisible(!isMobileFilterDrawerVisible);
  const closeDrawer = () => setIsMobileFilterDrawerVisible(false);
  const isMobile = window.innerWidth <= 768;

  return (
    <>
      <div className="bg-white" data-ui="header">
        <div className=" px-4">
          <div className="flag">
            <div className="flag__section">
              <a className="external incognito-link inline-block mb-m mt-m">
                <h1 className="js-main-header-title main-header__title">
                  Tests
                </h1>
              </a>
            </div>
            <div className="flag__section flag__section--tight flag__section--top js-main-header-actions">
              <Button
                type="button"
                className="btn btn--primary mt-m text-white pull-right main_border_radius"
                onClick={() => {
                  navigate("/dashboard/test-create");
                }}
              >
                Create Test
              </Button>
            </div>
          </div>
        </div>
      </div>
      <Layout style={{ background: "#fff", minHeight: "100vh" }}>
        {!isMobile && (
          <Sider width={300} className="site-layout-background bg-light">
            <div className="px-3 bg-light" style={{ height: "100vh" }}>
              <FilterForm
                filters={filters}
                handleClearFilters={handleClearFilters}
                handleFilterChange={handleFilterChange}
              />
            </div>
          </Sider>
        )}

        <Layout style={{ padding: "0 5px 5px", minHeight: "100vh" }}>
          <Button
            className="filter-toggle-button"
            onClick={toggleFilters}
            type="primary"
            style={{ backgroundColor: "#00756a" }}
          >
            Apply Filters
          </Button>
          <Outlet />
        </Layout>
      </Layout>
      <Drawer
        visible={isMobileFilterDrawerVisible}
        onClose={closeDrawer}
        placement="left"
        width="80%"
        className="filter-drawer"
        bodyStyle={{ paddingTop: 0 }} 
      >
        <FilterForm
          filters={filters}
          handleClearFilters={handleClearFilters}
          handleFilterChange={handleFilterChange}
        />
      </Drawer>
    </>
  );
};

export default AssessmentTest;
