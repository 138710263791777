export const NAVIGATION_CONFIG = {
  ATS_OWNER: [
    { title: "Home", href: "/dashboard/home" },
    {
      title: "Management",
      dropdown: [
        { title: "User-Management", href: "/dashboard/user-management" },
        { title: "Report", href: "/dashboard/report" }
      ]
    },
    {
      title: "Jobs",
      dropdown: [
        { title: "All Jobs", href: "/dashboard/jobs" },
        { title: "Job Reports", href: "/dashboard/jobs/job-reports" }
      ]
    },
    { title: "Candidates", href: "/dashboard/candidates" },

    {
      title: "Events",
      dropdown: [
        { title: "Calendar", href: "/dashboard/interview-calendar" },
        { title: "Interview", href: "/dashboard/interview" }
      ]
    }
  ],
  ATS_RECRUITER: [
    { title: "Home", href: "/dashboard/home" },
    {
      title: "Jobs",
      dropdown: [
        { title: "All Jobs", href: "/dashboard/jobs" },
        { title: "Job Reports", href: "/dashboard/jobs/job-reports" }
      ]
    },
    {
      title: "Events",
      dropdown: [
        { title: "Calendar", href: "/dashboard/interview-calendar" },
        { title: "Interview", href: "/dashboard/interview" }
      ]
    }
  ],
  RECRUITMENT_MANAGER: [
    { title: "Home", href: "/dashboard/home" },
    {
      title: "Jobs",
      dropdown: [
        { title: "All Jobs", href: "/dashboard/jobs" },
        { title: "Job Reports", href: "/dashboard/jobs/job-reports" }
      ]
    },
    { title: "Candidates", href: "/dashboard/candidates" },
    {
      title: "Events",
      dropdown: [
        { title: "Calendar", href: "/dashboard/interview-calendar" },
        { title: "Interview", href: "/dashboard/interview" }
      ]
    }
  ],
  ASSESSMENT_OWNER: [
    { title: "Assessments", href: "/dashboard/assessment" },
    { title: "Candidates", href: "/dashboard/candidates" },
    { title: "User-Management", href: "/dashboard/user-management" }
  ],
  ASSESSMENT_RECRUITER: [
    { title: "Assessments", href: "/dashboard/assessment" },
    { title: "Candidates", href: "/dashboard/candidates" }
  ],
  MENTORPAL_OWNER: [
    { title: "Home", href: "/dashboard/home" },
    {
      title: "Management",
      dropdown: [
        { title: "User-Management", href: "/dashboard/user-management" },
        { title: "Client", href: "/dashboard/client-dashboard" },
        { title: "Report", href: "/dashboard/report" },
        { title: "TimeLine Logs", href: "/dashboard/timeline-logs" }
      ]
    },
    {
      title: "Jobs",
      dropdown: [
        { title: "All Jobs", href: "/dashboard/jobs" },
        { title: "Job Reports", href: "/dashboard/jobs/job-reports" },
        { title: "Candidates", href: "/dashboard/candidates" }
      ]
    },
    {
      title: "Events",
      dropdown: [
        { title: "Calendar", href: "/dashboard/interview-calendar" },
        { title: "Interview", href: "/dashboard/interview" }
      ]
    },
    {
      title: "Assessments",
      dropdown: [
        { title: "Assessments", href: "/dashboard/assessment" },
        { title: "Tests", href: "/dashboard/tests" },
        { title: "Questions", href: "/dashboard/questions" }
      ]
    },
    {
      title: "Match Master & More",
      dropdown: [
        { title: "Match Master", href: "/dashboard/match-master" },
        { title: "Enquiry", href: "/dashboard/enquiry" },
        { title: "Feedback", href: "/dashboard/feedback" },
        { title: "Email Template", href: "/dashboard/emails-template" }
      ]
    }
  ],
  MENTORPAL_EXECUTIVE: [
    { title: "Tests", href: "/dashboard/tests" },
    { title: "Questions", href: "/dashboard/questions" },
    { title: "Enquiry", href: "/dashboard/enquiry" },
    { title: "Feedback", href: "/dashboard/feedback" }
  ],
  ATS_INTERVIEWER: [
    { title: "Home", href: "/dashboard/home" },
    { title: "Calendar", href: "/dashboard/interview-calendar" },
    { title: "Interview", href: "/dashboard/interview" },
    { title: "Report", href: "/dashboard/report" }
  ],
  CANDIDATE: [
    // { title: "Home", href: "/dashboard/home" }
    // { title: "Jobs", href: "/dashboard/jobs" }
  ]
};

export const userNavigation = [
  { name: "Your Profile", href: "/dashboard/profile" },
  { name: "Sign out", href: "#" }
];

export const getNavigationByUserType = (userType) => {
  switch (userType) {
    case "ats-owner":
    case "ats-admin":
      return NAVIGATION_CONFIG.ATS_OWNER;
    case "ats-recruiter":
    case "mentorpal-recruiter":
      return NAVIGATION_CONFIG.ATS_RECRUITER;
    case "mentorpal-recruitment-manager":
    case "hiring-manager":
      return NAVIGATION_CONFIG.RECRUITMENT_MANAGER;
    case "assessment-owner":
    case "assessment-admin":
      return NAVIGATION_CONFIG.ASSESSMENT_OWNER;
    case "assessment-recruiter":
      return NAVIGATION_CONFIG.ASSESSMENT_RECRUITER;
    case "mentorpal-owner":
    case "mentorpal-admin":
      return NAVIGATION_CONFIG.MENTORPAL_OWNER;
    case "mentorpal-executive":
      return NAVIGATION_CONFIG.MENTORPAL_EXECUTIVE;
    case "candidate-dashboard":
      return NAVIGATION_CONFIG.CANDIDATE;
    case "ats-interviewer":
      return NAVIGATION_CONFIG.ATS_INTERVIEWER;
    default:
      return [];
  }
};

export const getTargetLocation = (userType) => {
  switch (userType) {
    case "mentorpal-owner":
    case "mentorpal-admin":
    case "ats-owner":
    case "ats-admin":
    case "ats-recruiter":
    case "mentorpal-recruiter":
    case "hiring-manager":
    case "ats-interviewer":
    case "mentorpal-recruitment-manager":
      return "/dashboard/home";

    case "assessment-owner":
    case "assessment-admin":
    case "assessment-recruiter":
      return "/dashboard/assessment";

    case "mentorpal-executive":
      return "/dashboard/tests";

    case "candidate-dashboard":
      return "/dashboard/home";
    case "assessment-candidate":
      return "/dashboard/tips";
    default:
      return null;
  }
};
