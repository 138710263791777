import React, { useEffect, useState } from "react";
import {
  Modal,
  Col,
  Row,
  Table,
  Tabs,
  Typography,
  Radio,
  Spin,
  Layout,
  Divider,
  message,
  Button
} from "antd";
import { useDispatch, useSelector } from "react-redux";
import { getTestDetailAction } from "../../../../../redux/actions/assessment";
import CodeEditor from "../../../../Compiler/CodeEditor";
import logo from "../../../../../Assets/img/y3awvixq.png";
import { InfoCircleOutlined } from "@ant-design/icons";
import { QuillTextPreview } from "../../../../../Utils/TextEditor";

const { Title } = Typography;
const { TabPane } = Tabs;
const { Header } = Layout;

const PreviewModal = ({ previewModal, setPreviewModal, initialData }) => {
  const dispatch = useDispatch();
  const isMultipleTests = Array.isArray(initialData);
  const [currentTestId, setCurrentTestId] = useState(
    isMultipleTests ? initialData[0]?.test_id : initialData?.test_id
  );

  const [loading, setLoading] = useState(false);
  const getTestDetailsReducer = useSelector(
    (state) => state.getTestDetailsReducer
  );
  const [data, setData] = useState(null);

  useEffect(() => {
    if (currentTestId) {
      setLoading(true);
      dispatch(getTestDetailAction({ test_id: currentTestId }));
    }
    return () => {
      dispatch({ type: "GET_TEST_RESET" });
    };
  }, [dispatch, currentTestId]);

  useEffect(() => {
    const { loading, data, error } = getTestDetailsReducer;
    setLoading(loading);
    if (!loading) {
      if (data && !error) {
        setData(data.data);
      } else if (error) {
        console.warn("Error:", error);
        message.error(error);
      }
    }
  }, [getTestDetailsReducer]);

  const renderMCQ = (item) => (
    <div style={{ padding: "20px", fontSize: "16px" }}>
      <strong className="d-flex p-2">Choose Only One</strong>
      <Radio.Group value={item}>
        {item?.mcqChoices?.map((choice) => (
          <div style={{ display: "flex", alignItems: "center", marginBottom: "10px" }} key={choice?.id}>
            <Radio value={choice?.value}>{choice?.value}</Radio>
          </div>
        ))}
      </Radio.Group>
    </div>
  );

  const renderCode = (item) => (
    <div style={{ padding: "20px" }}>
      <CodeEditor
        code={item?.code}
        question_id={item?.question_id}
        onSave={(data) => {
          console.log("Saved code data:", data);
        }}
      />
    </div>
  );

  const renderQuestionnaire = (item) => (
    <div style={{ padding: "20px", fontSize: "16px" }}>
      <strong className="d-flex p-2">Choose Only One</strong>
      <Radio.Group>
        {item?.questionnaireChoices?.map((choice) => (
          <div style={{ display: "flex", alignItems: "center", marginBottom: "10px" }} key={choice?.id}>
            <Radio value={choice?.value}>{choice?.value}</Radio>
          </div>
        ))}
      </Radio.Group>
    </div>
  );

  const renderQuestionCard = (item) => {
    switch (item?.questionType) {
      case "mcq":
        return renderMCQ(item);
      case "code":
        return renderCode(item);
      case "questionnaire":
        return renderQuestionnaire(item);
      default:
        return null;
    }
  };

  const renderContent = () => (
    <>
      {data?.questions?.slice(0, 3).map((item, index) => (
        <React.Fragment key={index}>
          <Row
            style={{
              margin: "10px 0",
              padding: "16px",
              borderRadius: "10px",
              border: "3px solid #e8e8e8",
              flexDirection: window.innerWidth < 768 ? "column" : "row"
            }}
          >
            <Col
              span={24}
              md={8}
              style={{
                padding: "16px",
                width: window.innerWidth < 768 ? "100%" : "33%",
                boxSizing: "border-box"
              }}
            >
              <div style={{ padding: "10px" }}>
                <strong>Question:</strong>
                <Title level={4} style={{ fontSize: window.innerWidth < 768 ? "18px" : "24px" }}>
                  {item?.questionName}
                </Title>
              </div>
              <div style={{ padding: "10px" }}>
                <strong>Description:</strong>
                <QuillTextPreview content={item?.problemDescription} />
              </div>
              {item?.questionType === "code" && (
                <div style={{ padding: "10px" }}>
                  <strong>Test Case:</strong>
                  <Table
                    bordered
                    dataSource={item?.testCases}
                    columns={[
                      { title: "Input", dataIndex: "input", key: "input" },
                      { title: "Output", dataIndex: "output", key: "output" }
                    ]}
                    pagination={false}
                    rowKey="input"
                    size="small"
                  />
                </div>
              )}
            </Col>
            <Col
              span={24}
              md={16}
              style={{
                padding: "16px",
                width: window.innerWidth < 768 ? "100%" : "66%",
                boxSizing: "border-box"
              }}
            >
              {renderQuestionCard(item)}
            </Col>
          </Row>
          <Divider />
        </React.Fragment>
      ))}

      <div style={{ display: "flex", alignItems: "center", padding: "10px", color: "#1890ff" }}>
        <InfoCircleOutlined style={{ fontSize: "18px", marginRight: "8px" }} />
        <span>You have permission to preview only 3 tests.</span>
      </div>
    </>
  );

  return (
    <Modal
      visible={previewModal}
      width={window.innerWidth < 768 ? "100%" : "90%"}
      style={{ top: window.innerWidth < 768 ? "5px" : "10px", padding: 0 }}
      onCancel={() => setPreviewModal(false)}
      footer={[
        <Button
          key="cancel"
          className="test-button-cards-2"
          onClick={() => setPreviewModal(false)}
          >
          Cancel
        </Button>
      ]}
      title={
        <Header style={{ display: "flex", alignItems: "center", padding: "10px" }}>
          <a style={{ display: "flex", alignItems: "center", textDecoration: "none" }}>
            <img
              src={logo}
              alt="Test Logo"
              style={{
                width: window.innerWidth < 768 ? "30px" : "50px",
                marginRight: "10px"
              }}
            />
            <h3 style={{ fontSize: window.innerWidth < 768 ? "16px" : "24px", margin: 0 , color:"white"}}>
              Mpal Solution Private Limited
            </h3>
          </a>
        </Header>
      }
    >
      <Spin spinning={loading}>{renderContent()}</Spin>
    </Modal>
  );
};

export default PreviewModal;
