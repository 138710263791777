import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { TailSpin } from "react-loader-spinner";
import {
  PublicRoutes,
  AssessmentCandidateRoutes,
  CandidateDashboardRoutes,
  MentorpalOwnerRoutes,
  MentorpalExecutiveRoutes,
  AssessmentRecruiterRoutes,
  AssessmentOwnerRoutes,
  AtsInterviewerRoutes,
  AtsRecruiterRoutes,
  AtsOwnerRoutes,
  VerificationErrorRoutes
} from "../../routes";
import { getuserDetailsAction } from "../../redux/actions/user";
import {
  getAccessToken,
  REACT_USER_TYPE,
  REACT_USER_ID,
  REACT_INVITE_ID
} from "./tokenProvider";
import { getInviteAssessmentDetailAction } from "../../redux/actions/assessment";
import { getCandidateDetailsDashboardAction } from "../../redux/actions/candidate";
import { getRoleUSerDetailsAction } from "../../redux/actions/Roles&Management";
import { message } from "antd";

const Auth = () => {
  const [loggedIn, setLoggedIn] = useState(false);
  const [userType, setUserType] = useState(null);
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const authToken = getAccessToken();

  const loginUserReducer = useSelector((state) => state.loginUserReducer);
  const signupUserReducer = useSelector((state) => state.signupUserReducer);
  const assessmentLoginReducer = useSelector(
    (state) => state.assessmentLoginReducer
  );
  const {
    data: otpData,
    loading: otpLoading,
    error: otpError
  } = useSelector((state) => state.candidateLoginReducer);
  const getUserDetailReducer = useSelector(
    (state) => state.getUserDetailReducer
  );
  const getInviteAssessmentDetailsReducer = useSelector(
    (state) => state.getInviteAssessmentDetailsReducer
  );
  const getCandidateDetailsDashboardReducer = useSelector(
    (state) => state.getCandidateDetailsDashboardReducer
  );

  useEffect(() => {
    if (authToken) {
      setLoggedIn(true);
    } else {
      setLoading(false);
    }
  }, [authToken]);

  useEffect(() => {
    if (loggedIn) {
      const userId = REACT_USER_ID();
      const userType = REACT_USER_TYPE();

      if (userType === "assessment-candidate") {
        dispatch(
          getInviteAssessmentDetailAction({ assessment_invite_id: REACT_INVITE_ID() })
        );
      } else if (userType === "candidate-dashboard") {
        dispatch(getCandidateDetailsDashboardAction(userId));
      } else {
        dispatch(getuserDetailsAction({ user_id: userId }));
        dispatch(getRoleUSerDetailsAction({ user_id: userId }));
      }
    }
  }, [loggedIn, dispatch]);

  useEffect(() => {
    const {
      data: userDetailsData,
      loading: userDetailsLoading,
      error: userDetailsError
    } = getUserDetailReducer;
    const {
      data: inviteAssessmentData,
      loading: inviteAssessmentLoading,
      error: inviteAssessmentError
    } = getInviteAssessmentDetailsReducer;
    const {
      data: candidateData,
      loading: candidateLoading,
      error: candidateError
    } = getCandidateDetailsDashboardReducer;

    setLoading(
      userDetailsLoading || inviteAssessmentLoading || candidateLoading
    );
    if (!userDetailsLoading && !inviteAssessmentLoading && !candidateLoading) {
      if (userDetailsData && !userDetailsError) {
        setData(userDetailsData);
        setUserType(userDetailsData.user_type);
        setLoading(false);
      } else if (inviteAssessmentData && !inviteAssessmentError) {
        setData(inviteAssessmentData?.invite_data);
        setUserType("assessment-candidate");
        setLoading(false);
      } else if (candidateData && !candidateError) {
        localStorage.setItem("REACT_USER_PHOTO", candidateData?.photo);
        setData(candidateData);
        setUserType("candidate-dashboard");
        setLoading(false);
      } else if (userDetailsError || inviteAssessmentError || candidateError) {
        message.error(
          userDetailsError || inviteAssessmentError || candidateError
        );
        setLoading(false);
      }
    }
  }, [
    getUserDetailReducer,
    getInviteAssessmentDetailsReducer,
    getCandidateDetailsDashboardReducer
  ]);

  useEffect(() => {
    if (userType && data) {
      navigate(getTargetLocation(data));
    }
  }, [userType, data]);

  const getTargetLocation = (user) => {
    if (user?.status === "signup") {
      return "/registration";
  } else if (["inactive", "hold", "suspended", "deleted"].includes(user?.status)) {
      return "/error";
  }
    switch (userType) {
      case "mentorpal-owner":
      case "mentorpal-admin":
      case "ats-owner":
      case "ats-admin":
      case "hiring-manager":
      case "ats-recruiter":
      case "mentorpal-recruiter":
      case "mentorpal-manager":
      case "mentorpal-recruitment-manager":
      case "ats-interviewer":
        return "/dashboard/home";

      case "assessment-owner":
      case "assessment-admin":
      case "assessment-recruiter":
        return "/dashboard/assessment";

      case "mentorpal-executive":
        return "/dashboard/tests";

      case "candidate-dashboard":
        return "/dashboard/home";
      case "assessment-candidate":
        return "/dashboard/tips";
      default:
        return null;
    }
  };

  const renderRoutes = () => {
    if(["inactive", "hold", "suspended", "deleted"].includes(data?.status)){
      return <VerificationErrorRoutes/>
    }
    switch (userType) {
      case "ats-owner":
      case "ats-admin":
        return <AtsOwnerRoutes />;
      case "hiring-manager":
      case "ats-recruiter":
      case "mentorpal-recruiter":
      case "mentorpal-recruitment-manager":
        return <AtsRecruiterRoutes />;
      case "ats-interviewer":
        return <AtsInterviewerRoutes />;
      case "assessment-owner":
      case "assessment-admin":
        return <AssessmentOwnerRoutes />;
      case "assessment-recruiter":
        return <AssessmentRecruiterRoutes />;

      case "mentorpal-owner":
      case "mentorpal-admin":
        return <MentorpalOwnerRoutes />;

      case "mentorpal-executive":
        return <MentorpalExecutiveRoutes />;

      case "assessment-candidate":
        return <AssessmentCandidateRoutes />;
      case "candidate-dashboard":
        return <CandidateDashboardRoutes />;
      default:
        return (
          <>
            <div
              style={{
                position: "fixed",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                zIndex: 9999
              }}
            >
              <TailSpin ariaLabel="Loading..." color="#005c53" />
            </div>
          </>
        );
    }
  };

  if (loading) {
    return (
      <div
        style={{
          position: "fixed",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          zIndex: 9999
        }}
      >
        <TailSpin ariaLabel="Loading..." color="#005c53" />
      </div>
    );
  }

  return loggedIn ? renderRoutes() : <PublicRoutes />;
};

export default Auth;
