import React, { useEffect, useState } from "react";
import {
  Card,
  Tag,
  Space,
  Button,
  Row,
  Col,
  Typography,
  Divider,
  Dropdown,
  message,
  Table,
  Modal,
  Select,
  Input,
  Menu,
} from "antd";
import { useLocation, useNavigate } from "react-router-dom";
import {
  ArrowLeftOutlined,
  MoreOutlined,
  SearchOutlined,
} from "@ant-design/icons";
import { useDispatch, useSelector } from "react-redux";
import { TailSpin } from "react-loader-spinner";
import QuestionDrawer from "./Modal/QuestionDrawer";
import {
  getTestDetailAction,
  updateTestAction,
} from "../../../redux/actions/assessment";
import { QuillTextPreview } from "../../../Utils/TextEditor";
import { DOCUMENT_STATUS } from "../data";
import { QUESTION_TYPE } from "../data";
import("../../../Assets/css/call.css");

const { Title } = Typography;
const { Option } = Select;

const TestDetailsPage = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const test_id = queryParams.get("test_id");
  const updateTestReducer = useSelector((state) => state.updateTestReducer);
  useEffect(() => {
    dispatch(getTestDetailAction({ test_id: test_id }));
    return () => {
      dispatch({ type: "GET_TEST_RESET" });
    };
  }, [dispatch, test_id, updateTestReducer]);

  const getTestDetailsReducer = useSelector(
    (state) => state.getTestDetailsReducer
  );

  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [drawerVisible, setDrawerVisible] = useState(false);
  const [selectedQuestion, setSelectedQuestion] = useState(null);

  useEffect(() => {
    const { loading, data, error } = getTestDetailsReducer;
    setLoading(loading);
    if (!loading && data && !error) {
      setData(data.data);
    }
    if (!loading && !data && error) {
      console.warn("Error:", error);
      message.error(error);
    }
  }, [getTestDetailsReducer]);

  useEffect(() => {
    const { loading, data, error } = updateTestReducer;
    setLoading(loading);
    if (!loading && data && !error) {
      if (data?.data?.status === "delete") {
        message.success("Test deleted!");
        navigate("/dashboard/questions");
      } else {
        message.success(data?.message);
      }
    }
    if (!loading && !data && error) {
      console.warn("error", error);
      message.error(error);
    }
  }, [updateTestReducer]);

  const onItemFunction = (type) => {
    switch (type) {
      case "edit":
        // setCreateModule((prev) => ({ ...prev, show: true }));
        navigate(`/dashboard/test-create?test_id=${test_id}`);

        break;
      case "view":
        message.info("Features Coming soon");
        break;
      case "delete":
        Modal.confirm({
          title: "Confirm Status Change",
          content: `Are you sure you want to change the status to delete ?`,
          okText: "Yes",
          cancelText: "No",
          onOk: () => {
            dispatch(updateTestAction({ test_id: test_id, status: "delete" }));
          },
          onCancel: () => {
            message.info("Action Cancel!");
          },
        });
        break;
      default:
        message.error("event not found");
        break;
    }
  };

  const statusItems = DOCUMENT_STATUS.map((item, index) => ({
    key: `status-${index}`,
    label: <a onClick={() => handleChangeStatus(item.value)}>{item.label}</a>,
  }));

  const items = [
    {
      key: "1",
      label: <a onClick={() => onItemFunction("edit")}>Edit</a>,
    },
    {
      key: "2",
      label: <a onClick={() => onItemFunction("view")}>View</a>,
    },
    {
      key: "3",
      label: <a onClick={() => onItemFunction("delete")}>Delete</a>,
    },
    ...statusItems,
  ];

  const showDrawer = (record) => {
    setSelectedQuestion(record);
    setDrawerVisible(true);
  };

  const columns = [
    {
      title: "S.No",
      dataIndex: "sno",
      key: "sno",
      width:100,
      ellipsis: true,
      render: (text, record, index) => index + 1,
    },
    {
      title: "Title",
      dataIndex: "questionName",
      key: "questionName",
      width: 250,
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => (
        <div style={{ padding: 8 }}>
          <Input
            placeholder="Search Title"
            value={selectedKeys[0]}
            onChange={(e) =>
              setSelectedKeys(e.target.value ? [e.target.value] : [])
            }
            onPressEnter={() => confirm()}
            style={{ marginBottom: 8, display: "block" }}
          />
          <Space>
            <Button
              type="primary"
              onClick={() => confirm()}
              icon={<SearchOutlined />}
              size="small"
              style={{ backgroundColor: "#00756a", color: "white" }}
            >
              Search
            </Button>
            <Button
              onClick={() => {
                clearFilters && clearFilters();
                confirm();
              }}
              size="small"
              style={{ backgroundColor: "#00756a", color: "white" }}
            >
              Reset
            </Button>
          </Space>
        </div>
      ),
      onFilter: (value, record) =>
        record.questionName.toLowerCase().includes(value.toLowerCase()),
    },
    {
      title: "Type",
      dataIndex: "questionType",
      key: "type",
      width: 300,
      filters: QUESTION_TYPE.map((option) => ({
        text: option.label,
        value: option.value,
      })),
      onFilter: (value, record) => record.questionType === value,
      render: (type) => {
        const questionType = QUESTION_TYPE.find(
          (option) => option.value === type
        );
        return questionType ? questionType.label : type;
      },
    },
    {
      title: "Action",
      key: "action",
      width: 200,
      render: (text, record) => (
        <Button type="link" onClick={() => showDrawer(record)}>
          View
        </Button>
      ),
    },
  ];
  const [statusValue, setStatusValue] = useState(data?.status);

  const handleChangeStatus = (value) => {
    setStatusValue(value);

    Modal.confirm({
      title: "Confirm Status Change",
      content: `Are you sure you want to change the status to "${value}"?`,
      okText: "Yes",
      cancelText: "No",
      onOk: () => {
        dispatch(updateTestAction({ test_id: test_id, status: value }));
      },
      onCancel: () => {
        setStatusValue(null);
      },
    });
  };
  return (
    <>
      {loading ? (
        <div
          style={{
            position: "fixed",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            zIndex: 9999,
          }}
        >
          <TailSpin ariaLabel="Loading..." color="#005c53" />
        </div>
      ) : (
        <>
          <Card
            className="m-3"
            title={
              <Row justify="space-between" align="middle">
                <Col>
                  <ArrowLeftOutlined
                    onClick={() => navigate("/dashboard/tests")}
                    style={{ cursor: "pointer", marginRight: 10 }}
                  />
                  Title: {data?.title}
                </Col>
                <Col>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      gap: "16px",
                    }}
                  >
                    <Dropdown
                      overlay={<Menu items={items} />}
                      placement="bottomRight"
                    >
                      <Button shape="circle" icon={<MoreOutlined />} />
                    </Dropdown>
                  </div>
                </Col>
              </Row>
            }
            bordered={false}
            hoverable={true}
            style={{
              boxShadow: "0 4px 12px rgba(0, 0, 0, 0.1)",
              marginBottom: "20px",
              borderRadius: 8,
            }}
          >
            <Row gutter={16}>
              <Col span={12}>
                <Title level={4}>Description</Title>
                <QuillTextPreview content={data?.description} />

                <Divider />
                <Title level={4}>Tags</Title>
                <Space direction="horizontal" wrap>
                  {data?.tags?.map((tag) => (
                    <Tag key={tag}>{tag}</Tag>
                  ))}
                </Space>
                <Divider />
              </Col>
            </Row>
            <Row>
              <Col span={24}>
                <Title level={4}>Questions</Title>
                <Table
                  bordered
                  columns={columns}
                  dataSource={data?.questions || []}
                  rowKey="question_id"
                  pagination={false}
                  scroll={{
                    x: 1300 
                  }}
                />
              </Col>
            </Row>
          </Card>

          {drawerVisible && (
            <QuestionDrawer
              question_id={selectedQuestion?.question_id}
              drawerVisible={drawerVisible}
              setDrawerVisible={setDrawerVisible}
            />
          )}
        </>
      )}
    </>
  );
};

export default TestDetailsPage;
