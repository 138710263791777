import React, { useEffect, useState } from "react";
import {
  Card,
  Button,
  Typography,
  Row,
  Col,
  Divider,
  Descriptions,
  List,
  Spin
} from "antd";
import {
  VideoCameraOutlined,
  FullscreenOutlined,
  DesktopOutlined
} from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import useProctoring from "../ProctorHook/useProctoring";
import { useDispatch } from "react-redux";
import { updateInviteAssessmentDetailAction } from "../../../redux/actions/assessment";
import { REACT_INVITE_ID } from "../../Auth/tokenProvider";
import InstructionsModal from "../AssessmentLogin/InstructionModal";

const { Title, Paragraph } = Typography;

const CameraSetup = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);
  const [instructionModalVisible, setInstructionModalVisible] = useState(false);

  const {
    videoRef,
    canvasRef,
    startTest,
    userData,
    webcamActive,
    mouseActive,
    getWebcamPermission,
    isFullscreenActive
  } = useProctoring();

  useEffect(() => {
    if (userData) {
      dispatch(
        updateInviteAssessmentDetailAction({
          assessment_invite_id: REACT_INVITE_ID(),
          deviceData: userData,
          is_mouse_active: mouseActive,
          is_webcam_active: webcamActive,
          is_fullscreen: isFullscreenActive
        })
      );
      setLoading(false);
    }
  }, [userData, dispatch, webcamActive]);

  useEffect(() => {
    getWebcamPermission();
  }, [getWebcamPermission]);

  if (loading) {
    return (
      <Row
        justify="center"
        align="middle"
        style={{
          minHeight: "100vh",
          padding: "20px",
          backgroundColor: "#f0f2f5"
        }}
      >
        <Col>
          <Spin size="large" />
          <Title level={4} style={{ marginTop: "20px" }}>
            Loading...
          </Title>
        </Col>
      </Row>
    );
  }

  const deviceDetails = userData || {};

  return (
    <Row
      id="test-area"
      justify="center"
      align="middle"
      style={{
        minHeight: "100vh",
        padding: "20px",
        backgroundColor: "#f0f2f5"
      }}
    >
      <Col xs={24} sm={22} md={22} lg={22} xl={22}>
        <Card
          style={{
            width: "100%",
            maxWidth: "1200px",
            margin: "0 auto",
            padding: "20px",
            borderRadius: "8px",
            boxShadow: "0 4px 16px rgba(0, 0, 0, 0.1)"
          }}
        >
          <Title level={2} style={{ marginBottom: "16px" }}>
            Camera Setup
          </Title>
          <Paragraph style={{ marginBottom: "24px" }}>
            We use camera images to ensure fairness for everyone. Make sure that
            you are in front of your camera.
          </Paragraph>
          <Divider style={{ borderColor: "#AE1EA4" }} />
          <Row gutter={[24, 24]}>
            <Col xs={24} md={12}>
              <Card
                title="Device Details"
                bordered={false}
                style={{ marginBottom: "24px" }}
              >
                <Descriptions bordered column={1}>
                  <Descriptions.Item label="IP Address">
                    {deviceDetails?.ip || "N/A"}
                  </Descriptions.Item>
                  <Descriptions.Item label="Location">
                    {deviceDetails?.city}, {deviceDetails?.regionName},{" "}
                    {deviceDetails?.country}
                  </Descriptions.Item>
                  <Descriptions.Item label="Device Type">
                    {deviceDetails?.deviceType || "N/A"}
                  </Descriptions.Item>
                  <Descriptions.Item label="Browser">
                    {deviceDetails?.browser || "N/A"}
                  </Descriptions.Item>
                  <Descriptions.Item label="Operating System">
                    {deviceDetails?.platform || "N/A"}
                  </Descriptions.Item>
                  <Descriptions.Item label="ISP">
                    {deviceDetails?.isp || "N/A"}
                  </Descriptions.Item>
                  <Descriptions.Item label="Network Type">
                    {deviceDetails?.networkType || "N/A"}
                  </Descriptions.Item>
                  <Descriptions.Item label="Downlink Speed">
                    {deviceDetails?.downlink || "N/A"} Mbps
                  </Descriptions.Item>
                  <Descriptions.Item label="Round Trip Time (RTT)">
                    {deviceDetails?.rtt || "N/A"} ms
                  </Descriptions.Item>
                </Descriptions>
                <Divider />
                <List
                  bordered
                  dataSource={[
                    {
                      title: "Webcam Status",
                      icon: <VideoCameraOutlined />,
                      value: webcamActive ? "Active" : "Inactive"
                    },
                    {
                      title: "Mouse Status",
                      icon: <DesktopOutlined />,
                      value: mouseActive ? "Active" : "Inactive"
                    },
                    {
                      title: "Fullscreen Mode",
                      icon: <FullscreenOutlined />,
                      value: isFullscreenActive ? "Active" : "Inactive"
                    }
                  ]}
                  renderItem={(item) => (
                    <List.Item>
                      {item.icon}
                      <span style={{ marginLeft: "8px" }}>
                        {item.title}: {item.value}
                      </span>
                    </List.Item>
                  )}
                />
              </Card>
            </Col>
            <Col xs={24} md={12}>
              <Card
                title="Live Preview"
                bordered={false}
                style={{ marginBottom: "24px" }}
              >
                <div
                  id="test-area"
                  style={{
                    backgroundColor: "#272626",
                    color: "#FAEBD7",
                    width: "100%",
                    height: "300px",
                    marginBottom: "16px",
                    position: "relative"
                  }}
                >
                  <video
                    ref={videoRef}
                    style={{ width: "100%", height: "100%" }}
                    autoPlay
                    playsInline
                  />
                </div>
                <Paragraph>
                  <strong>Trouble with your webcam?</strong>
                </Paragraph>
                <List
                  bordered
                  dataSource={[
                    "Ensure you have granted permission for your browser to access your camera.",
                    "Ensure you are using a supported browser.",
                    "If you have multiple camera devices, ensure your browser and our website are using the correct one.",
                    "Try launching the assessment in incognito mode or a private window.",
                    "Update your camera drivers and web browser.",
                    "Restart your device and try accessing the assessment again.",
                    "Check if your camera is working in other applications to confirm it is not a hardware issue.",
                    "Ensure no other application is using the camera, as it can cause conflicts."
                  ]}
                  renderItem={(item) => (
                    <List.Item>
                      <span>{item}</span>
                    </List.Item>
                  )}
                />
                <Button
                  onClick={() => setInstructionModalVisible(true)}
                  type="primary"
                  className="custom-button"
                >
                  Start Test
                </Button>
              </Card>
            </Col>
          </Row>
        </Card>
      </Col>
      {instructionModalVisible && (
        <InstructionsModal
          instructionModalVisible={instructionModalVisible}
          setInstructionModalVisible={setInstructionModalVisible}
          onStartTest={() => {
            startTest();
            navigate("/dashboard/test");
          }}
        />
      )}
    </Row>
  );
};

export default CameraSetup;
