import React, { useEffect, useState } from "react";
import {
  Form,
  Card,
  Input,
  DatePicker,
  TimePicker,
  Switch,
  Row,
  Col,
  Select,
  InputNumber,
  Checkbox,
  Tooltip,
  Divider
} from "antd";
import { languages, Timezones_List } from "../../../../../Assets/data/common";
import { TagsAssessment } from "../../../data";

const { Option } = Select;
const { TextArea } = Input;

const FinalizeStep = ({ form }) => {
  return (
    <div className="container pb-20">
      <Form form={form} layout="vertical">
        {/* <Card
          style={{
            borderRadius: "18px",
            backgroundColor: "white",
            color: "black",
            marginBottom: "20px",
            padding: "20px"
          }}
          title="Basic Details"
        >
          <Row gutter={20} className="pb-10">
            <Col span={12}>
              <Form.Item
                className="pb-10"
                label="Labels"
                name="labels"
                rules={[{ required: false, message: "Please select a label" }]}
              >
                <Select
                  size="large"
                  placeholder="Select labels"
                  mode="multiple"
                >
                  {TagsAssessment?.map((item) => (
                    <Option key={item} value={item}>
                      {item}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item label="Job Link" name="job_link">
                <Input type="url" size="large" placeholder="Enter job role" />
              </Form.Item>
            </Col>
          </Row>
        </Card> */}

        {/* <Card
          style={{
            borderRadius: "18px",
            backgroundColor: "white",
            color: "black",
            marginBottom: "20px",
            padding: "20px"
          }}
          title="Assessment Administration"
        >
          <Form.Item
            className="pb-4"
            label="Disclaimer"
            name={["assessment_administration", "disclaimer"]}
            valuePropName="checked"
          >
            <Tooltip title="Agree to the disclaimer to enable instruction input">
              <Checkbox
                size="large"
                onChange={(e) => {
                  const checked = e.target.checked;
                  form.setFieldsValue({
                    assessment_administration: { disclaimer: checked }
                  });
                }}
              >
                I agree to the disclaimer
              </Checkbox>
            </Tooltip>
          </Form.Item>

          <Form.Item
            className="pb-40"
            label="Instruction"
            shouldUpdate={(prevValues, currentValues) =>
              prevValues.assessment_administration?.disclaimer !==
              currentValues.assessment_administration?.disclaimer
            }
          >
            {({ getFieldValue }) => {
              const isDisclaimerChecked = getFieldValue([
                "assessment_administration",
                "disclaimer"
              ]);
              return (
                <Form.Item
                  name={["assessment_administration", "instruction"]}
                  rules={[
                    {
                      required: isDisclaimerChecked,
                      message: "Enter assessment instruction!"
                    }
                  ]}
                >
                  <TextArea
                    placeholder="Enter assessment instruction!"
                    rows={6}
                    style={{ width: "100%" }}
                    disabled={!isDisclaimerChecked}
                  />
                </Form.Item>
              );
            }}
          </Form.Item>
          <Form.Item
            className="pb-4"
            label="Anti-cheating settings"
            name={["assessment_administration", "anti_cheating"]}
            valuePropName="checked"
          >
            <Tooltip title="Agree to the Anti-Cheating to enable input">
              <Checkbox
                size="large"
                onChange={(e) => {
                  const checked = e.target.checked;
                  form.setFieldsValue({
                    assessment_administration: { anti_cheating: checked }
                  });
                }}
              >
                Snapshots of candidates will be taken every 30 seconds via
                webcam.
              </Checkbox>
            </Tooltip>
          </Form.Item>
        </Card> */}

        <Card
          style={{
            borderRadius: "18px",
            backgroundColor: "white",
            color: "black",
            marginBottom: "20px",
            padding: "20px"
          }}
          title="Assessment Content"
        >
          <Row gutter={20}>
            {/* <Col span={8}>
              <Form.Item
                label="Allowed Languages"
                name={["assessment_content", "allowed_languages"]}
                rules={[{ required: true, message: "Please select languages" }]}
              >
                <Select
                  size="large"
                  placeholder="Select allowed languages"
                  mode="multiple"
                >
                  {languages?.map((lang) => (
                    <Option key={lang.value} value={lang.value}>
                      {lang.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col> */}
            <Col xs={24} sm={12} md={8} lg={6}>
              <Form.Item
                label="Cutoff Score"
                name={["assessment_content", "cutoff_score"]}
                rules={[{ required: true, message: "Enter cutoff score" }]}
              >
                <InputNumber
                  size="large"
                  style={{ width: "100%" }}
                  min={33}
                  max={100}
                  defaultValue={50}
                />
              </Form.Item>
            </Col>
            <Col xs={24} sm={12} md={8} lg={6}>
              <Form.Item
                label="Allowed Attempts"
                name={["assessment_content", "allowed_attempts"]}
                rules={[{ required: false, message: "Enter allowed attempts" }]}
              >
                <InputNumber
                  size="large"
                  style={{ width: "100%" }}
                  min={1}
                  max={3}
                  disabled={true}
                  defaultValue={1}
                />
              </Form.Item>
            </Col>
          </Row>
        </Card>
        <DeadlinesAndExpiration form={form} />
      </Form>
    </div>
  );
};

export default FinalizeStep;

export const DeadlinesAndExpiration = ({ form }) => {
  const [isCandidateDeadline, setIsCandidateDeadline] = useState(true);
  const [isAssessmentExpiration, setIsAssessmentExpiration] = useState(false);

  useEffect(() => {
    const initialValues = form.getFieldValue("deadlinesAndExpiration");

    if (initialValues) {
      setIsCandidateDeadline(
        initialValues.candidateDeadline?.candidateDeadline_enabled || false
      );
      setIsAssessmentExpiration(
        initialValues.assessmentExpiration?.assessmentExpiration_enabled ||
          false
      );
    }
  }, [form]);

  const handleSwitchChange = (type) => {
    const updatedValues = form.getFieldValue("deadlinesAndExpiration");

    if (type === "candidateDeadline") {
      setIsCandidateDeadline(true);
      setIsAssessmentExpiration(false);
      updatedValues.candidateDeadline.candidateDeadline_enabled = true;
      updatedValues.assessmentExpiration.assessmentExpiration_enabled = false;
    } else {
      setIsCandidateDeadline(false);
      setIsAssessmentExpiration(true);
      updatedValues.candidateDeadline.candidateDeadline_enabled = false;
      updatedValues.assessmentExpiration.assessmentExpiration_enabled = true;
    }

    form.setFieldsValue({ deadlinesAndExpiration: updatedValues });
  };

  return (
    <Form form={form} layout="vertical">
      <Card
        style={{
          borderRadius: "18px",
          backgroundColor: "white",
          color: "black",
          marginBottom: "20px",
          padding: "20px"
        }}
        title="Deadline and Expiration Date"
      >
        <Row gutter={[20, 20]} style={{ marginBottom: "20px" }}>
          <Col span={24}>
            <Form.Item
              name={[
                "deadlinesAndExpiration",
                "candidateDeadline",
                "candidateDeadline_enabled"
              ]}
              valuePropName="checked"
            >
              <Tooltip title="Enable to set a deadline for candidates to complete the assessment within a specified number of days.">
                <Switch
                  size="large"
                  checked={isCandidateDeadline}
                  onChange={() => handleSwitchChange("candidateDeadline")}
                />
                <span style={{ marginLeft: "10px" }}>Candidate Deadline</span>
              </Tooltip>
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={[20, 20]} style={{ marginBottom: "20px" }}>
          <Col span={24}>
            <span>
              This option allows you to set a specific period (in days) within
              which candidates must complete their assessment after receiving
              the invitation. It's ideal for email invitations. Candidates who
              are in progress when the deadline ends can still complete their
              assessment.
            </span>
          </Col>
          <Col xs={24} sm={12} md={8} lg={6}>
            <Form.Item
              label="Candidate Deadline Date"
              name={[
                "deadlinesAndExpiration",
                "candidateDeadline",
                "deadlineDays"
              ]}
              rules={[
                {
                  required: isCandidateDeadline,
                  message: "Please specify the number of days for the deadline."
                }
              ]}
            >
              <InputNumber
                size="large"
                disabled={!isCandidateDeadline}
                min={7}
                defaultValue={7}
                style={{ width: "100%" }}
              />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item
              name={[
                "deadlinesAndExpiration",
                "candidateDeadline",
                "sendRemindersBeforeDeadline"
              ]}
              valuePropName="checked"
              className="mt-10"
            >
              <Tooltip title="Enable to send reminders 24 hours before the deadline.">
                <Checkbox size="large" disabled={!isCandidateDeadline}>
                  Send reminders to candidates 24 hours before the assessment
                  expires.
                </Checkbox>
              </Tooltip>
            </Form.Item>
          </Col>
        </Row>

        <Divider />

        <Row gutter={[20, 20]} style={{ marginBottom: "20px" }}>
          <Col span={24}>
            <Form.Item
              name={[
                "deadlinesAndExpiration",
                "assessmentExpiration",
                "assessmentExpiration_enabled"
              ]}
              valuePropName="checked"
            >
              <Tooltip title="Enable to set an expiration date and time for the assessment">
                <Switch
                  size="large"
                  checked={isAssessmentExpiration}
                  onChange={() => handleSwitchChange("assessmentExpiration")}
                />
                <span style={{ marginLeft: "10px" }}>
                  Assessment Expiration Date
                </span>
              </Tooltip>
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={[20, 20]}>
          <Col span={24}>
            <span>
              This option enables you to set an exact date and time when the
              assessment will no longer be accessible. This is especially useful
              when using a shareable link. Candidates who are in progress at the
              time of expiration can still finish.
            </span>
          </Col>
          <Col xs={24} sm={12} md={8} lg={6}>
            <Form.Item
              label="Assessment Expiration Date"
              name={[
                "deadlinesAndExpiration",
                "assessmentExpiration",
                "expirationDate"
              ]}
              rules={[
                {
                  required: isAssessmentExpiration,
                  message: "Please select an expiration date"
                }
              ]}
            >
              <DatePicker
                size="large"
                disabled={!isAssessmentExpiration}
                format="YYYY-MM-DD"
                style={{ width: "100%" }}
              />
            </Form.Item>
          </Col>
          <Col xs={24} sm={12} md={8} lg={6}>
            <Form.Item
              label="Expiration Time"
              name={[
                "deadlinesAndExpiration",
                "assessmentExpiration",
                "expirationTime"
              ]}
              rules={[
                {
                  required: isAssessmentExpiration,
                  message: "Please select an expiration time"
                }
              ]}
            >
              <TimePicker
                format="HH:mm"
                size="large"
                disabled={!isAssessmentExpiration}
                style={{ width: "100%" }}
              />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item
              label="Send Reminders Before Expiration (hours)"
              name={[
                "deadlinesAndExpiration",
                "assessmentExpiration",
                "sendRemindersBeforeExpiration"
              ]}
              valuePropName="checked"
              className="mt-10"
            >
              <Tooltip title="Send reminders to candidates before the assessment expires">
                <Checkbox size="large" disabled={!isAssessmentExpiration}>
                  Send reminders to candidates before the assessment expires.
                </Checkbox>
              </Tooltip>
            </Form.Item>
          </Col>
        </Row>
      </Card>
    </Form>
  );
};
