import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getAllScheduleInterviewListAction } from "../../redux/actions/interviewer";
import { TailSpin } from "react-loader-spinner";
import {
  Button,
  Table,
  message,
  Select,
  Layout,
  Form,
  Collapse,
  Pagination,
  Radio,
  Input,
  DatePicker,
  Avatar,
  Drawer,
  Tabs
} from "antd";
import { ReloadOutlined } from "@ant-design/icons";
import moment from "moment";
import { INTERVIEW_ROUNDS } from "../../Assets/data/common";
import InterviewDetailsDrawer from "./InterviewDetailsModal";
import { UserOutlined } from "@ant-design/icons";
import { isMentorpalTeamUser } from "../../Utils/Common";
import ClientCustomSelect from "../../Utils/ClientSelect/clientSelect";
import("../../Assets/css/filter.css");

const { Option } = Select;
const { Sider, Content } = Layout;
const { Panel } = Collapse;
const { RangePicker } = DatePicker;
const FilterForm = ({
  filters,
  handleFilterChange,
  setFilters,
  setCurrentPage,
  setActiveTab
}) => (
  <Form
    layout="vertical"
    onValuesChange={(changedValues) => {
      Object.keys(changedValues).forEach((key) =>
        handleFilterChange(key, changedValues[key])
      );
    }}
    initialValues={filters}
  >
    <div className="d-flex justify-content-between align-items-center mb-3">
      <span className="d-flex align-items-center">
        <h2 className="m-0 ml-2 mt-3">Filters</h2>
      </span>
      <Button
        type=""
        className="cursor-pointer mt-3"
        onClick={() => {
          const resetFilters = {
            status: "",
            summary: "",
            client_id: "",
            candidate_id: "",
            job_id: "",
            startDateTime: null
          };
          setFilters(resetFilters);
          setActiveTab("today");
          setCurrentPage(1);
        }}
      >
        Reset all
      </Button>
    </div>
    <Collapse
      defaultActiveKey={["1", "2", "3", "4", "5"]}
      expandIconPosition="right"
      style={{ background: "#fff", border: "none" }}
    >
      <Panel
        header="Search by title"
        key="1"
        style={{ background: "#ffff", border: "none" }}
      >
        <Form.Item>
          <Input
            placeholder="Search by title"
            value={filters?.summary || ""}
            onChange={(e) => handleFilterChange("summary", e.target.value)}
            size="large"
          />
        </Form.Item>
      </Panel>
      <Panel
        header="Interview Round"
        key="2"
        style={{ background: "#ffff", border: "none" }}
      >
        <Select
          className="w-100"
          value={filters?.interviewRound}
          onChange={(value) => handleFilterChange("interviewRound", value)}
          placeholder="Select a round"
          size="large"
        >
          <Option value="">All</Option>
          {INTERVIEW_ROUNDS.map((item) => (
            <Option key={item.label} value={item.label}>
              {item.label}
            </Option>
          ))}
        </Select>
      </Panel>
      {isMentorpalTeamUser() && (
        <Panel
          header="Client"
          key="3"
          style={{ background: "#ffff", border: "none" }}
        >
          <ClientCustomSelect
            type={"filter"}
            value={filters.client_id}
            onChange={(value) => handleFilterChange("client_id", value)}
          />
        </Panel>
      )}

      <Panel
        header="Interview Status"
        key="4"
        style={{ background: "#ffff", border: "none" }}
      >
        <Select
          style={{ width: "100%" }}
          value={filters?.status}
          onChange={(value) => handleFilterChange("status", value)}
          placeholder="Select status"
          size="large"
        >
          <Select.Option value="">All</Select.Option>
          <Select.Option value="scheduled">Scheduled</Select.Option>
          <Select.Option value="in_progress">In Progress</Select.Option>
          <Select.Option value="completed">Completed</Select.Option>
          <Select.Option value="cancelled">Cancelled</Select.Option>
          <Select.Option value="rescheduled">Rescheduled</Select.Option>
        </Select>
      </Panel>

      <Panel
        header="Start Date & Time"
        key="5"
        style={{ background: "#ffff", border: "none" }}
      >
        <DatePicker
          style={{ width: "100%" }}
          value={filters.startDateTime ? moment(filters.startDateTime) : null}
          onChange={(date, dateString) =>
            handleFilterChange(
              "startDateTime",
              date ? date.format("YYYY-MM-DD") : null
            )
          }
          format="YYYY-MM-DD"
          size="large"
        />
      </Panel>
    </Collapse>
  </Form>
);

const InterViewListing = () => {
  const dispatch = useDispatch();
  const [openDrawer, setOpenDrawer] = useState({
    show: false,
    interview_id: ""
  });

  const [loading, setloading] = useState(false);
  const [data, setData] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [totalUsers, setTotalUsers] = useState(0);
  const [filters, setFilters] = useState({
    status: "",
    summary: "",
    client_id: "",
    candidate_id: "",
    job_id: "",
    startDateTime: null,
    period: "today"
  });
  const [activeTab, setActiveTab] = useState("today");
  const [isMobileFilterDrawerVisible, setIsMobileFilterDrawerVisible] =
    useState(false);

  const getAllScheduleInterviewListReducer = useSelector(
    (state) => state.getAllScheduleInterviewListReducer
  );

  const getInterviewDetailsReducer = useSelector(
    (state) => state.getInterviewDetailsReducer
  );

  const handleFilterChange = (key, value) => {
    setFilters((prevFilters) => {
      const updatedFilters = { ...prevFilters, [key]: value };
      if (key === "startDateTime" && key) {
        updatedFilters.period = "";
        setActiveTab('all')
      }

      return updatedFilters;
    });
    setCurrentPage(1);
  };

  const handleTabChange = (key) => {
    setActiveTab(key);

    // Update filters based on tab selection
    const periodMap = {
      today: { period: "today" },
      next7days: { period: "next_7_days" },
      last7days: { period: "last_7_days" },
      all: { period: "" },
    };

    setFilters((prevFilters) => ({
      ...prevFilters,
      ...periodMap[key],
      startDateTime: null
    }));

    setCurrentPage(1);
  };
  useEffect(() => {
    dispatch(
      getAllScheduleInterviewListAction({
        ...filters,
        page: currentPage,
        limit: pageSize
      })
    );
    return () => {
      dispatch({ type: "GET_SCHEDULE_INTERVIEW_LIST_RESET" });
    };
  }, [dispatch, pageSize, currentPage, filters]);

  useEffect(() => {
    const { loading, status, error, data } = getAllScheduleInterviewListReducer;
    setloading(loading);
    if (!loading && data && !error) {
      const formattedData = data.data?.map((item, index) => {
        return {
          key: item.interview_id,
          serialNumber: index + 1,
          external_attendees: item.external_attendees || [],
          internal_attendees: item.internal_attendees || [],
          startDateTime: moment(item.startDateTime).format(
            "YYYY-MM-DD HH:mm:ss"
          ),
          endDateTime: moment(item.endDateTime).format("YYYY-MM-DD HH:mm:ss"),
          meetingUrl: item.meeting_url,
          status: item.status,
          summary: item.summary,
          job_location: item.job_location,
          job_title: item.job_title,
          job_id: item.job_id,
          interviewRound: item.interviewRound,
          assignData: item.assignData,
          remarks: item?.feedback,
          location: item?.location
        };
      });
      setData(formattedData);
      setTotalUsers(data?.total);
    }
    if (!loading && !data && error) {
      setData([]);

      message.error(error);
    }
  }, [getAllScheduleInterviewListReducer]);

  const isMobile = window.innerWidth <= 768;

  const columns = [
    {
      title: "S.No",
      dataIndex: "serialNumber",
      key: "serialNumber",
      fixed: !isMobile ? "left" : undefined,
      width: 100,
      sorter: (a, b) => a.serialNumber - b.serialNumber,
      sortDirections: ["ascend", "descend"],
      ellipsis: true
    },
    {
      title: "Title",
      dataIndex: "summary",
      key: "summary",
      fixed: !isMobile ? "left" : undefined,
      width: 250,
      sorter: (a, b) => a.summary.localeCompare(b.summary),
      sortDirections: ["ascend", "descend"],
      render: (text, record) => (
        <a
          onClick={() =>
            setOpenDrawer({
              show: true,
              interview_id: record.key
            })
          }
          style={{
            cursor: "pointer",
            color: "#005c53",
            textDecoration: "underline",
            textOverflow: "ellipsis"
          }}
          title={text}
        >
          {text}
        </a>
      )
    },
    {
      title: "Recruiter",
      dataIndex: "assignData",
      key: "assignData",
      width: 250,
      render: (text) => (
        <>
          {text?.userIdsDetails?.length > 0
            ? text?.userIdsDetails?.map((item, index) => (
                <div
                  key={index}
                  style={{
                    display: "flex",
                    alignItems: "center",
                    marginBottom: 8
                  }}
                >
                  <div>
                    <Avatar
                      src={
                        item?.photo
                          ? `${process.env.REACT_APP_IMAGES_BASE_URL}${item?.photo}`
                          : undefined
                      }
                      icon={!item?.photo ? <UserOutlined /> : null}
                      alt={item?.name || "User"}
                      style={{ marginRight: 8, backgroundColor: "#f0f0f0" }}
                    />
                  </div>
                  <div>
                    <a title={`${item?.name || ""}`}>
                      {`${item?.name || "-"}`}
                    </a>
                  </div>
                </div>
              ))
            : "-"}
        </>
      )
    },
    {
      title: "Candidate Name",
      dataIndex: "internal_attendees",
      key: "internal_attendees",
      width: 200,
      render: (text) => (
        <>
          {text?.length > 0
            ? text.map((item, index) => (
                <div
                  key={index}
                  style={{
                    display: "flex",
                    alignItems: "center",
                    marginBottom: 8
                  }}
                >
                  <div>
                    <Avatar
                      src={
                        item?.photo
                          ? `${process.env.REACT_APP_IMAGES_BASE_URL}${item?.photo}`
                          : undefined
                      }
                      icon={!item?.photo ? <UserOutlined /> : null}
                      alt={item?.first_name || "User"}
                      style={{ marginRight: 8, backgroundColor: "#f0f0f0" }}
                    />
                  </div>
                  <div>
                    <a
                      title={`${item?.first_name || ""} ${
                        item?.last_name || ""
                      }`}
                    >
                      {`${item?.first_name || "-"} ${item?.last_name || "-"}`}
                    </a>
                  </div>
                </div>
              ))
            : "-"}
        </>
      )
    },
    {
      title: "Position",
      dataIndex: "job_title",
      key: "job_title",
      width: 250,
      sorter: (a, b) => a.job_title.localeCompare(b.job_title),
      sortDirections: ["ascend", "descend"]
    },

    {
      title: "Round",
      dataIndex: "interviewRound",
      key: "interviewRound",
      width: 150,
      sorter: (a, b) => a.interviewRound.localeCompare(b.interviewRound),
      sortDirections: ["ascend", "descend"]
    },
    {
      title: "Email",
      dataIndex: "internal_attendees",
      key: "internal_attendees",
      width: 250,
      render: (text) => (
        <>
          {text?.length > 0
            ? text.map((item, index) => (
                <div key={index} style={{ marginBottom: 8 }}>
                  {item?.email || "-"}
                </div>
              ))
            : "-"}
        </>
      )
    },

    {
      title: "Contact Number",
      dataIndex: "internal_attendees",
      key: "internal_attendees",
      width: 200,
      render: (text) => (
        <>
          {text?.length > 0
            ? text.map((item, index) => (
                <div key={index} style={{ marginBottom: 8 }}>
                  {item?.phone || "-"}
                </div>
              ))
            : "-"}
        </>
      )
    },
    {
      title: "DateTime",
      key: "dateTime",
      sorter: (a, b) => new Date(a.startDateTime) - new Date(b.startDateTime),
      sortDirections: ["ascend", "descend"],
      render: (record) => {
        const startDate = new Date(record.startDateTime);
        const endDate = new Date(record.endDateTime);

        const formattedDate = startDate.toLocaleString("en-GB", {
          day: "2-digit",
          month: "short",
          year: "numeric"
        });

        const formattedStartTime = startDate.toLocaleString("en-GB", {
          hour: "2-digit",
          minute: "2-digit",
          hour12: true
        });

        const formattedEndTime = endDate.toLocaleString("en-GB", {
          hour: "2-digit",
          minute: "2-digit",
          hour12: true
        });

        return `${formattedDate} ${formattedStartTime} - ${formattedEndTime}`;
      },
      width: 300
    },

    {
      title: "Panel Name",
      dataIndex: "external_attendees",
      key: "external_attendees",
      width: 250,
      render: (text) => (
        <>
          {text?.length > 0
            ? text.map((item, index) => (
                <div
                  key={index}
                  style={{
                    display: "flex",
                    alignItems: "center",
                    marginBottom: 8
                  }}
                >
                  <div>
                    <Avatar
                      src={
                        item?.photo
                          ? `${process.env.REACT_APP_IMAGES_BASE_URL}${item?.photo}`
                          : undefined
                      }
                      icon={!item?.photo ? <UserOutlined /> : null}
                      alt={item?.first_name || "User"}
                      style={{ marginRight: 8, backgroundColor: "#f0f0f0" }}
                    />
                  </div>
                  <div>
                    <a
                      title={`${item?.first_name || ""} ${
                        item?.last_name || ""
                      }`}
                    >
                      {`${item?.first_name || "-"} ${item?.last_name || "-"}`}
                    </a>
                  </div>
                </div>
              ))
            : "-"}
        </>
      )
    },
    {
      title: "Location",
      dataIndex: "location",
      key: "location",
      width: 250,
      sorter: (a, b) => a.location.localeCompare(b.location),
      sortDirections: ["ascend", "descend"],
      render: (text) => (
        <div style={{ display: "flex", alignItems: "center" }}>
          <span
            style={{
              display: "inline-block",
              width: 10,
              height: 10,
              borderRadius: "50%",
              backgroundColor:
                {
                  "Virtual (Google Meet)": "#34c759",
                  "Virtual (Zoom)": "#007aff",
                  "Virtual (Microsoft Teams)": "#6264a7",
                  "Client Office": "#ff9500",
                  "Company Office": "#ff3b30",
                  "Offsite Meeting": "#5ac8fa",
                  "Manual Meeting": "#ffcc00",
                  "Phone Call": "#ff2d55",
                  Other: "#d9d9d9"
                }[text] || "#d9d9d9",
              marginRight: 8
            }}
          />
          {text}
        </div>
      )
    },

    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      width: 150,
      filters: [
        { text: "Scheduled", value: "scheduled" },
        { text: "In Progress", value: "in_progress" },
        { text: "Completed", value: "completed" },
        { text: "Cancelled", value: "cancelled" },
        { text: "Rescheduled", value: "rescheduled" }
      ],
      onFilter: (value, record) => record.status === value,
      render: (status) => {
        const statusColors = {
          scheduled: "#5cb85c",
          in_progress: "#f0ad4e",
          completed: "#0275d8",
          cancelled: "#d9534f",
          rescheduled: "#5bc0de"
        };

        return (
          <>
            <span
              style={{
                display: "inline-block",
                width: 12,
                height: 12,
                borderRadius: "50%",
                backgroundColor: statusColors[status] || "#6c757d",
                marginRight: 8
              }}
            />
            {status || "-"}
          </>
        );
      }
    },
    {
      title: "Remarks",
      dataIndex: "remarks",
      key: "remarks",
      width: 150,
      render: (remarks) => remarks || "-"
    }
  ];

  const handleRefresh = () => {
    dispatch(
      getAllScheduleInterviewListAction({
        ...filters,
        page: currentPage,
        limit: pageSize
      })
    );
  };

  const handleChange = (key, value) => {
    setFilters((prevFilters) => ({ ...prevFilters, [key]: value }));
  };

  const toggleFilters = () =>
    setIsMobileFilterDrawerVisible(!isMobileFilterDrawerVisible);
  const closeDrawer = () => setIsMobileFilterDrawerVisible(false);

  return (
    <>
      <div className="bg-white" data-ui="header">
        <div className="px-4">
          <div className="flag">
            <div className="flag__section">
              <a className="external incognito-link inline-block mb-m mt-m">
                <h1 className="js-main-header-title main-header__title ">
                  Interviews
                </h1>
              </a>
            </div>
          </div>
        </div>
      </div>

      <Layout style={{ background: "#fff", height: "100vh" }}>
        {!isMobile && (
          <Sider width={300} className="site-layout-background bg-light">
            <div className="px-3 bg-light" style={{ height: "100vh" }}>
              <FilterForm
                filters={filters}
                handleFilterChange={handleFilterChange}
                handleChange={handleChange}
                setFilters={setFilters}
                setCurrentPage={setCurrentPage}
                setActiveTab={setActiveTab}
              />
            </div>
          </Sider>
        )}
        <Layout>
          <Button
            className="filter-toggle-button"
            onClick={toggleFilters}
            type="primary"
            style={{ backgroundColor: "#00756a" }}
          >
            Apply Filters
          </Button>
          {loading ? (
            <div
              style={{
                position: "fixed",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                zIndex: 9999
              }}
            >
              <TailSpin ariaLabel="Loading..." color="#005c53" />
            </div>
          ) : (
            <Content className="px-4">
              <div className="cl-section-header row justify-content-between align-items-end py-3">
                <div className="col d-flex align-items-center">
                  <Tabs
                    defaultActiveKey="today"
                    activeKey={activeTab}
                    onChange={handleTabChange}
                  >
                    <Tabs.TabPane tab="Today" key="today" />
                    <Tabs.TabPane tab="Next 7 Days" key="next7days" />
                    <Tabs.TabPane tab="Last 7 Days" key="last7days" />
                    <Tabs.TabPane tab="All" key="all" />
                  </Tabs>
                </div>
                <div className="cl-section-header__action-buttons-wrapper col-auto d-flex align-items-center">
                  <Button
                    className="ms-2"
                    onClick={handleRefresh}
                    icon={<ReloadOutlined />}
                    type="primary"
                    style={{ backgroundColor: "#00756a" }}
                  >
                    Refresh
                  </Button>
                </div>
              </div>
              <Table
                bordered
                columns={columns}
                dataSource={data}
                pagination={false}
                rowKey="user_id"
                scroll={{
                  x: 1300
                }}
              />
              <Pagination
                className="text-center align-content-center mt-3"
                total={totalUsers}
                current={currentPage}
                pageSize={pageSize}
                showSizeChanger
                pageSizeOptions={[5, 10, 20, 50, 100]}
                showTotal={(total) => `Total ${total} interviews`}
                onChange={(page, pageSize) => {
                  setCurrentPage(page);
                  setPageSize(pageSize);
                }}
              />
            </Content>
          )}
        </Layout>
      </Layout>

      <Drawer
        visible={isMobileFilterDrawerVisible}
        onClose={closeDrawer}
        placement="left"
        width="80%"
        className="filter-drawer"
        bodyStyle={{ paddingTop: 0 }}
      >
        <FilterForm
          filters={filters}
          handleFilterChange={handleFilterChange}
          handleChange={handleChange}
          setFilters={setFilters}
          setCurrentPage={setCurrentPage}
        />
      </Drawer>

      {openDrawer?.show && openDrawer?.interview_id && (
        <InterviewDetailsDrawer
          openDrawer={openDrawer}
          setOpenDrawer={setOpenDrawer}
        />
      )}
    </>
  );
};

export default InterViewListing;
