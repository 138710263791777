import React, { useState, useEffect } from "react";
import {
  Button,
  Form,
  Input,
  Layout,
  Collapse,
  Radio,
  Slider,
  Divider,
  Drawer,
} from "antd";
import { useDispatch } from "react-redux";
import { getAllQuestionListAction } from "../../../redux/actions/assessment";
import QuestionTypeModal from "./Modal/QuestionTypeModal";
import { Outlet } from "react-router-dom";
import ("../../../Assets/css/filter.css");

const { Sider, Content } = Layout;
const { Panel } = Collapse;

const FilterForm = ({
  filters,
  handleFilterChange,
  handleClearFilters,
  handleChange,
}) => (
  <Form
    layout="vertical"
    onValuesChange={handleFilterChange}
    initialValues={filters}
    className="pt-3"
  >
    <div className="d-flex justify-content-between align-items-center mb-3">
      <span className="d-flex align-items-center">
        <h2 className="m-0 ml-2 mt-2">Filters</h2>
      </span>

      <Button type="" className="cursor-pointer mt-2" onClick={handleClearFilters}>
        Reset all
      </Button>
    </div>
    <Collapse
      defaultActiveKey={["1", "2", "3"]}
      expandIconPosition="right"
      style={{ background: "#ffff", border: "none" }}
    >
      <Panel
        header="Search by question name"
        key="1"
        style={{ background: "#ffff", border: "none" }}
      >
        <Form.Item name="search">
          <Input placeholder="Type here..." size="large" />
        </Form.Item>
      </Panel>
      <Panel
        header="Select Question Type"
        key="2"
        style={{ background: "#ffff", border: "none" }}
      >
        <Form.Item name="questionType">
          <Radio.Group
            onChange={(e) =>
              handleFilterChange({ questionType: e.target.value })
            }
            value={filters.questionType}
          >
            <div className="d-flex justify-content-between mb-2">
              <Radio value="">All</Radio>
            </div>
            <div className="d-flex justify-content-between mb-2">
              <Radio value="mcq">Multiple Choice</Radio>
            </div>
            <div className="d-flex justify-content-between mb-2">
              <Radio value="code">Coding</Radio>
            </div>
            <div className="d-flex justify-content-between mb-2">
              <Radio value="questionnaire">Questionnaire</Radio>
            </div>
          </Radio.Group>
        </Form.Item>
      </Panel>
      <Panel
        header="Duration"
        key="3"
        style={{ background: "#fff", border: "none" }}
      >
        <Slider
          range
          marks={{
            0: "0",
            135: "135",
          }}
          defaultValue={[0, 135]}
          value={filters.recommendedTime}
          max={135}
          onChange={(value) => handleChange("recommendedTime", value)}
        />
        <div className="d-flex justify-content-between">
          <Input
            placeholder="From"
            value={filters.recommendedTime[0]}
            style={{ width: "48%" }}
            onChange={(e) =>
              handleChange("recommendedTime", [
                Number(e.target.value),
                filters.recommendedTime[1],
              ])
            }
            size="large"
          />
          <Input
            placeholder="To"
            value={filters.recommendedTime[1]}
            style={{ width: "48%" }}
            onChange={(e) =>
              handleChange("recommendedTime", [
                filters.recommendedTime[0],
                Number(e.target.value),
              ])
            }
            size="large"
          />
        </div>
      </Panel>
    </Collapse>
  </Form>
);

const AssessmentQuestions = () => {
  const [QuestionModal, setQuestionModal] = useState(false);

  const [filters, setFilters] = useState({
    questionType: "",
    search: "",
    recommendedTime: "",
  });
  const [contentHeight, setContentHeight] = useState("100vh");
  const [form] = Form.useForm();
  const dispatch = useDispatch();

  const [isMobileFilterDrawerVisible, setIsMobileFilterDrawerVisible] =
    useState(false);

  useEffect(() => {
    fetchQuestionList();
    return () => {
      dispatch({ type: "GET_LIST_QUESTION_RESET" });
    };
  }, [filters, dispatch]);

  const fetchQuestionList = () => {
    dispatch(
      getAllQuestionListAction({
        page: 1,
        limit: 10,
        ...filters,
      })
    );
  };

  const handleFilterChange = (changedFields) => {
    const newFilters = { ...filters, ...changedFields };
    setFilters(newFilters);
  };

  const handleClearFilters = () => {
    setFilters({
      questionType: "",
      search: "",
      recommendedTime: "",
    });
  };

  const handleChange = (key, value) => {
    setFilters((prevFilters) => ({ ...prevFilters, [key]: value }));
  };

  const toggleFilters = () =>
    setIsMobileFilterDrawerVisible(!isMobileFilterDrawerVisible);
  const closeDrawer = () => setIsMobileFilterDrawerVisible(false);
  const isMobile = window.innerWidth <= 768;

  return (
    <>
      <div className="bg-white" data-ui="header">
        <div className=" px-4">
          <div className="flag">
            <div className="flag__section">
              <a className="external incognito-link inline-block mb-m mt-m">
                <h1 className="js-main-header-title main-header__title mr-3">
                  Question Bank
                </h1>
              </a>
            </div>

            <div className="flag__section flag__section--tight flag__section--top js-main-header-actions">
              <Button
                type="button"
                onClick={() => setQuestionModal(true)}
                className="btn btn--primary mt-m text-white pull-right main_border_radius"
              >
                Create Question
              </Button>
            </div>
          </div>
        </div>
      </div>

      <Layout style={{ background: "#ffff", height: "100vh" }}>
        {!isMobile && (
          <Sider width={300} className="site-layout-background">
            <div className="px-3 bg-light" style={{ height: contentHeight }}>
              <FilterForm
                filters={filters}
                handleClearFilters={handleClearFilters}
                handleChange={handleChange}
                handleFilterChange={handleFilterChange}
              />
            </div>
          </Sider>
        )}
        
        <Layout style={{ padding: "0 5px 5px", minHeight: "100vh" }}>
          <Button
            className="filter-toggle-button"
            onClick={toggleFilters}
            type="primary"
            style={{ backgroundColor: "#00756a" }}
          >
            Apply Filters
          </Button>
          <Content
            className="site-layout-background"
            style={{
              padding: 0,
              margin: 0,
            }}
          >
            <Outlet />
          </Content>
        </Layout>
      </Layout>

      {QuestionModal && (
        <QuestionTypeModal
          QuestionModal={QuestionModal}
          setQuestionModal={setQuestionModal}
        />
      )}
      <Drawer
        visible={isMobileFilterDrawerVisible}
        onClose={closeDrawer}
        placement="left"
        width="80%"
        className="filter-drawer"
        bodyStyle={{ paddingTop: 0 }} 
      >
        <FilterForm
          filters={filters}
          handleChange={handleChange}
          handleClearFilters={handleClearFilters}
          handleFilterChange={handleFilterChange}
        />
      </Drawer>
    </>
  );
};

export default AssessmentQuestions;
