import { useRoutes } from "react-router-dom";
import ApplyJob from "./Components/ApplyJob";
import AssessmentCards from "./Components/Assessment/AssessmentMain/AssessmentCards";
import AssessmentDetails from "./Components/Assessment/AssessmentMain/AssessmentDetails";
import CreateAssessmentModule from "./Components/Assessment/AssessmentMain/CreateAssessment/CreateModule";
import AssessmentMain from "./Components/Assessment/AssessmentMain/index";
import AssessmentCandidates from "./Components/Assessment/Candidates";
import AssessmentQuestions from "./Components/Assessment/Questions";
import CreateQuestion from "./Components/Assessment/Questions/CreateQuestions";
import CardsListing from "./Components/Assessment/Questions/QuestionCards";
import DetailedQuestionPage from "./Components/Assessment/Questions/QuestionDetail";
import AssessmentTest from "./Components/Assessment/Test";
import TestDetailsPage from "./Components/Assessment/Test/TestDetails";
import { TestTable } from "./Components/Assessment/Test/TestTable";
import AssessmentLogin from "./Components/AssessmentCandidate/AssessmentLogin";
import AssessmentTestDashboard from "./Components/AssessmentCandidate/AssessmentTestDashboard";
import AssessmentCandidateMain from "./Components/AssessmentCandidate/AssessmentTestDashboard/Assessment";
import AssessmentCompletionCard from "./Components/AssessmentCandidate/AssessmentTestDashboard/AssessmentCompleteionCard";
import CameraSetup from "./Components/AssessmentCandidate/AssessmentTestDashboard/AssessmentSetup";
import AssessmentTips from "./Components/AssessmentCandidate/AssessmentTestDashboard/AssessmentTips";
import AssessmentFeedbackForm from "./Components/AssessmentCandidate/AssessmentTestDashboard/TestFeedbackForm";
import AssessmentReport from "./Components/CandidateDashboard/Assessment/AssessmentReport";
import CandidateLogin from "./Components/CandidateDashboard/Login";
import CandidateProfile from "./Components/CandidateDashboard/Pages/CandidateProfile";
import CandidateHome from "./Components/CandidateDashboard/Pages/Home";
import CareersPage from "./Components/CarrierPage";
import ClientDashboard from "./Components/Client";
import { ClientTable } from "./Components/Client/ClientListing";
import { ClientProfile } from "./Components/Client/ClientProfile";
import CreateClient from "./Components/Client/CreateClient";
import CandidateMainProfile from "./Components/Dashboard/CandidateProfile";
import MainDashboard from "./Components/Dashboard/mainDashboard";
import Home from "./Components/Home";
import HireTalentLanding from "./Components/Home/home";
import Login from "./Components/Login";
import MatchMasterAI from "./Components/MatchMasterAI";
import Profile from "./Components/Profile";
import ContactUs from "./Components/PublicPage/ContactUs";
import ForgetPassword from "./Components/PublicPage/ForgetPassword";
import PageNotFound from "./Components/PublicPage/PageNotFound";
import Registration from "./Components/Registration";
import EnquiryDashboard from "./Components/EnquiryDashboard";
import EnquiryListing from "./Components/EnquiryDashboard/EnquiryListing";
import MasterAdminCreateNewUser from "./Components/RoleManagementDashboard/MasterAdminCreateNewUser";
import MasterAdminUserManagementMain from "./Components/RoleManagementDashboard/MasterAdminUserManagementMain";
import ClientAdminUserManagementMain from "./Components/RoleManagementDashboard/ClientRoleManagement/ClientAdminUserMain";
import CreateJob from "./Components/Job/CreateJob";
import JobDetail from "./Components/Job/jobDetail";
import ClientReport from "./Components/Report/ClientReport";
import CustomerTerms from "./Components/Privacy&Policy/CustomerTerms";
import CandidateTerms from "./Components/Privacy&Policy/CandidateTerms";
import PrivacyPolicy from "./Components/Privacy&Policy/PrivacyPolicy";
import InfoSecurity from "./Components/Privacy&Policy/InfoSecurity";
import DataProcessing from "./Components/Privacy&Policy/DataProcessing";
import CookiePolicy from "./Components/Privacy&Policy/CookiePolicy";
import CreateTestModules from "./Components/Assessment/Test/test-library/CreateTestModule";
import JobsListing from "./Components/Job/JobsListing";
import JobComponent from "./Components/Job";
import InterViewListing from "./Components/InterviewScheduling/InterViewListing";
import FeedbackListing from "./Components/FeedbackPage/FeedbackListing";
import FeedbackDashboard from "./Components/FeedbackPage";
import Unsubscribe from "./Components/PublicPage/Unsubscribe";
import TimeLineLogs from "./Components/TimelIneLogs";
import EmailTemplates from "./Components/EmailTemplates";
import InterviewCalendar from "./Components/InterviewScheduling/InterviewCalender";
import DashboardHome from "./Components/Dashboard/dashboardHome";
import CandidatesComponent from "./Components/Dashboard/Candidates";
import JobReports from "./Components/Job/JobReports/JobReports";
import VerificationErrorPage from "./Utils/VerificationPage";

export const PublicRoutes = () => {
  return useRoutes([
    { path: "/", sensitive: true, element: <HireTalentLanding /> },
    { path: "/jobs", sensitive: true, element: <Home /> },
    { path: "reset-password", sensitive: true, element: <ForgetPassword /> },
    { path: "login", sensitive: true, element: <Login /> },
    { path: "*", sensitive: true, element: <PageNotFound status={404} /> },
    { path: "carrier-page/:id", element: <CareersPage /> },
    { path: "apply-job", element: <ApplyJob /> },
    { path: "apply-job/:id", element: <ApplyJob /> },
    { path: "contact-us", element: <ContactUs /> },
    { path: "terms", element: <CustomerTerms /> },
    { path: "candidate-terms", element: <CandidateTerms /> },
    { path: "privacy-policy", element: <PrivacyPolicy /> },
    { path: "security-measures", element: <InfoSecurity /> },
    { path: "dpa", element: <DataProcessing /> },
    { path: "cookie-policy", element: <CookiePolicy /> },
    { path: "email-subscribe", element: <Unsubscribe /> },
    {
      path: "assessment",
      element: <AssessmentLogin />
    },
    { path: "assessment-completed", element: <AssessmentCompletionCard /> },
    {
      path: "finalEvaluation",
      element: <AssessmentFeedbackForm />
    },
    {
      path: "candidate-login",
      element: <CandidateLogin />
    }
  ]);
};
export const AtsInterviewerRoutes = () => {
  return useRoutes([
    { path: "*", sensitive: true, element: <PageNotFound status={404} /> },
    { path: "registration", element: <Registration /> },
    {
      path: "dashboard",
      element: <MainDashboard />,
      children: [
        {
          path: "home",
          element: <DashboardHome />
        },
        { path: "profile", element: <Profile /> },
        { path: "interview", element: <InterViewListing /> },
        {
          path: "interview-calendar",
          element: <InterviewCalendar />
        }
      ]
    }
  ]);
};

export const AtsOwnerRoutes = () => {
  return useRoutes([
    { path: "carrier-page/:id", element: <CareersPage /> },
    { path: "registration", element: <Registration /> },
    { path: "*", sensitive: true, element: <PageNotFound status={404} /> },
    { path: "apply-job/:id", element: <ApplyJob /> },
    {
      path: "/dashboard",
      sensitive: true,
      element: <MainDashboard />,
      children: [
        {
          path: "jobs",
          element: <JobComponent />,
          children: [
            {
              path: "",
              element: <JobsListing />
            },
            {
              path: "job-reports",
              element: <JobReports />
            }
          ]
        },
        {
          path: "home",
          element: <DashboardHome />
        },

        {
          path: "candidates",
          element: <CandidatesComponent />
        },
        { path: "profile", element: <Profile /> },
        { path: "create-job", element: <CreateJob /> },
        { path: "candidate-profile/:id", element: <CandidateMainProfile /> },
        { path: "job-detail", element: <JobDetail /> },
        { path: "job-detail/:id", element: <JobDetail /> },

        { path: "edit-job", element: <CreateJob /> },
        { path: "match-master", element: <MatchMasterAI /> },
        {
          path: "user-management",
          element: <ClientAdminUserManagementMain />
        },
        {
          path: "create-user",
          element: <MasterAdminCreateNewUser />
        },
        {
          path: "update-user",
          element: <MasterAdminCreateNewUser />
        },
        { path: "interview", element: <InterViewListing /> },
        {
          path: "interview-calendar",
          element: <InterviewCalendar />
        },
        {
          path: "report",
          element: <ClientReport />
        },
        // assessments routes below
        { path: "candidates-assessment-report", element: <AssessmentReport /> },
        {
          path: "assessment",
          element: <AssessmentMain />,
          children: [{ path: "", element: <AssessmentCards /> }]
        },
        {
          path: "assessment/create-assessment",
          element: <CreateAssessmentModule />
        },
        {
          path: "assessment/edit-assessment",
          element: <CreateAssessmentModule />
        },

        { path: "assessment/details", element: <AssessmentDetails /> }
      ]
    }
  ]);
};
export const AtsRecruiterRoutes = () => {
  return useRoutes([
    { path: "carrier-page/:id", element: <CareersPage /> },
    { path: "registration", element: <Registration /> },
    { path: "*", sensitive: true, element: <PageNotFound status={404} /> },
    { path: "apply-job/:id", element: <ApplyJob /> },
    {
      path: "/dashboard",
      sensitive: true,
      element: <MainDashboard />,
      children: [
        {
          path: "home",
          element: <DashboardHome />
        },
        {
          path: "jobs",
          element: <JobComponent />,
          children: [
            {
              path: "",
              element: <JobsListing />
            },
            {
              path: "job-reports",
              element: <JobReports />
            }
          ]
        },

        {
          path: "candidates",
          element: <CandidatesComponent />
        },
        { path: "profile", element: <Profile /> },
        { path: "create-job", element: <CreateJob /> },
        { path: "candidate-profile/:id", element: <CandidateMainProfile /> },
        { path: "job-detail", element: <JobDetail /> },
        { path: "job-detail/:id", element: <JobDetail /> },
        { path: "edit-job", element: <CreateJob /> },
        { path: "match-master", element: <MatchMasterAI /> },
        { path: "interview", element: <InterViewListing /> },
        {
          path: "interview-calendar",
          element: <InterviewCalendar />
        }
      ]
    }
  ]);
};

export const AssessmentOwnerRoutes = () => {
  return useRoutes([
    { path: "registration", element: <Registration /> },
    { path: "*", sensitive: true, element: <PageNotFound status={404} /> },
    {
      path: "dashboard",
      element: <MainDashboard />,
      children: [
        { path: "profile", element: <Profile /> },
        { path: "candidates", element: <AssessmentCandidates /> },
        { path: "candidates-assessment-report", element: <AssessmentReport /> },

        {
          path: "assessment",
          element: <AssessmentMain />,
          children: [{ path: "", element: <AssessmentCards /> }]
        },
        {
          path: "assessment/create-assessment",
          element: <CreateAssessmentModule />
        },
        {
          path: "assessment/edit-assessment",
          element: <CreateAssessmentModule />
        },

        { path: "assessment/details", element: <AssessmentDetails /> },

        {
          path: "user-management",
          element: <ClientAdminUserManagementMain />
        },
        {
          path: "create-user",
          element: <MasterAdminCreateNewUser />
        },
        {
          path: "update-user",
          element: <MasterAdminCreateNewUser />
        }
      ]
    }
  ]);
};

export const AssessmentRecruiterRoutes = () => {
  return useRoutes([
    { path: "registration", element: <Registration /> },
    { path: "*", sensitive: true, element: <PageNotFound status={404} /> },
    {
      path: "dashboard",
      element: <MainDashboard />,
      children: [
        {
          path: "home",
          element: <DashboardHome />
        },
        { path: "profile", element: <Profile /> },
        { path: "candidates", element: <AssessmentCandidates /> },
        { path: "candidates-assessment-report", element: <AssessmentReport /> },
        {
          path: "assessment",
          element: <AssessmentMain />,
          children: [{ path: "", element: <AssessmentCards /> }]
        },
        {
          path: "assessment/create-assessment",
          element: <CreateAssessmentModule />
        },
        {
          path: "assessment/edit-assessment",
          element: <CreateAssessmentModule />
        },

        { path: "assessment/details", element: <AssessmentDetails /> }
      ]
    }
  ]);
};

export const AssessmentCandidateRoutes = () => {
  return useRoutes([
    { path: "*", sensitive: true, element: <PageNotFound status={404} /> },
    {
      path: "dashboard",
      element: <AssessmentCandidateMain />,
      children: [
        {
          path: "test",
          element: <AssessmentTestDashboard />
        },
        { path: "tips", element: <AssessmentTips /> },
        { path: "setup", element: <CameraSetup /> }
      ]
    }
  ]);
};

export const CandidateDashboardRoutes = () => {
  return useRoutes([
    { path: "*", sensitive: true, element: <PageNotFound status={404} /> },
    {
      path: "dashboard",
      element: <MainDashboard />,
      children: [
        {
          path: "home",
          element: <CandidateHome />
        },
        {
          path: "profile",
          element: <CandidateProfile />
        },
        { path: "jobs", element: <Home /> }
      ]
    },
    {
      path: "apply-job/:id",
      element: <ApplyJob />
    }
  ]);
};

export const MentorpalOwnerRoutes = () => {
  return useRoutes([
    { path: "*", sensitive: true, element: <PageNotFound status={404} /> },
    { path: "registration", element: <Registration /> },
    { path: "carrier-page/:id", element: <CareersPage /> },
    { path: "apply-job", element: <ApplyJob /> },
    { path: "apply-job/:id", element: <ApplyJob /> },
    {
      path: "dashboard",
      element: <MainDashboard />,
      children: [
        {
          path: "home",
          element: <DashboardHome />
        },
        {
          path: "user-management",
          element: <MasterAdminUserManagementMain />
        },
        {
          path: "interview-calendar",
          element: <InterviewCalendar />
        },
        {
          path: "create-user",
          element: <MasterAdminCreateNewUser />
        },
        {
          path: "update-user",
          element: <MasterAdminCreateNewUser />
        },
        { path: "profile", element: <Profile /> },
        { path: "timeline-logs", element: <TimeLineLogs /> },
        {
          path: "enquiry",
          element: <EnquiryDashboard />,
          children: [
            {
              path: "",
              element: <EnquiryListing />
            }
          ]
        },
        {
          path: "feedback",
          element: <FeedbackDashboard />,
          children: [
            {
              path: "",
              element: <FeedbackListing />
            }
          ]
        },
        {
          path: "report",
          element: <ClientReport />
        },
        {
          path: "emails-template",
          element: <EmailTemplates />
        },
        {
          path: "client-dashboard",
          element: <ClientDashboard />,
          children: [
            { path: "", element: <ClientTable /> },
            { path: "details", element: <ClientProfile /> }
          ]
        },
        { path: "create-client", element: <CreateClient /> },
        { path: "update-client", element: <CreateClient /> },

        // ats routes
        {
          path: "jobs",
          element: <JobComponent />,
          children: [
            {
              path: "",
              element: <JobsListing />
            },
            {
              path: "job-reports",
              element: <JobReports />
            }
          ]
        },

        {
          path: "candidates",
          element: <CandidatesComponent />
        },
        { path: "create-job", element: <CreateJob /> },
        { path: "interview", element: <InterViewListing /> },
        { path: "candidate-profile/:id", element: <CandidateMainProfile /> },
        { path: "job-detail", element: <JobDetail /> },
        { path: "job-detail/:id", element: <JobDetail /> },

        { path: "edit-job", element: <CreateJob /> },
        { path: "match-master", element: <MatchMasterAI /> },

        // assessments routes below
        { path: "candidates-assessment-report", element: <AssessmentReport /> },
        {
          path: "assessment",
          element: <AssessmentMain />,
          children: [{ path: "", element: <AssessmentCards /> }]
        },
        {
          path: "assessment/create-assessment",
          element: <CreateAssessmentModule />
        },
        {
          path: "assessment/edit-assessment",
          element: <CreateAssessmentModule />
        },

        { path: "assessment/details", element: <AssessmentDetails /> },

        {
          path: "questions",
          element: <AssessmentQuestions />,
          children: [
            { path: "", element: <CardsListing /> },
            { path: "details", element: <DetailedQuestionPage /> }
          ]
        },
        { path: "question", element: <CreateQuestion /> },
        {
          path: "tests",
          element: <AssessmentTest />,
          children: [
            { path: "", element: <TestTable /> },
            { path: "details", element: <TestDetailsPage /> }
          ]
        },
        { path: "test-create", element: <CreateTestModules /> }
      ]
    }
  ]);
};

export const MentorpalExecutiveRoutes = () => {
  return useRoutes([
    { path: "*", sensitive: true, element: <PageNotFound status={404} /> },
    { path: "registration", element: <Registration /> },
    {
      path: "dashboard",
      element: <MainDashboard />,
      children: [
        { path: "profile", element: <Profile /> },
        {
          path: "enquiry",
          element: <EnquiryDashboard />,
          children: [
            {
              path: "",
              element: <EnquiryListing />
            }
          ]
        },

        {
          path: "questions",
          element: <AssessmentQuestions />,
          children: [
            { path: "", element: <CardsListing /> },
            { path: "details", element: <DetailedQuestionPage /> }
          ]
        },
        { path: "question", element: <CreateQuestion /> },
        {
          path: "tests",
          element: <AssessmentTest />,
          children: [
            { path: "", element: <TestTable /> },
            { path: "details", element: <TestDetailsPage /> }
          ]
        }
      ]
    }
  ]);
};
export const VerificationErrorRoutes = () => {
  return useRoutes([
    { path: "*", sensitive: true, element: <PageNotFound status={404} /> },
    { path: "error", element: <VerificationErrorPage /> }
  ]);
};
