import React, { useState, useEffect } from "react";
import {
  Button,
  Form,
  Input,
  Layout,
  Collapse, Divider,
  Radio,
  Drawer,
  DatePicker
} from "antd";
import { useDispatch, useSelector } from "react-redux";
import { Outlet } from "react-router-dom";
import { getEnquiryAction } from "../../redux/actions/common";
import moment from "moment";
import ("../../Assets/css/filter.css");

const { Sider, Content } = Layout;
const { Panel } = Collapse;
const { RangePicker } = DatePicker;

const FilterForm = ({
  filters,
  handleFilterChange,
  handleClearFilters,
  handleChange
}) => (
  <Form
    layout="vertical"
    onValuesChange={handleFilterChange}
    initialValues={filters}
    style={{ paddingTop: "20px" }}
  >
    <div className="d-flex justify-content-between align-items-center mb-3">
      <span className="d-flex align-items-center">
        <h2 className="m-0 ml-2">Filters</h2>
      </span>

      <Button
        type=""
        className="cursor-pointer"
        onClick={() => {
          handleClearFilters();
        }}
      >
        Reset all
      </Button>
    </div>
    <Collapse
      defaultActiveKey={["1", "2", "3", "4", "5"]}
      expandIconPosition="right"
      style={{ background: "#ffff", border: "none" }}
    >
      <Panel
        header="Search by Company Name"
        key="1"
        style={{ background: "#ffff", border: "none" }}
      >
        <Form.Item>
          <Input
          size="large"
            placeholder="Company Name"
            value={filters.company_name}
            onChange={(e) => handleChange("company_name", e.target.value)}
          />
        </Form.Item>
      </Panel>

      <Panel
        header="Select Enquiry Status"
        key="3"
        style={{ background: "#ffff", border: "none" }}
      >
        <Form.Item name="status">
          <Radio.Group
            value={filters?.status}
            onChange={(e) => handleChange("status", e.target.value)}
          >
            <div className="d-flex justify-content-between mb-2">
              <Radio value="">All</Radio>
            </div>
            <div className="d-flex justify-content-between mb-2">
              <Radio value="pending">Pending</Radio>
            </div>
            <div className="d-flex justify-content-between mb-2">
              <Radio value="completed">Completed</Radio>
            </div>
          </Radio.Group>
        </Form.Item>
      </Panel>
      <Panel header="Date Range" key="4">
        <RangePicker
          size="large"
          value={[
            filters.startDate ? moment(filters.startDate) : null,
            filters.endDate ? moment(filters.endDate) : null,
          ]}
          onChange={(dates) => {
            handleChange(
              "startDate",
              dates ? dates[0].toISOString() : null
            );
            handleChange(
              "endDate",
              dates ? dates[1].toISOString() : null
            );
          }}
          format="YYYY-MM-DD"
          style={{ width: "100%" }}
          disabledDate={(current) => {
            return current && current > moment().endOf("day");
          }}
        />
      </Panel>
    </Collapse>
  </Form>
);

const EnquiryDashboard = () => {
  const dispatch = useDispatch();
  const updateEnquiryReducer = useSelector(
    (state) => state.updateEnquiryReducer
  );

  const [filters, setFilters] = useState({
    company_name: "",
    company_type: "",
    company_location: "",
    status: "",
    startDate: null,
    endDate: null

  });

  const [isMobileFilterDrawerVisible, setIsMobileFilterDrawerVisible] =
    useState(false);

  useEffect(() => {
    fetchClientList();
    return () => {
      dispatch({ type: "GET_ENQUIRY_RESET" });
    };
  }, [filters, dispatch, updateEnquiryReducer]);

  const fetchClientList = () => {
    dispatch(
      getEnquiryAction({
        page: 1,
        limit: 10,
        ...filters
      })
    );
  };

  const handleFilterChange = (changedFields) => {
    const newFilters = { ...filters, ...changedFields };
    setFilters(newFilters);
  };

  const handleClearFilters = () => {
    setFilters({
      company_name: "",
      company_type: "",
      company_location: "",
      status: "",
      startDate: null,
      endDate: null
  
    });
  };

  const handleChange = (key, value) => {
    setFilters((prevFilters) => ({ ...prevFilters, [key]: value }));
  };

  const toggleFilters = () =>
    setIsMobileFilterDrawerVisible(!isMobileFilterDrawerVisible);
  const closeDrawer = () => setIsMobileFilterDrawerVisible(false);
  const isMobile = window.innerWidth <= 768;

  return (
    <>
      <div className="main-header bg-white" data-ui="header">
        <div className=" px-4">
          <div className="flag">
            <div className="flag__section">
              <a className="external incognito-link inline-block mb-m mt-m">
                <h1 className="js-main-header-title main-header__title main-header__title--dashboard">
                  Enquiry
                </h1>
              </a>
            </div>
          </div>
        </div>
      </div>

      <Layout style={{ background: "#ffff", height: "100vh" }}>
        {!isMobile && (
          <Sider width={300} className="site-layout-background bg-light">
            <div className="px-3 bg-light" style={{ height: "100vh" }}>
              <FilterForm
                filters={filters}
                handleFilterChange={handleFilterChange}
                handleChange={handleChange}
                handleClearFilters={handleClearFilters}
              />
            </div>
          </Sider>
        )}
        <Layout style={{ padding: "0 5px 5px", minHeight: "100vh" }}>
          <Button
            className="filter-toggle-button"
            onClick={toggleFilters}
            type="primary"
            style={{ backgroundColor: "#00756a" }}
          >
            Apply Filters
          </Button>

          <Content
            className="site-layout-background"
            style={{
              padding: 0,
              margin: 0
            }}
          >
            <Outlet />
          </Content>
        </Layout>
      </Layout>

      <Drawer
        visible={isMobileFilterDrawerVisible}
        onClose={closeDrawer}
        placement="left"
        width="80%"
        className="filter-drawer"
      >
        <FilterForm
          filters={filters}
          handleFilterChange={handleFilterChange}
          handleChange={handleChange}
          handleClearFilters={handleClearFilters}
        />
      </Drawer>
    </>
  );
};

export default EnquiryDashboard;
