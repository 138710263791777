import {
  Button,
  message,
  Pagination,
  Dropdown,
  Space,
  Menu,
  Modal,
  Table,
  Avatar,
  Layout,
  Badge,
  Tabs
} from "antd";
import { ReloadOutlined, MoreOutlined, UserOutlined } from "@ant-design/icons";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { TailSpin } from "react-loader-spinner";
import { useNavigate } from "react-router-dom";
import {
  getJobAction,
  updateJobDetailAction
} from "../../redux/actions/common";
import { statusColor } from "../../Assets/data/common";

const { confirm } = Modal;
const { Content } = Layout;
const { TabPane } = Tabs;

const JobsListing = ({ client_id }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [totalJobs, setTotalJobs] = useState(0);
  const [data, setData] = useState([]);
  const [activeTab, setActiveTab] = useState("active");

  const {
    loading: jobLoading,
    data: jobData,
    error: jobError,
    request: jobRequest
  } = useSelector((state) => state.getJobReducer);

  const {
    data: updateData,
    error: updateError,
    loading: updateLoading
  } = useSelector((state) => state.updateJobDetailReducer);

  useEffect(() => {
    fetchJobs();
    return () => {
      dispatch({ type: "GET_JOB_RESET" });
    };
  }, [dispatch, updateData, activeTab, currentPage]);

  useEffect(() => {
    if (!jobLoading && jobData && !jobError) {
      setData(jobData.data || []);
      setPageSize(jobData.pageSize || 10);
      setTotalJobs(jobData.total || 0);
      setActiveTab(jobRequest?.status);
    } else if (!jobLoading && jobError) {
      message.error(jobError);
    }
  }, [jobLoading, jobData, jobError]);

  const fetchJobs = () => {
    dispatch(
      getJobAction({
        pageSize,
        page: currentPage,
        status: activeTab,
        ...(client_id && { client_id })
      })
    );
  };

  const showConfirm = (title, content, onOk) => {
    confirm({
      title,
      content,
      onOk: () => {
        onOk();
        return new Promise((resolve) => {
          setTimeout(() => resolve(), 500);
        });
      },
      okText: "Yes",
      cancelText: "No",
      okButtonProps: {
        style: { backgroundColor: "#005c53", borderColor: "#005c53" }
      },
      cancelButtonProps: { style: { color: "#005c53" } }
    });
  };

  const handleMenuClick = (e, record) => {
    const job_id = record.job?.job_id;
    const actions = {
      active: () => {
        showConfirm(
          "Confirm Active",
          "Are you sure you want to activate this job?",
          () => dispatch(updateJobDetailAction({ job_id, status: "active" }))
        );
      },
      inactive: () => {
        showConfirm(
          "Confirm Inactive",
          "Are you sure you want to unpublish this job?",
          () => dispatch(updateJobDetailAction({ job_id, status: "inactive" }))
        );
      },
      onhold: () => {
        showConfirm(
          "Confirm On Hold",
          "Are you sure you want to put this job on hold?",
          () => dispatch(updateJobDetailAction({ job_id, status: "on hold" }))
        );
      },
      "view-job": () => navigate(`/apply-job/${job_id}`),
      "edit-job": () => {
        navigate(`/dashboard/edit-job?job_id=${job_id}`);
      },
      "leave-job": () => {
        showConfirm(
          "Confirm Leave Job",
          "Are you sure you want to leave this job?",
          () => dispatch(updateJobDetailAction({ job_id, status: "delete" }))
        );
      },
      "refer-job": () => message.success("Feature Coming soon"),
      "reports-job": () => message.success("Feature Coming soon")
    };

    if (actions[e.key]) actions[e.key]();
  };

  const actionMenu = (record) => (
    <Menu onClick={(e) => handleMenuClick(e, record)} className="actions-menu">
      <Menu.Item key="active">Activate Job</Menu.Item>
      <Menu.Item key="inactive">Deactivate Job</Menu.Item>
      <Menu.Item key="onhold">Put Job on Hold</Menu.Item>
      <Menu.Item key="edit-job">Edit Job Details</Menu.Item>
      <Menu.Item key="view-job">View Live Job Details</Menu.Item>
    </Menu>
  );
  const isMobile = window.innerWidth <= 768;

  const columns = [
    {
      title: "S.No",
      key: "serial_number",
      render: (_, __, index) => index + 1,
      fixed: !isMobile ? "left" : undefined,
      width: 100,
      ellipsis: true,
    },
    {
      title: "Job Title",
      dataIndex: ["job", "job_title"],
      key: "job_title",
      fixed: !isMobile ? "left" : undefined,
      width: 250,
      render: (text, record) => (
        <a
          className="text-decoration-underline cursor-pointer"
          style={{ cursor: "pointer", color: "#005c53" }}
          onClick={() => {
            navigate(`/dashboard/job-detail/${record.job?.job_id}`);
          }}
        >
          {text}
        </a>
      )
    },
    {
      title: "Client",
      dataIndex: ["client"],
      width: 300,
      key: "client",
      render: (text, record) => (
        <span className="d-flex">
          <div>
            <Avatar
              src={
                record?.client?.company_photo
                  ? `${process.env.REACT_APP_IMAGES_BASE_URL}${record?.client?.company_photo}`
                  : undefined
              }
              icon={!record?.client?.company_photo ? <UserOutlined /> : null}
              alt={text || "User"}
              style={{ marginRight: 8 }}
            />
          </div>
          <div>
            <a>{record?.client?.company_name}</a>
          </div>
        </span>
      )
    },
    {
      title: "Total Candidates",
      dataIndex: "totalCandidates",
      key: "totalCandidates",
      width: 200
    },
    {
      title: "Sourced",
      dataIndex: ["stats", "sourced"],
      key: "sourced",
      width: 150
    },
    {
      title: "Applied",
      dataIndex: ["stats", "applied"],
      key: "applied",
      width: 150
    },
    {
      title: "Phone",
      dataIndex: ["stats", "phone"],
      key: "phone",
      width: 150
    },
    {
      title: "Assessment",
      dataIndex: ["stats", "assessment"],
      key: "assessment",
      width: 150
    },
    {
      title: "Interview",
      dataIndex: ["stats", "interview"],
      key: "interview",
      width: 150
    },
    {
      title: "Interview Reject",
      dataIndex: ["stats", "interviewReject"],
      key: "interview",
      width: 150
    },
    {
      title: "Offer",
      dataIndex: ["stats", "offer"],
      key: "offer",
      width: 150
    },
    {
      title: "Hired",
      dataIndex: ["stats", "hired"],
      key: "hired",
      width: 150
    },
    {
      title: "Disqualified",
      dataIndex: ["stats", "disqualified"],
      key: "disqualified",
      width: 150
    },
    {
      title: "Status",
      dataIndex: ["job", "status"],
      key: "status",
      width: 150,
      render: (status) => {
        return (
          <Badge
            count={
              <span
                style={{
                  backgroundColor: statusColor[status]?.backgroundColor,
                  color: statusColor[status]?.color,
                  borderRadius: "18px",
                  padding: "5px 15px"
                }}
              >
                {status?.toUpperCase()}
              </span>
            }
          />
        );
      }
    },
    {
      title: "Action",
      key: "action",
      fixed: !isMobile ? "right" : undefined,
      width: 100,
      render: (_, record) => (
        <Space size="large">
          <Dropdown overlay={actionMenu(record)}>
            <Button
              onClick={(e) => e.preventDefault()}
              shape="circle"
              icon={<MoreOutlined />}
            />
          </Dropdown>
        </Space>
      )
    }
  ];

  return (
    <>
      {jobLoading ? (
        <div className="loading-spinner">
          <TailSpin ariaLabel="Loading..." color="#005c53" />
        </div>
      ) : (
        <Content className={isMobile ? 'p-2' : 'px-4'}>
          <div className="cl-section-header row justify-content-between align-items-end py-3">
            <div className="col d-flex align-items-center">
              <Tabs
                activeKey={activeTab}
                onChange={(key) => {
                  setActiveTab(key);
                  setCurrentPage(1);
                }}
              >
                <TabPane tab="Active Jobs" key="active" />
                <TabPane tab="Inactive Jobs" key="inactive" />
                <TabPane tab="On Hold Jobs" key="on hold" />
              </Tabs>
            </div>
            <div className="cl-section-header__action-buttons-wrapper col-auto d-flex align-items-center">
              <Button
                className="ms-2"
                onClick={fetchJobs}
                icon={<ReloadOutlined />}
                type="primary"
                style={{ backgroundColor: "#00756a" }}
              >
                Refresh
              </Button>
            </div>
          </div>
          <Table
            columns={columns}
            dataSource={data}
            pagination={false}
            rowKey="job_id"
            scroll={{ x: 1400 }}
            bordered
            footer={() => (
              <div style={{ textAlign: "center" }}>
                <a
                  href="#"
                  onClick={(e) => {
                    e.preventDefault();
                    const baseUrl = "/dashboard/create-job";
                    const url = client_id ? `${baseUrl}?client_id=${client_id}` : baseUrl;
                    navigate(url);
                  }}
                  style={{
                    color: "#00756a",
                    textDecoration: "underline",
                    fontWeight: "bold",
                    cursor: "pointer"
                  }}
                >
                  Create Job
                </a>
              </div>
            )}
          />
          <Pagination
            className="text-center align-content-center mt-3"
            current={currentPage}
            total={totalJobs}
            pageSize={pageSize}
            showSizeChanger
            onChange={(page, size) => {
              setCurrentPage(page);
              setPageSize(size);
            }}
            pageSizeOptions={[5, 10, 20, 50, 100]}
            showTotal={(total) => `Total ${total} Jobs`}
            style={{ margin: "5% 0" }}
          />
        </Content>
      )}
    </>
  );
};

export default JobsListing;
