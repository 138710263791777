import {
  Button,
  message,
  Pagination,
  Menu,
  Modal,
  Table,
  Avatar,
  Layout,
  Tabs
} from "antd";
import { ReloadOutlined, UserOutlined } from "@ant-design/icons";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { TailSpin } from "react-loader-spinner";
import { useNavigate } from "react-router-dom";
import {
  getJobAction,
  updateJobDetailAction
} from "../../../redux/actions/common";
const { confirm } = Modal;
const { Content } = Layout;
const { TabPane } = Tabs;

const JobReports = ({ client_id }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [totalJobs, setTotalJobs] = useState(0);
  const [data, setData] = useState([]);
  const [activeTab, setActiveTab] = useState("active");

  const {
    loading: jobLoading,
    data: jobData,
    error: jobError,
    request: jobRequest
  } = useSelector((state) => state.getJobReducer);

  useEffect(() => {
    fetchJobs();
    return () => {
      dispatch({ type: "GET_JOB_RESET" });
    };
  }, [dispatch, activeTab, currentPage]);

  useEffect(() => {
    if (!jobLoading && jobData && !jobError) {
      setData(jobData.data || []);
      setPageSize(jobData.pageSize || 10);
      setTotalJobs(jobData.total || 0);
      setActiveTab(jobRequest?.status);
    } else if (!jobLoading && jobError) {
      message.error(jobError);
    }
  }, [jobLoading, jobData, jobError]);

  const fetchJobs = () => {
    dispatch(
      getJobAction({
        pageSize,
        page: currentPage,
        status: activeTab,
        ...(client_id && { client_id })
      })
    );
  };

  const isMobile = window.innerWidth <= 768;

  const columns = [
    {
      title: "S.No",
      key: "serial_number",
      render: (_, __, index) => index + 1,
      fixed: !isMobile ? "left" : undefined,
      width: 100,
      ellipsis: true
    },
    {
      title: "Job Title",
      dataIndex: ["job", "job_title"],
      key: "job_title",
      fixed: !isMobile ? "left" : undefined,
      width: 250,
      render: (text, record) => (
        <a
          className="text-decoration-underline cursor-pointer"
          style={{ cursor: "pointer", color: "#005c53" }}
          onClick={() => {
            navigate(`/dashboard/job-detail/${record.job?.job_id}`);
          }}
        >
          {text}
        </a>
      )
    },
    {
      title: "Client",
      dataIndex: ["client"],
      width: 300,
      key: "client",
      render: (text, record) => (
        <span className="d-flex">
          <div>
            <Avatar
              src={
                record?.client?.company_photo
                  ? `${process.env.REACT_APP_IMAGES_BASE_URL}${record?.client?.company_photo}`
                  : undefined
              }
              icon={!record?.client?.company_photo ? <UserOutlined /> : null}
              alt={text || "User"}
              style={{ marginRight: 8 }}
            />
          </div>
          <div>
            <a>{record?.client?.company_name}</a>
          </div>
        </span>
      )
    },
    {
      title: "Total Candidates",
      dataIndex: "totalCandidates",
      key: "totalCandidates",
      width: 200
    },
    {
      title: "Total Interviews",
      dataIndex: "totalInterviews",
      key: "totalInterviews",
      width: 150
    },
    {
      title: "Completed Interviews",
      dataIndex: "completedInterviews",
      key: "completedInterviews",
      width: 150
    },
    {
      title: "Pending Interviews",
      dataIndex: "pendingInterviews",
      key: "pendingInterviews",
      width: 150
    },
    {
      title: "Today's Interviews",
      dataIndex: "todaysInterviews",
      key: "todaysInterviews",
      width: 150
    },
    {
      title: "This Week's Interviews",
      dataIndex: "thisWeeksInterviews",
      key: "thisWeeksInterviews",
      width: 150
    }
  ];

  return (
    <>
      {jobLoading ? (
        <div className="loading-spinner">
          <TailSpin ariaLabel="Loading..." color="#005c53" />
        </div>
      ) : (
        <Content className={isMobile ? "p-2" : "px-4"}>
          <div className="cl-section-header row justify-content-between align-items-end py-3">
            <div className="col d-flex align-items-center">
              <Tabs
                activeKey={activeTab}
                onChange={(key) => {
                  setActiveTab(key);
                  setCurrentPage(1);
                }}
              >
                <TabPane tab="Active Jobs" key="active" />
                <TabPane tab="Inactive Jobs" key="inactive" />
                <TabPane tab="On Hold Jobs" key="on hold" />
              </Tabs>
            </div>
            <div className="cl-section-header__action-buttons-wrapper col-auto d-flex align-items-center">
              <Button
                className="ms-2"
                onClick={fetchJobs}
                icon={<ReloadOutlined />}
                type="primary"
                style={{ backgroundColor: "#00756a" }}
              >
                Refresh
              </Button>
            </div>
          </div>
          <Table
            columns={columns}
            dataSource={data}
            pagination={false}
            rowKey="job_id"
            scroll={{ x: 1400 }}
            bordered
            footer={() => (
              <div style={{ textAlign: "center" }}>
                <a
                  href="#"
                  onClick={(e) => {
                    e.preventDefault();
                    const baseUrl = "/dashboard/create-job";
                    const url = client_id
                      ? `${baseUrl}?client_id=${client_id}`
                      : baseUrl;
                    navigate(url);
                  }}
                  style={{
                    color: "#00756a",
                    textDecoration: "underline",
                    fontWeight: "bold",
                    cursor: "pointer"
                  }}
                >
                  Create Job
                </a>
              </div>
            )}
          />
          <Pagination
            className="text-center align-content-center mt-3"
            current={currentPage}
            total={totalJobs}
            pageSize={pageSize}
            showSizeChanger
            onChange={(page, size) => {
              setCurrentPage(page);
              setPageSize(size);
            }}
            pageSizeOptions={[5, 10, 20, 50, 100]}
            showTotal={(total) => `Total ${total} Jobs`}
            style={{ margin: "5% 0" }}
          />
        </Content>
      )}
    </>
  );
};

export default JobReports;
