import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { TailSpin } from "react-loader-spinner";
import {
  Button,
  Table,
  message,
  Layout,
  Form,
  Collapse,
  Pagination,
  Input,
  Drawer,
  Badge,
  Dropdown,
  Space,
  Menu,
} from "antd";
import { ReloadOutlined } from "@ant-design/icons";
import {
  getEmailTemplatesAction,
  updateEmailTemplatesAction,
} from "../../redux/actions/common";
import { MoreOutlined } from "@ant-design/icons";
import { useIsMobileView } from "../../Utils/CustomHooks/windowSize";
import("../../Assets/css/filter.css");

const { Sider, Content } = Layout;
const { Panel } = Collapse;

const FilterForm = ({ filters, handleFilterChange, handleReset, form }) => (
  <Form
    form={form}
    layout="vertical"
    onValuesChange={(changedValues) => {
      Object.keys(changedValues).forEach((key) =>
        handleFilterChange(key, changedValues[key])
      );
    }}
    initialValues={filters}
  >
    <div className="d-flex justify-content-between align-items-center mb-3">
      <span className="d-flex align-items-center">
        <h2 className="m-0 ml-2 mt-3">Filters</h2>
      </span>
      <Button type="" className="cursor-pointer mt-3" onClick={handleReset}>
        Reset all
      </Button>
    </div>
    <Collapse
      defaultActiveKey={["1", "2", "3", "4", "5"]}
      expandIconPosition="right"
      style={{ background: "#fff", border: "none" }}
    >
      <Panel header="Search by title" key="1">
        <Form.Item name="summary">
          <Input
            placeholder="Search by title"
            value={filters.summary}
            onChange={(e) => handleFilterChange("summary", e.target.value)}
          />
        </Form.Item>
      </Panel>
    </Collapse>
  </Form>
);

const EmailTemplates = () => {
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const [openDrawer, setOpenDrawer] = useState({
    show: false,
    data: {},
  });

  const [loading, setloading] = useState(false);
  const [data, setData] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [totalUsers, setTotalUsers] = useState(0);
  const [filters, setFilters] = useState({
    summary: "",
  });

  const [isMobileFilterDrawerVisible, setIsMobileFilterDrawerVisible] =
    useState(false);
  const getEmailTemplatesReducer = useSelector(
    (state) => state.getEmailTemplatesReducer
  );
  const updateEmailTemplatesReducer = useSelector(
    (state) => state.updateEmailTemplatesReducer
  );

  const handleFilterChange = (key, value) => {
    setFilters((prevFilters) => ({ ...prevFilters, [key]: value }));
    setCurrentPage(1);
  };

  useEffect(() => {
    dispatch(
      getEmailTemplatesAction({
        ...filters,
        page: currentPage,
        limit: pageSize,
      })
    );
    return () => {
      dispatch({ type: "GET_EMAIL_TEMPLATES_RESET" });
    };
  }, [dispatch, pageSize, currentPage, filters, updateEmailTemplatesReducer]);

  useEffect(() => {
    const { loading, status, error, data } = getEmailTemplatesReducer;
    setloading(loading);
    if (!loading && data && !error) {
      const formattedData = data.data?.map((item, index) => {
        return {
          ...item,
        };
      });
      setData(formattedData);
      setTotalUsers(data?.totalTemplates);
    }
    if (!loading && !data && error) {
      setData([]);

      message.error(error);
    }
  }, [getEmailTemplatesReducer]);
  useEffect(() => {
    const { loading, status, error, data } = updateEmailTemplatesReducer;
    setloading(loading);
    if (!loading && data && !error) {
      message.success("Update Successfully!");
    }
    if (!loading && !data && error) {
      message.error(error);
    }
  }, [updateEmailTemplatesReducer]);

  const actionMenu = (record) => (
    <Menu
      onClick={(e) => {
        console.log(e, record);
        dispatch(
          updateEmailTemplatesAction({
            status: e?.key,
            templateId: record?.templateId,
          })
        );
      }}
      className="actions-menu"
    >
      <Menu.Item key="inactive">Deactivate Email</Menu.Item>
      <Menu.Item key="active">Activate Email</Menu.Item>
    </Menu>
  );

  const isMobile = window.innerWidth <= 768;

  const columns = [
    {
      title: "S.No",
      dataIndex: "serial_no",
      key: "serial_no",
      fixed: !isMobile ? "left" : undefined,
      width: 80,
      ellipsis: true,
      sorter: (a, b) => a.serial_no - b.serial_no,
      sortDirections: ["ascend", "descend"],
    },

    {
      title: "subjectLine",
      dataIndex: "subjectLine",
      key: "subjectLine",
      width: 250,
      fixed: !isMobile ? "left" : undefined,
      sorter: (a, b) => a.subjectLine.localeCompare(b.subjectLine),
      sortDirections: ["ascend", "descend"],
      render: (text, record) => (
        <span
          onClick={() => {
            setOpenDrawer({
              show: true,
              data: record,
            });
          }}
        >
          <a
            className="text-decoration-underline cursor-pointer"
            style={{ cursor: "pointer", color: "#005c53" }}
          >{`${record?.subjectLine}`}</a>
        </span>
      ),
    },
    {
      title: "Usage",
      dataIndex: "usage",
      key: "usage",
      width: 250,
      render: (text, record) => (
        <span>
          {text?.map((item, index) => (
            <Badge
              key={index}
              color={index % 2 === 0 ? "blue" : "green"}
              text={item}
              style={{ marginRight: 8 }}
            />
          ))}
        </span>
      ),
    },
    {
      title: "Title",
      dataIndex: "templateId",
      key: "templateId",
      fixed: !isMobile ? "left" : undefined,
      width: 250,
      sorter: (a, b) => a.templateId.localeCompare(b.templateId),
      sortDirections: ["ascend", "descend"],
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      width: 100,
      render: (status) => {
        return (
          <>
            {status === "active" ? (
              <Badge status="success" text="Active" />
            ) : (
              <Badge status="error" text="InActive" />
            )}
          </>
        );
      },
    },

    {
      title: "Action",
      key: "action",
      fixed: !isMobile ? "right" : undefined,
      width: 80,
      render: (_, record) => (
        <Space size="large">
          <Dropdown overlay={actionMenu(record)}>
            <Button
              onClick={(e) => e.preventDefault()}
              shape="circle"
              icon={<MoreOutlined />}
            />
          </Dropdown>
        </Space>
      ),
    },
  ];

  const handleRefresh = () => {
    dispatch(
      getEmailTemplatesAction({
        ...filters,
        page: currentPage,
        limit: pageSize,
      })
    );
  };

  const handleReset = () => {
    form.setFieldsValue({
      summary: "",
    });

    setFilters({
      summary: "",
    });
  };

  const toggleFilters = () =>
    setIsMobileFilterDrawerVisible(!isMobileFilterDrawerVisible);
  const closeDrawer = () => setIsMobileFilterDrawerVisible(false);

  return (
    <>
      <div  data-ui="header">
        <div className="px-4">
          <div className="flag">
            <div className="flag__section">
              <a className="external incognito-link inline-block mb-m mt-m">
                <h1 className="js-main-header-title main-header__title ">
                  Email Templates
                </h1>
              </a>
            </div>
          </div>
        </div>
      </div>

      <Layout style={{ background: "#fff", height: "100vh" }}>
        {!isMobile && (
          <Sider width={300} className="site-layout-background bg-light">
            <div className="px-3 bg-light" style={{ height: "100vh" }}>
              <FilterForm
                filters={filters}
                handleFilterChange={handleFilterChange}
                handleReset={handleReset}
                form={form}
              />
            </div>
          </Sider>
        )}
        <Layout>
          <Button
            className="filter-toggle-button"
            onClick={toggleFilters}
            type="primary"
            style={{ backgroundColor: "#00756a" }}
          >
            Apply Filters
          </Button>
          {loading ? (
            <div
              style={{
                position: "fixed",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                zIndex: 9999,
              }}
            >
              <TailSpin ariaLabel="Loading..." color="#005c53" />
            </div>
          ) : (
            <Content className="px-4">
              <div className="cl-section-header row justify-content-between align-items-end py-3">
                <div className="col d-flex align-items-center"></div>
                <div className="cl-section-header__action-buttons-wrapper col-auto d-flex align-items-center">
                  <Button
                    className="ms-2"
                    onClick={handleRefresh}
                    icon={<ReloadOutlined />}
                    type="primary"
                    style={{ backgroundColor: "#00756a" }}
                  >
                    Refresh
                  </Button>
                </div>
              </div>
              <Table
                bordered
                columns={columns}
                dataSource={data}
                pagination={false}
                rowKey="user_id"
                scroll={{
                  x: 1300,
                }}
              />
              <Pagination
                className="text-center align-content-center mt-3"
                total={totalUsers}
                current={currentPage}
                pageSize={pageSize}
                showSizeChanger
                pageSizeOptions={[5, 10, 20, 50, 100]}
                showTotal={(total) => `Total ${total} template`}
                onChange={(page, pageSize) => {
                  setCurrentPage(page);
                  setPageSize(pageSize);
                }}
              />
            </Content>
          )}
        </Layout>
      </Layout>
      <Drawer
        visible={isMobileFilterDrawerVisible}
        onClose={closeDrawer}
        placement="left"
        width="80%"
        className="filter-drawer"
        bodyStyle={{ paddingTop: 0 }} 
      >
        <FilterForm
          filters={filters}
          handleFilterChange={handleFilterChange}
          handleReset={handleReset}
          form={form}
        />
      </Drawer>

      {openDrawer?.show && openDrawer?.data && (
        <>
          <TimeLineDetails visible={openDrawer} setVisible={setOpenDrawer} />
        </>
      )}
    </>
  );
};

export default EmailTemplates;

const TimeLineDetails = ({ visible, setVisible }) => {
  const onClose = useCallback(() => {
    setVisible((prev) => ({ ...prev, show: false, data: {} }));
  }, [setVisible]);

  return (
    <Drawer
      placement="right"
      width={useIsMobileView() ? "100%" : "50%"}
      open={visible?.show}
      onClose={onClose}
      title={
        <div className="flex justify-between items-center">
          <div className="flex items-center">
            <span className="text-lg font-semibold text-[#00756a]">
              Email Template Details
            </span>
          </div>
        </div>
      }
    >
      <>
        <div className="mb-4 p-4 border rounded-lg shadow-sm bg-white hover:shadow-md transition-shadow duration-200">
          <iframe
            className="w-full rounded-lg"
            style={{ height: "100vh" }}
            srcDoc={visible?.data?.htmlContent}
            title="Email Preview"
          />
        </div>
      </>
    </Drawer>
  );
};
