import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { TailSpin } from "react-loader-spinner";
import { useNavigate } from "react-router-dom";
import {
  Button,
  Checkbox,
  Drawer,
  Layout,
  Pagination,
  Table,
  Input,
  Select,
  Form,
  message,
  Space,
} from "antd";
import {
  createTestAction,
  getAllQuestionListAction,
} from "../../../redux/actions/assessment";
import { DOCUMENT_STATUS, QUESTION_TYPE, TagsAssessment } from "../data";
import { SearchOutlined, ReloadOutlined } from "@ant-design/icons";
import { QuillTextEditor } from "../../../Utils/TextEditor";
import { useIsMobileView } from "../../../Utils/CustomHooks/windowSize";
const { Option } = Select;
const { Content } = Layout;

const CardsListing = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const getAllQuestionListReducer = useSelector(
    (state) => state.getAllQuestionListReducer
  );

  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [totalQuestions, setTotalQuestions] = useState(0);
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selectedQuestions, setSelectedQuestions] = useState([]);
  const [showActions, setShowActions] = useState(false);
  const [selectAllChecked, setSelectAllChecked] = useState(false);
  const [drawerVisible, setDrawerVisible] = useState(false);
  const [searchText, setSearchText] = useState("");

  const handleRowClick = (question_id) => {
    navigate(`details?question_id=${question_id}`);
  };

  useEffect(() => {
    fetchQuestionList();
    return () => {
      dispatch({ type: "GET_LIST_QUESTION_RESET" });
    };
  }, [currentPage, pageSize]);

  useEffect(() => {
    const { loading, data, error } = getAllQuestionListReducer;
    setLoading(loading);
    if (!loading && data && !error) {
      setData(data?.data);
      setTotalQuestions(data?.totalQuestions);
    }
    if (!loading && !data && error) {
      console.warn("Error:", error);
      message.error(error);
    }
  }, [getAllQuestionListReducer]);

  const fetchQuestionList = () => {
    dispatch(
      getAllQuestionListAction({
        page: currentPage,
        limit: pageSize,
      })
    );
  };

  const handlePageChange = (page, pageSize) => {
    setCurrentPage(page);
    setPageSize(pageSize);
  };

  const handleCheckboxChange = (selectedRowKeys) => {
    setSelectedQuestions(selectedRowKeys);
    setShowActions(selectedRowKeys.length > 0);
    setSelectAllChecked(selectedRowKeys.length === data.length);
  };

  const handleSelectAllChange = (e) => {
    const { checked } = e.target;
    setSelectAllChecked(checked);
    const allQuestionIds = data.map((q) => q.question_id);
    setSelectedQuestions(checked ? allQuestionIds : []);
    setShowActions(checked);
  };

  const handleAddToTest = () => {
    setDrawerVisible(true);
  };

  const columns = [
    {
      title: (
        <Checkbox checked={selectAllChecked} onChange={handleSelectAllChange} />
      ),
      dataIndex: "select",
      key: "select",
      width:100,
      render: (_, record) => (
        <Checkbox
          checked={selectedQuestions.includes(record.question_id)}
          onChange={(e) => {
            const checked = e.target.checked;
            const keys = checked
              ? [...selectedQuestions, record.question_id]
              : selectedQuestions.filter((key) => key !== record.question_id);
            handleCheckboxChange(keys);
          }}
        />
      ),
    },
    {
      title: "Question Name",
      dataIndex: "questionName",
      key: "questionName",
      width: 300,
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => (
        <div style={{ padding: 8 }}>
          <Input
            placeholder="Search Question"
            value={selectedKeys[0]}
            onChange={(e) =>
              setSelectedKeys(e.target.value ? [e.target.value] : [])
            }
            onPressEnter={() => confirm()}
            style={{ marginBottom: 8, display: "block" }}
          />
          <Space>
            <Button
              type="primary"
              onClick={() => confirm()}
              icon={<SearchOutlined />}
              size="small"
              style={{ backgroundColor: "#00756a", color: "white" }}
            >
              Search
            </Button>
            <Button
              onClick={() => {
                clearFilters && clearFilters();
                confirm();
              }}
              size="small"
              style={{ backgroundColor: "#00756a", color: "white" }}
            >
              Reset
            </Button>
          </Space>
        </div>
      ),
      onFilter: (value, record) =>
        record.questionName.toLowerCase().includes(value.toLowerCase()),
      render: (text, record) => (
        <div
          className="text-decoration-underline"
          style={{ cursor: "pointer", color: "#005c53" }}
          onClick={() => handleRowClick(record.question_id)}
        >
          {text}
        </div>
      ),
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      width: 250,
      filters: DOCUMENT_STATUS.map((option) => ({
        text: option.label,
        value: option.value,
      })),
      onFilter: (value, record) => record.status === value,
      render: (status) => {
        const statusOption = DOCUMENT_STATUS.find(
          (option) => option.value === status
        );
        return statusOption ? statusOption.label : status;
      },
    },
    {
      title: "Type",
      dataIndex: "questionType",
      key: "questionType",
      width: 300,
      filters: QUESTION_TYPE.map((option) => ({
        text: option.label,
        value: option.value,
      })),
      onFilter: (value, record) => record.questionType === value,
      render: (type) => {
        const questionType = QUESTION_TYPE.find(
          (option) => option.value === type
        );
        return questionType ? questionType.label : type;
      },
    },
    {
      title: "Time",
      dataIndex: "recommendedTime",
      key: "recommendedTime",
      align: "center",
      width: 250,
      sorter: (a, b) => a.recommendedTime - b.recommendedTime,
      render: (time) => `${time} mins`,
    },
  ];

  const handleRefresh = () => {
    dispatch(
      getAllQuestionListAction({
        page: currentPage,
        limit: pageSize,
      })
    );
    setSearchText(""); // Clear search text on refresh
  };
  useEffect(() => {
    if (searchText) {
      const searchData = data.filter((item) =>
        item.questionName.toLowerCase().includes(searchText.toLowerCase())
      );
      setData(searchData);
    } else {
      setData(data);
    }
  }, [searchText, data]);

  const handleSearch = (value) => {
    setSearchText(value);
  };
  return (
    <>
      {showActions && (
        <div
          style={{ marginBottom: "1px", marginLeft: "20px", marginTop: "10px" }}
        >
          {/* <Button
            onClick={handleDeleteAll}
            disabled={selectedQuestions.length === 0}
          >
            Delete All
          </Button>{" "} */}
          <Button
            onClick={handleAddToTest}
            disabled={selectedQuestions.length === 0}
          >
            Add to Test
          </Button>
        </div>
      )}
      <Content
        className="card-container px-3"
        style={{ padding: "0px", minHeight: 280 }}
      >
        <div className="data-preview">
          {loading ? (
            <div className="loading-spinner">
              <TailSpin ariaLabel="Loading..." color="#005c53" />
            </div>
          ) : (
            <>
              <div className="cl-section-header row justify-content-between align-items-end py-3">
                <div className="col d-flex align-items-center">
                  <form className="candidate-search-input-form-theme-3">
                    <div className="form-group">
                      <label htmlFor="cl-search-input" className="d-none">
                        Search Questions
                      </label>
                      <div className="input-group">
                        <Input
                          placeholder="Search"
                          prefix={<SearchOutlined />}
                          value={searchText}
                          onChange={(e) => handleSearch(e.target.value)}
                          style={{ width: 200 }}
                        />
                      </div>
                    </div>
                  </form>
                </div>
                <div className="cl-section-header__action-buttons-wrapper col-auto d-flex align-items-center ">
                  <Button
                    className="ms-2"
                    onClick={handleRefresh}
                    icon={<ReloadOutlined />}
                    type="primary"
                    style={{ backgroundColor: "#00756a" }}
                  >
                    Refresh
                  </Button>
                </div>
              </div>

              <div
                style={{
                  overflowX: "auto", 
                  WebkitOverflowScrolling: "touch", 
                }}
              >
                <Table
                  bordered
                  columns={columns}
                  dataSource={data}
                  pagination={false}
                  rowKey="question_id"
                  className="custom-table"
                  style={{ minWidth: "600px" }} 
                />
              </div>

              <Pagination
                className="text-center align-content-center mt-3 pb-4"
                current={currentPage}
                total={totalQuestions}
                pageSize={pageSize}
                onChange={handlePageChange}
                showSizeChanger
                pageSizeOptions={[5, 10, 20, 50, 100]}
                showTotal={(total) => `Total ${total} Questions`}
              />
            </>
          )}
        </div>
      </Content>
      {drawerVisible && (
        <TestShortcutDrawer
          setDrawerVisible={setDrawerVisible}
          drawerVisible={drawerVisible}
          selectedQuestions={selectedQuestions}
          setSelectedQuestions={setSelectedQuestions}
        />
      )}
    </>
  );
};

const TestShortcutDrawer = ({
  setDrawerVisible,
  drawerVisible,
  selectedQuestions,
  setSelectedQuestions,
}) => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);

  const [formValues, setFormValues] = useState({
    title: "",
    description: "",
    field: "",
    tags: [],
    skills: [],
    type: "",
    timeLimit: "",
    level: "",
  });

  const [form] = Form.useForm();

  const closeDrawer = () => {
    setDrawerVisible(false);
  };

  const handleSubmit = async (values) => {
    try {
      await dispatch(
        createTestAction({ ...values, questionIds: selectedQuestions })
      );
    } catch (error) {
      message.error("An error occurred while creating the test.");
      console.error(error);
    }
  };
  const createTestReducer = useSelector((state) => state.createTestReducer);
  useEffect(() => {
    const { loading, status, error, data } = createTestReducer;
    setLoading(loading);
    if (!loading && !error && status) {
      setDrawerVisible(false);
      setFormValues({
        title: "",
        description: "",
        field: "",
        tags: [],
        skills: [],
        type: "",
        timeLimit: "",
        level: "",
      });
      setSelectedQuestions([]);
      message.success("Test created successfully!");
    }
    if (!loading && error && status !== 201) {
      console.warn("error", error);
      message.error(error);
    }
  }, [createTestReducer]);
  return (
    <>
      <Drawer
        title="Create Test"
        placement="right"
        onClose={closeDrawer}
        visible={drawerVisible}
        className="drawer-title"
        width={useIsMobileView() ? "100%" : "60%"}
      >
        <Form
          form={form}
          onFinish={handleSubmit}
          initialValues={formValues}
          onValuesChange={(changedValues, allValues) =>
            setFormValues(allValues)
          }
          layout="vertical"
          className="drawer-form"
        >
          <Form.Item
            name="title"
            label="Title"
            rules={[{ required: true, message: "Title is required" }]}
          >
            <Input placeholder="Enter title" />
          </Form.Item>

          <Form.Item
            name="description"
            label="Description"
            rules={[
              {
                required: true,
                message: "Description is required",
              },
              {
                validator: (_, value) => {
                  if (value && value.trim() !== "") {
                    return Promise.resolve();
                  }
                  return Promise.reject(new Error("Description is required"));
                },
              },
            ]}
          >
            <QuillTextEditor
              form={form}
              field={{ name: "description" }}
              style={{ maxHeight: "200px" }}
            />
          </Form.Item>

          <Form.Item
            name="tags"
            label="Tags"
            initialValue={formValues.tags}
            rules={[{ required: true, message: "tags is required" }]}
          >
            <Select mode="tags" placeholder="Enter tags">
              {TagsAssessment?.map((item, key) => (
                <Option value={item} key={key}>
                  {item}
                </Option>
              ))}
            </Select>
          </Form.Item>

          <Form.Item
            name="type"
            label="Type"
            rules={[{ required: true, message: "Type is required" }]}
          >
            <Select placeholder="Select type">
              <Option value="code">Coding</Option>
              <Option value="mcq">Multiple Choice</Option>
              <Option value="questionnaire">Questionnaire</Option>
            </Select>
          </Form.Item>

          <Form.Item
            name="level"
            label="Level"
            rules={[{ required: true, message: "Level is required" }]}
          >
            <Select placeholder="Select level">
              <Option value="low">Easy</Option>
              <Option value="medium">Medium</Option>
              <Option value="high">High</Option>
            </Select>
          </Form.Item>

          <Button
            type="primary"
            htmlType="submit"
            className="btn btn--primary mt-m text-white main_border_radius"
            style={{ marginTop: "20px" }}
            loading={loading}
          >
            Submit
          </Button>
        </Form>
      </Drawer>
    </>
  );
};

export default CardsListing;
