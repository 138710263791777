import React, { useEffect, useState, useCallback } from "react";
import {
  Drawer,
  Avatar,
  message,
  Tooltip,
  Button,
  Divider,
  Collapse,
  Spin,
  Form,
  Input,
  Badge,
  Select,
  Typography,
  Row,
  Col
} from "antd";
import {
  UserOutlined,
  CalendarOutlined,
  EnvironmentOutlined,
  ClockCircleOutlined,
  FileTextOutlined,
  PhoneOutlined,
  TeamOutlined,
  PushpinOutlined,
  CloseOutlined,
  IdcardOutlined
} from "@ant-design/icons";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import {
  getInterviewDetailsAction,
  interviewFeedbackAction,
  updateInterviewDetailsAction
} from "../../redux/actions/interviewer";
import { statusColor } from "../../Assets/data/common";
import { useIsMobileView } from "../../Utils/CustomHooks/windowSize";
import { useNavigate } from "react-router-dom";

const { Panel } = Collapse;
const { Option } = Select;
const { Title, Text } = Typography;

const AttendeeList = ({ attendees, role, navigate }) =>
  attendees.map((attendee, idx) => (
    <div key={idx} className="d-flex align-items-center mb-3">
      <Tooltip title={`Email: ${attendee.email}`}>
        <Avatar
          src={
            attendee.photo
              ? `${process.env.REACT_APP_IMAGES_BASE_URL}${attendee.photo}`
              : undefined
          }
          icon={!attendee.photo ? <UserOutlined /> : null}
          alt={attendee.first_name || "User"}
          style={{ marginRight: 8 }}
        />
      </Tooltip>
      <span>{`${attendee.first_name} ${attendee.last_name}`}</span>
      <span className="ms-2 text-muted">({role})</span>

      {role === "Candidate" && (
        <Tooltip title="To view candidate profile, click here">
          <Badge
            count="View Profile"
            style={{
              backgroundColor: "#1890ff",
              color: "white",
              marginLeft: "10px",
              cursor: "pointer"
            }}
            onClick={() => {
              if (attendee?.candidate_id) {
                console.log(attendee?.candidate_id);
                navigate(
                  `/dashboard/candidate-profile/${attendee?.candidate_id}`
                );
              }
            }}
          />
        </Tooltip>
      )}
    </div>
  ));

const InterviewDetailsDrawer = ({ openDrawer, setOpenDrawer }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);
  const [accordionOpen, setAccordionOpen] = useState(["2", "3"]);

  useEffect(() => {
    if (openDrawer?.active && openDrawer?.show) {
      if (openDrawer?.active === "tracking") {
        setAccordionOpen(["2"]);
      } else {
        setAccordionOpen(["3"]);
      }
    }
  }, [openDrawer]);

  const [data, setData] = useState(null);
  const [form] = Form.useForm();
  const [joinEnabled, setJoinEnabled] = useState(false);
  const [joinHide, setJoinHide] = useState(false);

  const { interview_id } = openDrawer || {};
  const interviewDetails = useSelector(
    (state) => state.getInterviewDetailsReducer
  );
  const feedbackStatus = useSelector((state) => state.interviewFeedbackReducer);
  const updateStatus = useSelector(
    (state) => state.updateInterviewDetailsReducer
  );

  useEffect(() => {
    if (interview_id) {
      dispatch(getInterviewDetailsAction({ interview_id }));
    }
    return () => {
      dispatch({ type: "GET_INTERVIEW_DETAILS_RESET" });
    };
  }, [dispatch, interview_id]);

  useEffect(() => {
    const { loading, data, error } = updateStatus;
    setLoading(loading);
    if (!loading && data) {
      if (error) {
        message.error("Failed to update interview tracking. Please try again.");
      } else if (data) {
        setOpenDrawer({ show: false, interview_id: "" });
        message.success("Tracking updated successfully!");
      }
    }
  }, [updateStatus]);

  useEffect(() => {
    const { loading, data, error } = interviewDetails;
    setLoading(loading);
    if (!loading) {
      if (data) {
        setData(data);
        form.setFieldsValue({
          feedback: {
            recruiter: {
              feedback: data?.interview?.feedback?.recruiter?.feedback || ""
            },
            candidate: {
              feedback: data?.interview?.feedback?.candidate?.feedback || ""
            }
          },
          tracking: {
            initialConfirmationStatus:
              data.interview.tracking.initialConfirmationStatus,
            finalConfirmationStatus:
              data.interview.tracking.finalConfirmationStatus
          },
          status: data?.interview?.status
        });
      } else if (error) {
        message.error("Failed to fetch interview details.");
      }
    }
  }, [interviewDetails]);

  useEffect(() => {
    const { loading, data, error } = feedbackStatus;
    if (!loading) {
      if (data && !error) {
        setOpenDrawer({ show: false, interview_id: "" });
        message.success(data?.message);
      } else if (error) {
        message.error("Failed to submit feedback.");
      }
    }
  }, [feedbackStatus]);

  useEffect(() => {
    if (data) {
      const currentTime = moment();
      const interviewStartTime = moment
        .utc(data?.interview?.startDateTime)
        .local();
      const interviewEndTime = moment.utc(data?.interview?.endDateTime).local();

      const diffBefore = interviewStartTime.diff(currentTime, "minutes");
      const diffAfter = currentTime.diff(interviewEndTime, "minutes");

      setJoinEnabled(
        (diffBefore <= 30 && diffBefore >= 0) ||
          (diffAfter >= 0 && diffAfter <= 30) ||
          currentTime.isBetween(
            interviewStartTime,
            interviewEndTime,
            null,
            "[]"
          )
      );

      setJoinHide(diffAfter > 30);
    }
  }, [data]);

  const handleSubmitFeedback = (values) => {
    dispatch(interviewFeedbackAction({ interview_id, ...values }));
  };

  const handleDone = useCallback(() => {
    form
      .validateFields()
      .then((values) => {
        dispatch(updateInterviewDetailsAction({ interview_id, ...values }));
      })
      .catch((error) => {
        console.error("Form validation failed:", error);
      });
  }, [form, dispatch, interview_id]);

  const isStatusRestricted =
    data?.interview?.status === "no show" ||
    data?.interview?.status === "cancelled";

  return (
    <Drawer
      title={
        <div className="d-flex justify-content-between align-items-center">
          <span>
            <CalendarOutlined /> Interview Details
          </span>
          <Button
            type="danger"
            danger
            style={{
              backgroundColor: "#ffebee",
              color: "#c62828"
            }}
            className="ms-auto"
            icon={<CloseOutlined />}
            onClick={() => {
              if (!isStatusRestricted) {
                dispatch(
                  updateInterviewDetailsAction({
                    interview_id,
                    status: "no show"
                  })
                );
              }
            }}
            disabled={isStatusRestricted}
          >
            No Show
          </Button>
        </div>
      }
      placement="right"
      closable
      onClose={() => {
        dispatch({ type: "GET_INTERVIEW_DETAILS_RESET" });
        setOpenDrawer({ show: false, interview_id: "" });
      }}
      visible={openDrawer?.show}
      width={useIsMobileView() ? "100%" : "40%"}
      bodyStyle={{ padding: "20px", background: "#f9f9f9" }}
    >
      {loading ? (
        <div className="d-flex justify-content-center align-items-center h-100">
          <Spin size="large" />
        </div>
      ) : (
        <>
          <div className="d-flex flex-column mb-3">
            <Row gutter={[16, 16]} className="py-2">
              <Col span={12}>
                <Text strong>
                  <CalendarOutlined /> Round:
                </Text>{" "}
                <Text>{data?.interview?.interviewRound}</Text>
              </Col>
              <Col span={12}>
                <Text strong>
                  <PhoneOutlined /> Candidate Phone:
                </Text>{" "}
                <Text>{data?.candidate?.phone}</Text>
              </Col>
              <Col span={12}>
                <Text strong>
                  <PushpinOutlined /> Summary:
                </Text>{" "}
                <Text>{data?.interview?.summary}</Text>
              </Col>
              <Col span={12}>
                <Text strong>
                  <IdcardOutlined /> Status:
                </Text>{" "}
                <Badge
                  count={
                    <span
                      style={{
                        backgroundColor:
                          statusColor[data?.interview?.status]?.backgroundColor,
                        color: statusColor[data?.interview?.status]?.color,
                        borderRadius: "18px",
                        padding: "5px 15px"
                      }}
                    >
                      {data?.interview?.status?.toUpperCase()}
                    </span>
                  }
                />
              </Col>
              <Col span={12}>
                <Text strong>
                  <EnvironmentOutlined /> Location:
                </Text>{" "}
                <Text>{data?.interview?.location}</Text>
              </Col>
              <Col span={12}>
                <Text strong>
                  <ClockCircleOutlined /> Start Time:
                </Text>{" "}
                <Text>
                  {moment(data?.interview?.startDateTime).format(
                    "MMMM Do YYYY, h:mm A"
                  )}
                </Text>
              </Col>
              <Col span={12}>
                <Text strong>
                  <ClockCircleOutlined /> End Time:
                </Text>{" "}
                <Text>
                  {moment(data?.interview?.endDateTime).format(
                    "MMMM Do YYYY, h:mm A"
                  )}
                </Text>
              </Col>
            </Row>

            <Collapse>
              <Panel
                header={
                  <span>
                    <FileTextOutlined /> Description
                  </span>
                }
                key="1"
              >
                <div
                  className="bg-white rounded p-3 mb-2"
                  dangerouslySetInnerHTML={{
                    __html: data?.interview?.description
                  }}
                />
              </Panel>
            </Collapse>
          </div>

          <Divider>Attendee Details</Divider>

          <Collapse defaultActiveKey={"1"}>
            <Panel
              header={
                <span>
                  <TeamOutlined /> Candidates
                </span>
              }
              key="1"
            >
              {data?.interview?.internal_attendees?.length > 0 ? (
                <AttendeeList
                  attendees={data?.interview?.internal_attendees}
                  role="Candidate"
                  navigate={navigate}
                />
              ) : (
                <p>No candidates available.</p>
              )}
            </Panel>

            <Panel
              header={
                <span>
                  <TeamOutlined /> Interviewers
                </span>
              }
              key="2"
            >
              {data?.interview?.external_attendees?.length > 0 ? (
                <AttendeeList
                  attendees={data?.interview?.external_attendees}
                  role="Interviewer"
                />
              ) : (
                <p>No interviewers available.</p>
              )}
            </Panel>
          </Collapse>
          <Divider>Interview Status & Tracking Status</Divider>
          <Collapse defaultActiveKey={accordionOpen} expandIconPosition="right">
            <Panel header="Interview Status Tracking" key="2">
              <Tooltip
                title={
                  isStatusRestricted
                    ? "Updates are disabled for 'no show' or 'cancelled' interviews."
                    : ""
                }
              >
                <Form form={form} layout="vertical" disabled={isStatusRestricted}>
                  <Form.Item label="Interview Status" name="status">
                    <Select placeholder="Select a status" size="large">
                      <Option value="scheduled">Scheduled</Option>
                      <Option value="completed">Completed</Option>
                      <Option value="cancelled">Cancelled</Option>
                      <Option value="no show">No Show</Option>
                    </Select>
                  </Form.Item>
                  <Form.Item
                    label="On Track Morning Status?"
                    name={["tracking", "initialConfirmationStatus"]}
                  >
                    <Select size="large">
                      <Option value="pending">Pending</Option>
                      <Option value="on track">On Track</Option>
                      <Option value="off track">Off Track</Option>
                    </Select>
                  </Form.Item>
                  <Form.Item
                    label="On Track status before 30 minutes of meeting?"
                    name={["tracking", "finalConfirmationStatus"]}
                  >
                    <Select size="large">
                      <Option value="pending">Pending</Option>
                      <Option value="on track">On Track</Option>
                      <Option value="off track">Off Track</Option>
                    </Select>
                  </Form.Item>
                </Form>
              </Tooltip>
              <div className="py-3">
                <Tooltip
                  title={
                    isStatusRestricted
                      ? "Cannot save tracking for 'no show' or 'cancelled' interviews."
                      : ""
                  }
                >
                  <Button
                    size="large"
                    type="primary"
                    className={`w-full ${
                      isStatusRestricted ? "bg-gray-400" : "bg-teal-600"
                    }`}
                    onClick={handleDone}
                    loading={feedbackStatus?.loading}
                    disabled={isStatusRestricted}
                  >
                    Save Tracking
                  </Button>
                </Tooltip>
              </div>
            </Panel>
          </Collapse>
          <Divider>Interview Feedback</Divider>
          <Collapse defaultActiveKey={accordionOpen} expandIconPosition="right">
            <Panel
              header={
                <span>
                  <FileTextOutlined /> Feedback
                </span>
              }
              key="3"
            >
              <div className="mt-4">
                <Form
                  form={form}
                  layout="vertical"
                  onFinish={handleSubmitFeedback}
                >
                  <Form.Item
                    name={["feedback", "recruiter", "feedback"]}
                    label="Recruiter Feedback"
                  >
                    <Input.TextArea
                      rows={4}
                      placeholder="Enter recruiter feedback"
                      autoSize={{ minRows: 4, maxRows: 6 }}
                    />
                  </Form.Item>
                  <Form.Item
                    name={["feedback", "candidate", "feedback"]}
                    label="Candidate Feedback"
                  >
                    <Input.TextArea
                      rows={4}
                      placeholder="Enter candidate feedback"
                      autoSize={{ minRows: 4, maxRows: 6 }}
                    />
                  </Form.Item>
                  <Form.Item>
                    <Button
                      size="large"
                      type="primary"
                      className="w-full bg-teal-600"
                      htmlType="submit"
                      loading={feedbackStatus?.loading}
                    >
                      {data?.interview?.feedback?.recruiter?.status ===
                        "completed" ||
                      data?.interview?.feedback?.candidate?.status ===
                        "completed"
                        ? "Update Feedback"
                        : "Submit Feedback"}
                    </Button>
                  </Form.Item>
                </Form>
              </div>
            </Panel>
          </Collapse>

          {data?.interview?.isCreateCalendar && !joinHide && (
            <div className="sticky-bottom mt-4">
              <Tooltip
                title={
                  !joinEnabled
                    ? "The meeting is available 30 minutes before the start time"
                    : ""
                }
              >
                <Button
                  size="large"
                  type="primary"
                  disabled={!joinEnabled}
                  href={joinEnabled ? data?.interview?.meeting_url : "#"}
                  target="_blank"
                  className={`w-full ${
                    joinEnabled ? "bg-teal-600" : "bg-gray-400"
                  }`}
                >
                  <ClockCircleOutlined /> Join Meeting
                </Button>
              </Tooltip>
            </div>
          )}
        </>
      )}
    </Drawer>
  );
};

export default InterviewDetailsDrawer;
