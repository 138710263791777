import React, { useState, useCallback } from "react";
import {
  Button,
  Divider,
  Input,
  Layout,
  Select,
  DatePicker,
  Collapse,
  Form,
  Drawer
} from "antd";
import { useDispatch } from "react-redux";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { getJobAction } from "../../redux/actions/common";
import { isMentorpalTeamUser } from "../../Utils/Common";
import ClientCustomSelect from "../../Utils/ClientSelect/clientSelect";
import moment from "moment";
import("../../Assets/css/filter.css");

const { Sider } = Layout;
const { Option } = Select;
const { Panel } = Collapse;

const FilterForm = ({
  filters,
  handleFilterChange,
  handleReset,
  handleChange
}) => (
  <Form
    layout="vertical"
    onValuesChange={(changedValues) => {
      Object.keys(changedValues).forEach((key) =>
        handleFilterChange(key, changedValues[key])
      );
    }}
    initialValues={filters}
  >
    <div className="d-flex justify-content-between align-items-center mb-3">
      <Button type="ghost">
        <h2 className="m-0 ml-2 mt-1">Filters</h2>
      </Button>
      <Button className="cursor-pointer mt-3" onClick={handleReset}>
        Reset all
      </Button>
    </div>
    <Collapse
      defaultActiveKey={["1", "2", "3", "4", "5", "6", "7", "8", "9"]}
      expandIconPosition="right"
      bordered={false}
      style={{ background: "#ffff", border: "none" }}
    >
      <Panel header="Search" key="1">
        <Input
          value={filters.job_title}
          placeholder="Search jobs"
          onChange={(value) => handleChange("job_title", value.target.value)}
          size="large"
        />
      </Panel>

      {isMentorpalTeamUser() && (
        <Panel header="Client" key="2">
          <ClientCustomSelect
            type={"filter"}
            value={filters.client_id}
            onChange={(value) => handleChange("client_id", value)}
          />
        </Panel>
      )}
      <Panel header="Status" key="3">
        <Select
          placeholder="Select Status"
          value={filters.status}
          onChange={(value) => handleChange("status", value)}
          style={{ width: "100%" }}
          size="large"
        >
          <Option value="">All</Option>
          <Option value="active">Active</Option>
          <Option value="inactive">InActive</Option>
          <Option value="on hold">on Hold</Option>
          <Option value="delete">Delete</Option>
        </Select>
      </Panel>

      {/* <Panel header="Created At" key="4">
        <DatePicker
          style={{ width: "100%" }}
          value={filters.createdAt ? moment(filters.createdAt) : null}
          onChange={(date, dateString) => handleChange("createdAt", dateString)}
          format="YYYY-MM-DD"
          size="large"
        />
      </Panel> */}
    </Collapse>
  </Form>
);

const JobComponent = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();

  const pathSegments = location.pathname.split("/");
  const lastChild = pathSegments[pathSegments.length - 1];

  const [filters, setFilters] = useState({
    job_title: "",
    job_location: "",
    job_type: "",
    application_deadline: null,
    createdAt: null,
    client_id: "",
    remote_options: "",
    status: "active"
  });

  const [isMobileFilterDrawerVisible, setIsMobileFilterDrawerVisible] =
    useState(false);

  const handleChange = useCallback(
    (key, value) => {
      setFilters((prevFilters) => ({
        ...prevFilters,
        [key]: value
      }));

      dispatch(
        getJobAction({
          page: 1,
          limit: 10,
          ...filters,
          [key]: value
        })
      );
    },
    [dispatch, filters]
  );

  const handleReset = useCallback(() => {
    setFilters({
      job_title: "",
      job_location: "",
      createdAt: null,
      job_type: "",
      application_deadline: null,
      client_id: "",
      remote_options: ""
    });
    dispatch(getJobAction({ page: 1, limit: 10 }));
  }, [dispatch]);

  const toggleFilters = () =>
    setIsMobileFilterDrawerVisible(!isMobileFilterDrawerVisible);
  const closeDrawer = () => setIsMobileFilterDrawerVisible(false);
  const isMobile = window.innerWidth <= 768;

  return (
    <>
      <div className="bg-white">
        <div className="px-4">
          <div className="flag">
            <div className="flag__section">
              <a className="external incognito-link inline-block mb-m mt-m">
                <h1 className="js-main-header-title main-header__title">
                  {lastChild === "jobs" ? "Jobs" : "Jobs Reports"}
                </h1>
              </a>
            </div>
            {lastChild === "jobs" && (
              <div className="flag__section flag__section--tight  js-main-header-actions">
                <Button
                  type="button"
                  className="custom-button"
                  onClick={() => navigate("/dashboard/create-job")}
                >
                  Create a new job
                </Button>
              </div>
            )}
          </div>
        </div>
      </div>

      <Layout style={{ background: "#fff", minHeight: "100vh" }}>
        {!isMobile && (
          <Sider width={300} className="site-layout-background bg-light">
            <div className="px-3 bg-light" style={{ height: "100vh" }}>
              <FilterForm
                filters={filters}
                handleReset={handleReset}
                handleChange={handleChange}
              />
            </div>
          </Sider>
        )}

        {isMentorpalTeamUser() && (
          <Panel key="2">
            <ClientCustomSelect
              type={"filter"}
              value={filters.client_id}
              onChange={(value) => handleChange("client_id", value)}
            />
          </Panel>
        )}

        <Panel key="4">
          <DatePicker
            style={{ width: "100%" }}
            onChange={(date, dateString) =>
              handleChange("createdAt", dateString)
            }
            format="YYYY-MM-DD"
            size="large"
          />
        </Panel>
        <Layout>
          <Button
            className="filter-toggle-button"
            onClick={toggleFilters}
            type="primary"
            style={{ backgroundColor: "#00756a" }}
          >
            Apply Filters
          </Button>
          <Outlet />
        </Layout>
      </Layout>
      <Drawer
        visible={isMobileFilterDrawerVisible}
        onClose={closeDrawer}
        placement="left"
        width="80%"
        className="filter-drawer"
        bodyStyle={{ paddingTop: 0 }}
      >
        <FilterForm
          filters={filters}
          handleReset={handleReset}
          handleChange={handleChange}
        />
      </Drawer>
    </>
  );
};

export default JobComponent;
