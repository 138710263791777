export const languages = [
  {
    label: "English",
    value: "English"
  },
  {
    label: "Hindi - हिन्दी",
    value: "Hindi - हिन्दी"
  },
  {
    label: "English (Australia)",
    value: "English (Australia)"
  },
  {
    label: "English (Canada)",
    value: "English (Canada)"
  },
  {
    label: "English (India)",
    value: "English (India)"
  },
  {
    label: "English (United States)",
    value: "English (United States)"
  },
  {
    label: "Afrikaans",
    value: "Afrikaans"
  },
  {
    label: "Albanian - shqip",
    value: "Albanian - shqip"
  },
  {
    label: "Amharic - አማርኛ",
    value: "Amharic - አማርኛ"
  },
  {
    label: "Arabic - العربية",
    value: "Arabic - العربية"
  },
  {
    label: "Aragonese - aragonés",
    value: "Aragonese - aragonés"
  },
  {
    label: "Armenian - հայերեն",
    value: "Armenian - հայերեն"
  },
  {
    label: "Asturian - asturianu",
    value: "Asturian - asturianu"
  },
  {
    label: "Azerbaijani - azərbaycan dili",
    value: "Azerbaijani - azərbaycan dili"
  },
  {
    label: "Basque - euskara",
    value: "Basque - euskara"
  },
  {
    label: "Belarusian - беларуская",
    value: "Belarusian - беларуская"
  },
  {
    label: "Bengali - বাংলা",
    value: "Bengali - বাংলা"
  },
  {
    label: "Bosnian - bosanski",
    value: "Bosnian - bosanski"
  },
  {
    label: "Breton - brezhoneg",
    value: "Breton - brezhoneg"
  },
  {
    label: "Bulgarian - български",
    value: "Bulgarian - български"
  },
  {
    label: "Catalan - català",
    value: "Catalan - català"
  },
  {
    label: "Central Kurdish - کوردی (دەستنوسی عەرەبی)",
    value: "Central Kurdish - کوردی (دەستنوسی عەرەبی)"
  },
  {
    label: "Chinese - 中文",
    value: "Chinese - 中文"
  },
  {
    label: "Chinese (Hong Kong) - 中文（香港）",
    value: "Chinese (Hong Kong) - 中文（香港）"
  },
  {
    label: "Chinese (Simplified) - 中文（简体）",
    value: "Chinese (Simplified) - 中文（简体）"
  },
  {
    label: "Chinese (Traditional) - 中文（繁體）",
    value: "Chinese (Traditional) - 中文（繁體）"
  },
  {
    label: "Corsican",
    value: "Corsican"
  },
  {
    label: "Croatian - hrvatski",
    value: "Croatian - hrvatski"
  },
  {
    label: "Czech - čeština",
    value: "Czech - čeština"
  },
  {
    label: "Danish - dansk",
    value: "Danish - dansk"
  },
  {
    label: "Dutch - Nederlands",
    value: "Dutch - Nederlands"
  },
  {
    label: "English (New Zealand)",
    value: "English (New Zealand)"
  },
  {
    label: "English (South Africa)",
    value: "English (South Africa)"
  },
  {
    label: "English (United Kingdom)",
    value: "English (United Kingdom)"
  },
  {
    label: "Esperanto - esperanto",
    value: "Esperanto - esperanto"
  },
  {
    label: "Estonian - eesti",
    value: "Estonian - eesti"
  },
  {
    label: "Faroese - føroyskt",
    value: "Faroese - føroyskt"
  },
  {
    label: "Filipino",
    value: "Filipino"
  },
  {
    label: "Finnish - suomi",
    value: "Finnish - suomi"
  },
  {
    label: "French - français",
    value: "French - français"
  },
  {
    label: "French (Canada) - français (Canada)",
    value: "French (Canada) - français (Canada)"
  },
  {
    label: "French (France) - français (France)",
    value: "French (France) - français (France)"
  },
  {
    label: "French (Switzerland) - français (Suisse)",
    value: "French (Switzerland) - français (Suisse)"
  },
  {
    label: "Galician - galego",
    value: "Galician - galego"
  },
  {
    label: "Georgian - ქართული",
    value: "Georgian - ქართული"
  },
  {
    label: "German - Deutsch",
    value: "German - Deutsch"
  },
  {
    label: "German (Austria) - Deutsch (Österreich)",
    value: "German (Austria) - Deutsch (Österreich)"
  },
  {
    label: "German (Germany) - Deutsch (Deutschland)",
    value: "German (Germany) - Deutsch (Deutschland)"
  },
  {
    label: "German (Liechtenstein) - Deutsch (Liechtenstein)",
    value: "German (Liechtenstein) - Deutsch (Liechtenstein)"
  },
  {
    label: "German (Switzerland) - Deutsch (Schweiz)",
    value: "German (Switzerland) - Deutsch (Schweiz)"
  },
  {
    label: "Greek - Ελληνικά",
    value: "Greek - Ελληνικά"
  },
  {
    label: "Guarani",
    value: "Guarani"
  },
  {
    label: "Gujarati - ગુજરાતી",
    value: "Gujarati - ગુજરાતી"
  },
  {
    label: "Hausa",
    value: "Hausa"
  },
  {
    label: "Hawaiian - ʻŌlelo Hawaiʻi",
    value: "Hawaiian - ʻŌlelo Hawaiʻi"
  },
  {
    label: "Hebrew - עברית",
    value: "Hebrew - עברית"
  },
  {
    label: "Hungarian - magyar",
    value: "Hungarian - magyar"
  },
  {
    label: "Icelandic - íslenska",
    value: "Icelandic - íslenska"
  },
  {
    label: "Indonesian - Indonesia",
    value: "Indonesian - Indonesia"
  },
  {
    label: "Interlingua",
    value: "Interlingua"
  },
  {
    label: "Irish - Gaeilge",
    value: "Irish - Gaeilge"
  },
  {
    label: "Italian - italiano",
    value: "Italian - italiano"
  },
  {
    label: "Italian (Italy) - italiano (Italia)",
    value: "Italian (Italy) - italiano (Italia)"
  },
  {
    label: "Italian (Switzerland) - italiano (Svizzera)",
    value: "Italian (Switzerland) - italiano (Svizzera)"
  },
  {
    label: "Japanese - 日本語",
    value: "Japanese - 日本語"
  },
  {
    label: "Kannada - ಕನ್ನಡ",
    value: "Kannada - ಕನ್ನಡ"
  },
  {
    label: "Kazakh - қазақ тілі",
    value: "Kazakh - қазақ тілі"
  },
  {
    label: "Khmer - ខ្មែរ",
    value: "Khmer - ខ្មែរ"
  },
  {
    label: "Korean - 한국어",
    value: "Korean - 한국어"
  },
  {
    label: "Kurdish - Kurdî",
    value: "Kurdish - Kurdî"
  },
  {
    label: "Kyrgyz - кыргызча",
    value: "Kyrgyz - кыргызча"
  },
  {
    label: "Lao - ລາວ",
    value: "Lao - ລາວ"
  },
  {
    label: "Latin",
    value: "Latin"
  },
  {
    label: "Latvian - latviešu",
    value: "Latvian - latviešu"
  },
  {
    label: "Lingala - lingála",
    value: "Lingala - lingála"
  },
  {
    label: "Lithuanian - lietuvių",
    value: "Lithuanian - lietuvių"
  },
  {
    label: "Macedonian - македонски",
    value: "Macedonian - македонски"
  },
  {
    label: "Malay - Bahasa Melayu",
    value: "Malay - Bahasa Melayu"
  },
  {
    label: "Malayalam - മലയാളം",
    value: "Malayalam - മലയാളം"
  },
  {
    label: "Maltese - Malti",
    value: "Maltese - Malti"
  },
  {
    label: "Marathi - मराठी",
    value: "Marathi - मराठी"
  },
  {
    label: "Mongolian - монгол",
    value: "Mongolian - монгол"
  },
  {
    label: "Nepali - नेपाली",
    value: "Nepali - नेपाली"
  },
  {
    label: "Norwegian - norsk",
    value: "Norwegian - norsk"
  },
  {
    label: "Norwegian Bokmål - norsk bokmål",
    value: "Norwegian Bokmål - norsk bokmål"
  },
  {
    label: "Norwegian Nynorsk - nynorsk",
    value: "Norwegian Nynorsk - nynorsk"
  },
  {
    label: "Occitan",
    value: "Occitan"
  },
  {
    label: "Oriya - ଓଡ଼ିଆ",
    value: "Oriya - ଓଡ଼ିଆ"
  },
  {
    label: "Oromo - Oromoo",
    value: "Oromo - Oromoo"
  },
  {
    label: "Pashto - پښتو",
    value: "Pashto - پښتو"
  },
  {
    label: "Persian - فارسی",
    value: "Persian - فارسی"
  },
  {
    label: "Polish - polski",
    value: "Polish - polski"
  },
  {
    label: "Portuguese - português",
    value: "Portuguese - português"
  },
  {
    label: "Portuguese (Brazil) - português (Brasil)",
    value: "Portuguese (Brazil) - português (Brasil)"
  },
  {
    label: "Portuguese (Portugal) - português (Portugal)",
    value: "Portuguese (Portugal) - português (Portugal)"
  },
  {
    label: "Punjabi - ਪੰਜਾਬੀ",
    value: "Punjabi - ਪੰਜਾਬੀ"
  },
  {
    label: "Quechua",
    value: "Quechua"
  },
  {
    label: "Romanian - română",
    value: "Romanian - română"
  },
  {
    label: "Romanian (Moldova) - română (Moldova)",
    value: "Romanian (Moldova) - română (Moldova)"
  },
  {
    label: "Romansh - rumantsch",
    value: "Romansh - rumantsch"
  },
  {
    label: "Russian - русский",
    value: "Russian - русский"
  },
  {
    label: "Scottish Gaelic",
    value: "Scottish Gaelic"
  },
  {
    label: "Serbian - српски",
    value: "Serbian - српски"
  },
  {
    label: "Serbo - Croatian",
    value: "Serbo - Croatian"
  },
  {
    label: "Shona - chiShona",
    value: "Shona - chiShona"
  },
  {
    label: "Sindhi",
    value: "Sindhi"
  },
  {
    label: "Sinhala - සිංහල",
    value: "Sinhala - සිංහල"
  },
  {
    label: "Slovak - slovenčina",
    value: "Slovak - slovenčina"
  },
  {
    label: "Slovenian - slovenščina",
    value: "Slovenian - slovenščina"
  },
  {
    label: "Somali - Soomaali",
    value: "Somali - Soomaali"
  },
  {
    label: "Southern Sotho",
    value: "Southern Sotho"
  },
  {
    label: "Spanish - español",
    value: "Spanish - español"
  },
  {
    label: "Spanish (Argentina) - español (Argentina)",
    value: "Spanish (Argentina) - español (Argentina)"
  },
  {
    label: "Spanish (Latin America) - español (Latinoamérica)",
    value: "Spanish (Latin America) - español (Latinoamérica)"
  },
  {
    label: "Spanish (Mexico) - español (México)",
    value: "Spanish (Mexico) - español (México)"
  },
  {
    label: "Spanish (Spain) - español (España)",
    value: "Spanish (Spain) - español (España)"
  },
  {
    label: "Spanish (United States) - español (Estados Unidos)",
    value: "Spanish (United States) - español (Estados Unidos)"
  },
  {
    label: "Sundanese",
    value: "Sundanese"
  },
  {
    label: "Swahili - Kiswahili",
    value: "Swahili - Kiswahili"
  },
  {
    label: "Swedish - svenska",
    value: "Swedish - svenska"
  },
  {
    label: "Tajik - тоҷикӣ",
    value: "Tajik - тоҷикӣ"
  },
  {
    label: "Tamil - தமிழ்",
    value: "Tamil - தமிழ்"
  },
  {
    label: "Tatar",
    value: "Tatar"
  },
  {
    label: "Telugu - తెలుగు",
    value: "Telugu - తెలుగు"
  },
  {
    label: "Thai - ไทย",
    value: "Thai - ไทย"
  },
  {
    label: "Tigrinya - ትግርኛ",
    value: "Tigrinya - ትግርኛ"
  },
  {
    label: "Tongan - lea fakatonga",
    value: "Tongan - lea fakatonga"
  },
  {
    label: "Turkish - Türkçe",
    value: "Turkish - Türkçe"
  },
  {
    label: "Turkmen",
    value: "Turkmen"
  },
  {
    label: "Twi",
    value: "Twi"
  },
  {
    label: "Ukrainian - українська",
    value: "Ukrainian - українська"
  },
  {
    label: "Urdu - اردو",
    value: "Urdu - اردو"
  },
  {
    label: "Uyghur",
    value: "Uyghur"
  },
  {
    label: "Uzbek - o‘zbek",
    value: "Uzbek - o‘zbek"
  },
  {
    label: "Vietnamese - Tiếng Việt",
    value: "Vietnamese - Tiếng Việt"
  },
  {
    label: "Walloon - wa",
    value: "Walloon - wa"
  },
  {
    label: "Welsh - Cymraeg",
    value: "Welsh - Cymraeg"
  },
  {
    label: "Western Frisian",
    value: "Western Frisian"
  },
  {
    label: "Xhosa",
    value: "Xhosa"
  },
  {
    label: "Yiddish",
    value: "Yiddish"
  },
  {
    label: "Yoruba - Èdè Yorùbá",
    value: "Yoruba - Èdè Yorùbá"
  },
  {
    label: "Zulu - isiZulu",
    value: "Zulu - isiZulu"
  }
];

export const countries = [
  {
    label: "India",
    value: "India"
  },
  {
    label: "Afghanistan",
    value: "Afghanistan"
  },
  {
    label: "Aland Islands",
    value: "Aland Islands"
  },
  {
    label: "Albania",
    value: "Albania"
  },
  {
    label: "Algeria",
    value: "Algeria"
  },
  {
    label: "American Samoa",
    value: "American Samoa"
  },
  {
    label: "Andorra",
    value: "Andorra"
  },
  {
    label: "Angola",
    value: "Angola"
  },
  {
    label: "Anguilla",
    value: "Anguilla"
  },
  {
    label: "Antarctica",
    value: "Antarctica"
  },
  {
    label: "Antigua and Barbuda",
    value: "Antigua and Barbuda"
  },
  {
    label: "Argentina",
    value: "Argentina"
  },
  {
    label: "Armenia",
    value: "Armenia"
  },
  {
    label: "Aruba",
    value: "Aruba"
  },
  {
    label: "Australia",
    value: "Australia"
  },
  {
    label: "Austria",
    value: "Austria"
  },
  {
    label: "Azerbaijan",
    value: "Azerbaijan"
  },
  {
    label: "Bahamas",
    value: "Bahamas"
  },
  {
    label: "Bahrain",
    value: "Bahrain"
  },
  {
    label: "Bangladesh",
    value: "Bangladesh"
  },
  {
    label: "Barbados",
    value: "Barbados"
  },
  {
    label: "Belarus",
    value: "Belarus"
  },
  {
    label: "Belgium",
    value: "Belgium"
  },
  {
    label: "Belize",
    value: "Belize"
  },
  {
    label: "Benin",
    value: "Benin"
  },
  {
    label: "Bermuda",
    value: "Bermuda"
  },
  {
    label: "Bhutan",
    value: "Bhutan"
  },
  {
    label: "Bolivia",
    value: "Bolivia"
  },
  {
    label: "Bonaire, Sint Eustatius and Saba",
    value: "Bonaire, Sint Eustatius and Saba"
  },
  {
    label: "Bosnia and Herzegovina",
    value: "Bosnia and Herzegovina"
  },
  {
    label: "Botswana",
    value: "Botswana"
  },
  {
    label: "Bouvet Island",
    value: "Bouvet Island"
  },
  {
    label: "Brazil",
    value: "Brazil"
  },
  {
    label: "British Indian Ocean Territory",
    value: "British Indian Ocean Territory"
  },
  {
    label: "Brunei Darussalam",
    value: "Brunei Darussalam"
  },
  {
    label: "Bulgaria",
    value: "Bulgaria"
  },
  {
    label: "Burkina Faso",
    value: "Burkina Faso"
  },
  {
    label: "Burundi",
    value: "Burundi"
  },
  {
    label: "Cambodia",
    value: "Cambodia"
  },
  {
    label: "Cameroon",
    value: "Cameroon"
  },
  {
    label: "Canada",
    value: "Canada"
  },
  {
    label: "Cape Verde",
    value: "Cape Verde"
  },
  {
    label: "Cayman Islands",
    value: "Cayman Islands"
  },
  {
    label: "Central African Republic",
    value: "Central African Republic"
  },
  {
    label: "Chad",
    value: "Chad"
  },
  {
    label: "Chile",
    value: "Chile"
  },
  {
    label: "China",
    value: "China"
  },
  {
    label: "Christmas Island",
    value: "Christmas Island"
  },
  {
    label: "Cocos (Keeling) Islands",
    value: "Cocos (Keeling) Islands"
  },
  {
    label: "Colombia",
    value: "Colombia"
  },
  {
    label: "Comoros",
    value: "Comoros"
  },
  {
    label: "Congo",
    value: "Congo"
  },
  {
    label: "Congo, Democratic Republic of the Congo",
    value: "Congo, Democratic Republic of the Congo"
  },
  {
    label: "Cook Islands",
    value: "Cook Islands"
  },
  {
    label: "Costa Rica",
    value: "Costa Rica"
  },
  {
    label: "Cote D'Ivoire",
    value: "Cote D'Ivoire"
  },
  {
    label: "Croatia",
    value: "Croatia"
  },
  {
    label: "Cuba",
    value: "Cuba"
  },
  {
    label: "Curacao",
    value: "Curacao"
  },
  {
    label: "Cyprus",
    value: "Cyprus"
  },
  {
    label: "Czech Republic",
    value: "Czech Republic"
  },
  {
    label: "Denmark",
    value: "Denmark"
  },
  {
    label: "Djibouti",
    value: "Djibouti"
  },
  {
    label: "Dominica",
    value: "Dominica"
  },
  {
    label: "Dominican Republic",
    value: "Dominican Republic"
  },
  {
    label: "Ecuador",
    value: "Ecuador"
  },
  {
    label: "Egypt",
    value: "Egypt"
  },
  {
    label: "El Salvador",
    value: "El Salvador"
  },
  {
    label: "Equatorial Guinea",
    value: "Equatorial Guinea"
  },
  {
    label: "Eritrea",
    value: "Eritrea"
  },
  {
    label: "Estonia",
    value: "Estonia"
  },
  {
    label: "Ethiopia",
    value: "Ethiopia"
  },
  {
    label: "Falkland Islands (Malvinas)",
    value: "Falkland Islands (Malvinas)"
  },
  {
    label: "Faroe Islands",
    value: "Faroe Islands"
  },
  {
    label: "Fiji",
    value: "Fiji"
  },
  {
    label: "Finland",
    value: "Finland"
  },
  {
    label: "France",
    value: "France"
  },
  {
    label: "French Guiana",
    value: "French Guiana"
  },
  {
    label: "French Polynesia",
    value: "French Polynesia"
  },
  {
    label: "French Southern Territories",
    value: "French Southern Territories"
  },
  {
    label: "Gabon",
    value: "Gabon"
  },
  {
    label: "Gambia",
    value: "Gambia"
  },
  {
    label: "Georgia",
    value: "Georgia"
  },
  {
    label: "Germany",
    value: "Germany"
  },
  {
    label: "Ghana",
    value: "Ghana"
  },
  {
    label: "Gibraltar",
    value: "Gibraltar"
  },
  {
    label: "Greece",
    value: "Greece"
  },
  {
    label: "Greenland",
    value: "Greenland"
  },
  {
    label: "Grenada",
    value: "Grenada"
  },
  {
    label: "Guadeloupe",
    value: "Guadeloupe"
  },
  {
    label: "Guam",
    value: "Guam"
  },
  {
    label: "Guatemala",
    value: "Guatemala"
  },
  {
    label: "Guernsey",
    value: "Guernsey"
  },
  {
    label: "Guinea",
    value: "Guinea"
  },
  {
    label: "Guinea-Bissau",
    value: "Guinea-Bissau"
  },
  {
    label: "Guyana",
    value: "Guyana"
  },
  {
    label: "Haiti",
    value: "Haiti"
  },
  {
    label: "Heard Island and Mcdonald Islands",
    value: "Heard Island and Mcdonald Islands"
  },
  {
    label: "Holy See (Vatican City State)",
    value: "Holy See (Vatican City State)"
  },
  {
    label: "Honduras",
    value: "Honduras"
  },
  {
    label: "Hong Kong",
    value: "Hong Kong"
  },
  {
    label: "Hungary",
    value: "Hungary"
  },
  {
    label: "Iceland",
    value: "Iceland"
  },
  {
    label: "Indonesia",
    value: "Indonesia"
  },
  {
    label: "Iran, Islamic Republic of",
    value: "Iran, Islamic Republic of"
  },
  {
    label: "Iraq",
    value: "Iraq"
  },
  {
    label: "Ireland",
    value: "Ireland"
  },
  {
    label: "Isle of Man",
    value: "Isle of Man"
  },
  {
    label: "Israel",
    value: "Israel"
  },
  {
    label: "Italy",
    value: "Italy"
  },
  {
    label: "Jamaica",
    value: "Jamaica"
  },
  {
    label: "Japan",
    value: "Japan"
  },
  {
    label: "Jersey",
    value: "Jersey"
  },
  {
    label: "Jordan",
    value: "Jordan"
  },
  {
    label: "Kazakhstan",
    value: "Kazakhstan"
  },
  {
    label: "Kenya",
    value: "Kenya"
  },
  {
    label: "Kiribati",
    value: "Kiribati"
  },
  {
    label: "Korea, Democratic People's Republic of",
    value: "Korea, Democratic People's Republic of"
  },
  {
    label: "Korea, Republic of",
    value: "Korea, Republic of"
  },
  {
    label: "Kosovo",
    value: "Kosovo"
  },
  {
    label: "Kuwait",
    value: "Kuwait"
  },
  {
    label: "Kyrgyzstan",
    value: "Kyrgyzstan"
  },
  {
    label: "Lao People's Democratic Republic",
    value: "Lao People's Democratic Republic"
  },
  {
    label: "Latvia",
    value: "Latvia"
  },
  {
    label: "Lebanon",
    value: "Lebanon"
  },
  {
    label: "Lesotho",
    value: "Lesotho"
  },
  {
    label: "Liberia",
    value: "Liberia"
  },
  {
    label: "Libyan Arab Jamahiriya",
    value: "Libyan Arab Jamahiriya"
  },
  {
    label: "Liechtenstein",
    value: "Liechtenstein"
  },
  {
    label: "Lithuania",
    value: "Lithuania"
  },
  {
    label: "Luxembourg",
    value: "Luxembourg"
  },
  {
    label: "Macao",
    value: "Macao"
  },
  {
    label: "Macedonia, the Former Yugoslav Republic of",
    value: "Macedonia, the Former Yugoslav Republic of"
  },
  {
    label: "Madagascar",
    value: "Madagascar"
  },
  {
    label: "Malawi",
    value: "Malawi"
  },
  {
    label: "Malaysia",
    value: "Malaysia"
  },
  {
    label: "Maldives",
    value: "Maldives"
  },
  {
    label: "Mali",
    value: "Mali"
  },
  {
    label: "Malta",
    value: "Malta"
  },
  {
    label: "Marshall Islands",
    value: "Marshall Islands"
  },
  {
    label: "Martinique",
    value: "Martinique"
  },
  {
    label: "Mauritania",
    value: "Mauritania"
  },
  {
    label: "Mauritius",
    value: "Mauritius"
  },
  {
    label: "Mayotte",
    value: "Mayotte"
  },
  {
    label: "Mexico",
    value: "Mexico"
  },
  {
    label: "Micronesia, Federated States of",
    value: "Micronesia, Federated States of"
  },
  {
    label: "Moldova, Republic of",
    value: "Moldova, Republic of"
  },
  {
    label: "Monaco",
    value: "Monaco"
  },
  {
    label: "Mongolia",
    value: "Mongolia"
  },
  {
    label: "Montenegro",
    value: "Montenegro"
  },
  {
    label: "Montserrat",
    value: "Montserrat"
  },
  {
    label: "Morocco",
    value: "Morocco"
  },
  {
    label: "Mozambique",
    value: "Mozambique"
  },
  {
    label: "Myanmar",
    value: "Myanmar"
  },
  {
    label: "Namibia",
    value: "Namibia"
  },
  {
    label: "Nauru",
    value: "Nauru"
  },
  {
    label: "Nepal",
    value: "Nepal"
  },
  {
    label: "Netherlands",
    value: "Netherlands"
  },
  {
    label: "Netherlands Antilles",
    value: "Netherlands Antilles"
  },
  {
    label: "New Caledonia",
    value: "New Caledonia"
  },
  {
    label: "New Zealand",
    value: "New Zealand"
  },
  {
    label: "Nicaragua",
    value: "Nicaragua"
  },
  {
    label: "Niger",
    value: "Niger"
  },
  {
    label: "Nigeria",
    value: "Nigeria"
  },
  {
    label: "Niue",
    value: "Niue"
  },
  {
    label: "Norfolk Island",
    value: "Norfolk Island"
  },
  {
    label: "Northern Mariana Islands",
    value: "Northern Mariana Islands"
  },
  {
    label: "Norway",
    value: "Norway"
  },
  {
    label: "Oman",
    value: "Oman"
  },
  {
    label: "Pakistan",
    value: "Pakistan"
  },
  {
    label: "Palau",
    value: "Palau"
  },
  {
    label: "Palestinian Territory, Occupied",
    value: "Palestinian Territory, Occupied"
  },
  {
    label: "Panama",
    value: "Panama"
  },
  {
    label: "Papua New Guinea",
    value: "Papua New Guinea"
  },
  {
    label: "Paraguay",
    value: "Paraguay"
  },
  {
    label: "Peru",
    value: "Peru"
  },
  {
    label: "Philippines",
    value: "Philippines"
  },
  {
    label: "Pitcairn",
    value: "Pitcairn"
  },
  {
    label: "Poland",
    value: "Poland"
  },
  {
    label: "Portugal",
    value: "Portugal"
  },
  {
    label: "Puerto Rico",
    value: "Puerto Rico"
  },
  {
    label: "Qatar",
    value: "Qatar"
  },
  {
    label: "Reunion",
    value: "Reunion"
  },
  {
    label: "Romania",
    value: "Romania"
  },
  {
    label: "Russian Federation",
    value: "Russian Federation"
  },
  {
    label: "Rwanda",
    value: "Rwanda"
  },
  {
    label: "Saint Barthelemy",
    value: "Saint Barthelemy"
  },
  {
    label: "Saint Helena",
    value: "Saint Helena"
  },
  {
    label: "Saint Kitts and Nevis",
    value: "Saint Kitts and Nevis"
  },
  {
    label: "Saint Lucia",
    value: "Saint Lucia"
  },
  {
    label: "Saint Martin",
    value: "Saint Martin"
  },
  {
    label: "Saint Pierre and Miquelon",
    value: "Saint Pierre and Miquelon"
  },
  {
    label: "Saint Vincent and the Grenadines",
    value: "Saint Vincent and the Grenadines"
  },
  {
    label: "Samoa",
    value: "Samoa"
  },
  {
    label: "San Marino",
    value: "San Marino"
  },
  {
    label: "Sao Tome and Principe",
    value: "Sao Tome and Principe"
  },
  {
    label: "Saudi Arabia",
    value: "Saudi Arabia"
  },
  {
    label: "Senegal",
    value: "Senegal"
  },
  {
    label: "Serbia",
    value: "Serbia"
  },
  {
    label: "Serbia and Montenegro",
    value: "Serbia and Montenegro"
  },
  {
    label: "Seychelles",
    value: "Seychelles"
  },
  {
    label: "Sierra Leone",
    value: "Sierra Leone"
  },
  {
    label: "Singapore",
    value: "Singapore"
  },
  {
    label: "Sint Maarten",
    value: "Sint Maarten"
  },
  {
    label: "Slovakia",
    value: "Slovakia"
  },
  {
    label: "Slovenia",
    value: "Slovenia"
  },
  {
    label: "Solomon Islands",
    value: "Solomon Islands"
  },
  {
    label: "Somalia",
    value: "Somalia"
  },
  {
    label: "South Africa",
    value: "South Africa"
  },
  {
    label: "South Georgia and the South Sandwich Islands",
    value: "South Georgia and the South Sandwich Islands"
  },
  {
    label: "South Sudan",
    value: "South Sudan"
  },
  {
    label: "Spain",
    value: "Spain"
  },
  {
    label: "Sri Lanka",
    value: "Sri Lanka"
  },
  {
    label: "Sudan",
    value: "Sudan"
  },
  {
    label: "Suriname",
    value: "Suriname"
  },
  {
    label: "Svalbard and Jan Mayen",
    value: "Svalbard and Jan Mayen"
  },
  {
    label: "Swaziland",
    value: "Swaziland"
  },
  {
    label: "Sweden",
    value: "Sweden"
  },
  {
    label: "Switzerland",
    value: "Switzerland"
  },
  {
    label: "Syrian Arab Republic",
    value: "Syrian Arab Republic"
  },
  {
    label: "Taiwan, Province of China",
    value: "Taiwan, Province of China"
  },
  {
    label: "Tajikistan",
    value: "Tajikistan"
  },
  {
    label: "Tanzania, United Republic of",
    value: "Tanzania, United Republic of"
  },
  {
    label: "Thailand",
    value: "Thailand"
  },
  {
    label: "Timor-Leste",
    value: "Timor-Leste"
  },
  {
    label: "Togo",
    value: "Togo"
  },
  {
    label: "Tokelau",
    value: "Tokelau"
  },
  {
    label: "Tonga",
    value: "Tonga"
  },
  {
    label: "Trinidad and Tobago",
    value: "Trinidad and Tobago"
  },
  {
    label: "Tunisia",
    value: "Tunisia"
  },
  {
    label: "Turkey",
    value: "Turkey"
  },
  {
    label: "Turkmenistan",
    value: "Turkmenistan"
  },
  {
    label: "Turks and Caicos Islands",
    value: "Turks and Caicos Islands"
  },
  {
    label: "Tuvalu",
    value: "Tuvalu"
  },
  {
    label: "Uganda",
    value: "Uganda"
  },
  {
    label: "Ukraine",
    value: "Ukraine"
  },
  {
    label: "United Arab Emirates",
    value: "United Arab Emirates"
  },
  {
    label: "United Kingdom",
    value: "United Kingdom"
  },
  {
    label: "United States",
    value: "United States"
  },
  {
    label: "United States Minor Outlying Islands",
    value: "United States Minor Outlying Islands"
  },
  {
    label: "Uruguay",
    value: "Uruguay"
  },
  {
    label: "Uzbekistan",
    value: "Uzbekistan"
  },
  {
    label: "Vanuatu",
    value: "Vanuatu"
  },
  {
    label: "Venezuela",
    value: "Venezuela"
  },
  {
    label: "Viet Nam",
    value: "Viet Nam"
  },
  {
    label: "Virgin Islands, British",
    value: "Virgin Islands, British"
  },
  {
    label: "Virgin Islands, U.s.",
    value: "Virgin Islands, U.s."
  },
  {
    label: "Wallis and Futuna",
    value: "Wallis and Futuna"
  },
  {
    label: "Western Sahara",
    value: "Western Sahara"
  },
  {
    label: "Yemen",
    value: "Yemen"
  },
  {
    label: "Zambia",
    value: "Zambia"
  },
  {
    label: "Zimbabwe",
    value: "Zimbabwe"
  }
];
export const Timezones_List = [
  {
    value: "(GMT-11:00) Midway Island",
    label: "Pacific/Midway"
  },
  {
    value: "(GMT-11:00) Samoa",
    label: "US/Samoa"
  },
  {
    value: "(GMT-10:00) Hawaii",
    label: "US/Hawaii"
  },
  {
    value: "(GMT-09:00) Alaska",
    label: "US/Alaska"
  },
  {
    value: "(GMT-08:00) Pacific Time (US & Canada)",
    label: "US/Pacific"
  },
  {
    value: "(GMT-08:00) Tijuana",
    label: "America/Tijuana"
  },
  {
    value: "(GMT-07:00) Arizona",
    label: "US/Arizona"
  },
  {
    value: "(GMT-07:00) Mountain Time (US & Canada)",
    label: "US/Mountain"
  },
  {
    value: "(GMT-07:00) Chihuahua",
    label: "America/Chihuahua"
  },
  {
    value: "(GMT-07:00) Mazatlan",
    label: "America/Mazatlan"
  },
  {
    value: "(GMT-06:00) Mexico City",
    label: "America/Mexico_City"
  },
  {
    value: "(GMT-06:00) Monterrey",
    label: "America/Monterrey"
  },
  {
    value: "(GMT-06:00) Saskatchewan",
    label: "Canada/Saskatchewan"
  },
  {
    value: "(GMT-06:00) Central Time (US & Canada)",
    label: "US/Central"
  },
  {
    value: "(GMT-05:00) Eastern Time (US & Canada)",
    label: "US/Eastern"
  },
  {
    value: "(GMT-05:00) Indiana (East)",
    label: "US/East-Indiana"
  },
  {
    value: "(GMT-05:00) Bogota",
    label: "America/Bogota"
  },
  {
    value: "(GMT-05:00) Lima",
    label: "America/Lima"
  },
  {
    value: "(GMT-04:30) Caracas",
    label: "America/Caracas"
  },
  {
    value: "(GMT-04:00) Atlantic Time (Canada)",
    label: "Canada/Atlantic"
  },
  {
    value: "(GMT-04:00) La Paz",
    label: "America/La_Paz"
  },
  {
    value: "(GMT-04:00) Santiago",
    label: "America/Santiago"
  },
  {
    value: "(GMT-03:30) Newfoundland",
    label: "Canada/Newfoundland"
  },
  {
    value: "(GMT-03:00) Buenos Aires",
    label: "America/Buenos_Aires"
  },
  {
    value: "(GMT-03:00) Greenland",
    label: "Greenland"
  },
  {
    value: "(GMT-02:00) Stanley",
    label: "Atlantic/Stanley"
  },
  {
    value: "(GMT-01:00) Azores",
    label: "Atlantic/Azores"
  },
  {
    value: "(GMT-01:00) Cape Verde Is.",
    label: "Atlantic/Cape_Verde"
  },
  {
    value: "(GMT) Casablanca",
    label: "Africa/Casablanca"
  },
  {
    value: "(GMT) Dublin",
    label: "Europe/Dublin"
  },
  {
    value: "(GMT) Lisbon",
    label: "Europe/Lisbon"
  },
  {
    value: "(GMT) London",
    label: "Europe/London"
  },
  {
    value: "(GMT) Monrovia",
    label: "Africa/Monrovia"
  },
  {
    value: "(GMT+01:00) Amsterdam",
    label: "Europe/Amsterdam"
  },
  {
    value: "(GMT+01:00) Belgrade",
    label: "Europe/Belgrade"
  },
  {
    value: "(GMT+01:00) Berlin",
    label: "Europe/Berlin"
  },
  {
    value: "(GMT+01:00) Bratislava",
    label: "Europe/Bratislava"
  },
  {
    value: "(GMT+01:00) Brussels",
    label: "Europe/Brussels"
  },
  {
    value: "(GMT+01:00) Budapest",
    label: "Europe/Budapest"
  },
  {
    value: "(GMT+01:00) Copenhagen",
    label: "Europe/Copenhagen"
  },
  {
    value: "(GMT+01:00) Ljubljana",
    label: "Europe/Ljubljana"
  },
  {
    value: "(GMT+01:00) Madrid",
    label: "Europe/Madrid"
  },
  {
    value: "(GMT+01:00) Paris",
    label: "Europe/Paris"
  },
  {
    value: "(GMT+01:00) Prague",
    label: "Europe/Prague"
  },
  {
    value: "(GMT+01:00) Rome",
    label: "Europe/Rome"
  },
  {
    value: "(GMT+01:00) Sarajevo",
    label: "Europe/Sarajevo"
  },
  {
    value: "(GMT+01:00) Skopje",
    label: "Europe/Skopje"
  },
  {
    value: "(GMT+01:00) Stockholm",
    label: "Europe/Stockholm"
  },
  {
    value: "(GMT+01:00) Vienna",
    label: "Europe/Vienna"
  },
  {
    value: "(GMT+01:00) Warsaw",
    label: "Europe/Warsaw"
  },
  {
    value: "(GMT+01:00) Zagreb",
    label: "Europe/Zagreb"
  },
  {
    value: "(GMT+02:00) Athens",
    label: "Europe/Athens"
  },
  {
    value: "(GMT+02:00) Bucharest",
    label: "Europe/Bucharest"
  },
  {
    value: "(GMT+02:00) Cairo",
    label: "Africa/Cairo"
  },
  {
    value: "(GMT+02:00) Harare",
    label: "Africa/Harare"
  },
  {
    value: "(GMT+02:00) Helsinki",
    label: "Europe/Helsinki"
  },
  {
    value: "(GMT+02:00) Istanbul",
    label: "Europe/Istanbul"
  },
  {
    value: "(GMT+02:00) Jerusalem",
    label: "Asia/Jerusalem"
  },
  {
    value: "(GMT+02:00) Kyiv",
    label: "Europe/Kiev"
  },
  {
    value: "(GMT+02:00) Minsk",
    label: "Europe/Minsk"
  },
  {
    value: "(GMT+02:00) Riga",
    label: "Europe/Riga"
  },
  {
    value: "(GMT+02:00) Sofia",
    label: "Europe/Sofia"
  },
  {
    value: "(GMT+02:00) Tallinn",
    label: "Europe/Tallinn"
  },
  {
    value: "(GMT+02:00) Vilnius",
    label: "Europe/Vilnius"
  },
  {
    value: "(GMT+03:00) Baghdad",
    label: "Asia/Baghdad"
  },
  {
    value: "(GMT+03:00) Kuwait",
    label: "Asia/Kuwait"
  },
  {
    value: "(GMT+03:00) Nairobi",
    label: "Africa/Nairobi"
  },
  {
    value: "(GMT+03:00) Riyadh",
    label: "Asia/Riyadh"
  },
  {
    value: "(GMT+03:00) Moscow",
    label: "Europe/Moscow"
  },
  {
    value: "(GMT+03:30) Tehran",
    label: "Asia/Tehran"
  },
  {
    value: "(GMT+04:00) Baku",
    label: "Asia/Baku"
  },
  {
    value: "(GMT+04:00) Volgograd",
    label: "Europe/Volgograd"
  },
  {
    value: "(GMT+04:00) Muscat",
    label: "Asia/Muscat"
  },
  {
    value: "(GMT+04:00) Tbilisi",
    label: "Asia/Tbilisi"
  },
  {
    value: "(GMT+04:00) Yerevan",
    label: "Asia/Yerevan"
  },
  {
    value: "(GMT+04:30) Kabul",
    label: "Asia/Kabul"
  },
  {
    value: "(GMT+05:00) Karachi",
    label: "Asia/Karachi"
  },
  {
    value: "(GMT+05:00) Tashkent",
    label: "Asia/Tashkent"
  },
  {
    value: "(GMT+05:30) Calcutta",
    label: "Asia/Calcutta"
  },
  {
    value: "(GMT+05:45) Kathmandu",
    label: "Asia/Kathmandu"
  },
  {
    value: "(GMT+06:00) Ekaterinburg",
    label: "Asia/Yekaterinburg"
  },
  {
    value: "(GMT+06:00) Almaty",
    label: "Asia/Almaty"
  },
  {
    value: "(GMT+06:00) Dhaka",
    label: "Asia/Dhaka"
  },
  {
    value: "(GMT+07:00) Novosibirsk",
    label: "Asia/Novosibirsk"
  },
  {
    value: "(GMT+07:00) Bangkok",
    label: "Asia/Bangkok"
  },
  {
    value: "(GMT+07:00) Jakarta",
    label: "Asia/Jakarta"
  },
  {
    value: "(GMT+08:00) Krasnoyarsk",
    label: "Asia/Krasnoyarsk"
  },
  {
    value: "(GMT+08:00) Chongqing",
    label: "Asia/Chongqing"
  },
  {
    value: "(GMT+08:00) Hong Kong",
    label: "Asia/Hong_Kong"
  },
  {
    value: "(GMT+08:00) Kuala Lumpur",
    label: "Asia/Kuala_Lumpur"
  },
  {
    value: "(GMT+08:00) Perth",
    label: "Australia/Perth"
  },
  {
    value: "(GMT+08:00) Singapore",
    label: "Asia/Singapore"
  },
  {
    value: "(GMT+08:00) Taipei",
    label: "Asia/Taipei"
  },
  {
    value: "(GMT+08:00) Ulaan Bataar",
    label: "Asia/Ulaanbaatar"
  },
  {
    value: "(GMT+08:00) Urumqi",
    label: "Asia/Urumqi"
  },
  {
    value: "(GMT+09:00) Irkutsk",
    label: "Asia/Irkutsk"
  },
  {
    value: "(GMT+09:00) Seoul",
    label: "Asia/Seoul"
  },
  {
    value: "(GMT+09:00) Tokyo",
    label: "Asia/Tokyo"
  },
  {
    value: "(GMT+09:30) Adelaide",
    label: "Australia/Adelaide"
  },
  {
    value: "(GMT+09:30) Darwin",
    label: "Australia/Darwin"
  },
  {
    value: "(GMT+10:00) Yakutsk",
    label: "Asia/Yakutsk"
  },
  {
    value: "(GMT+10:00) Brisbane",
    label: "Australia/Brisbane"
  },
  {
    value: "(GMT+10:00) Canberra",
    label: "Australia/Canberra"
  },
  {
    value: "(GMT+10:00) Guam",
    label: "Pacific/Guam"
  },
  {
    value: "(GMT+10:00) Hobart",
    label: "Australia/Hobart"
  },
  {
    value: "(GMT+10:00) Melbourne",
    label: "Australia/Melbourne"
  },
  {
    value: "(GMT+10:00) Port Moresby",
    label: "Pacific/Port_Moresby"
  },
  {
    value: "(GMT+10:00) Sydney",
    label: "Australia/Sydney"
  },
  {
    value: "(GMT+11:00) Vladivostok",
    label: "Asia/Vladivostok"
  },
  {
    value: "(GMT+12:00) Magadan",
    label: "Asia/Magadan"
  },
  {
    value: "(GMT+12:00) Auckland",
    label: "Pacific/Auckland"
  },
  {
    value: "(GMT+12:00) Fiji",
    label: "Pacific/Fiji"
  }
];

export const masterMatchKeywords = [
  {
    role: "Software Development",
    keywords: [
      "JavaScript",
      "React",
      "Node.js",
      "Python",
      "Java",
      "C++",
      "SQL",
      "REST APIs",
      "Microservices",
      "Agile",
      "Git",
      "TypeScript",
      "HTML",
      "CSS",
      "Angular",
      "Vue.js",
      "Django",
      "Spring Boot",
      "Ruby on Rails",
      "Unit Testing",
      "CI/CD",
      "Cloud Computing",
      "DevOps",
      "Docker",
      "Kubernetes",
      "AWS",
      "Azure",
      "Firebase",
      "GraphQL",
      "MongoDB"
    ]
  },
  {
    role: "Data Science and Analytics",
    keywords: [
      "Machine Learning",
      "Data Analysis",
      "Python",
      "R",
      "SQL",
      "Data Visualization",
      "Hadoop",
      "TensorFlow",
      "Statistics",
      "Data Mining",
      "Big Data",
      "Spark",
      "Pandas",
      "NumPy",
      "Keras",
      "Deep Learning",
      "Natural Language Processing",
      "Data Wrangling",
      "Business Intelligence",
      "ETL",
      "Matplotlib",
      "Scikit-learn",
      "Tableau",
      "Power BI",
      "Clustering",
      "Classification",
      "Regression",
      "Predictive Modeling",
      "Data Engineering",
      "Jupyter Notebooks"
    ]
  },
  {
    role: "DevOps and Cloud",
    keywords: [
      "AWS",
      "Azure",
      "Docker",
      "Kubernetes",
      "Jenkins",
      "CI/CD",
      "Terraform",
      "Ansible",
      "CloudFormation",
      "Monitoring",
      "Linux",
      "Bash",
      "Shell Scripting",
      "Prometheus",
      "Grafana",
      "Infrastructure as Code",
      "ELK Stack",
      "Nginx",
      "Load Balancing",
      "Scaling",
      "Serverless",
      "Security",
      "Network Configuration",
      "Configuration Management",
      "Release Management",
      "Automation",
      "Cloud Security",
      "Log Management",
      "System Administration",
      "Containerization"
    ]
  },
  {
    role: "Marketing",
    keywords: [
      "SEO",
      "Content Marketing",
      "Social Media",
      "Google Analytics",
      "AdWords",
      "Brand Management",
      "Email Marketing",
      "Copywriting",
      "Market Research",
      "Campaign Management",
      "Digital Marketing",
      "PPC",
      "Influencer Marketing",
      "Marketing Automation",
      "Conversion Optimization",
      "Customer Acquisition",
      "Engagement Strategies",
      "Lead Generation",
      "CRM",
      "HubSpot",
      "Facebook Ads",
      "Instagram Ads",
      "Content Strategy",
      "Video Marketing",
      "Web Analytics",
      "Creative Design",
      "Public Relations",
      "Event Marketing",
      "User Experience",
      "Marketing Metrics"
    ]
  },
  {
    role: "Human Resources",
    keywords: [
      "Recruitment",
      "Employee Relations",
      "Payroll",
      "Talent Management",
      "HRIS",
      "Compliance",
      "Performance Management",
      "Training and Development",
      "Onboarding",
      "Benefits Administration",
      "Employee Engagement",
      "Succession Planning",
      "Labor Laws",
      "Conflict Resolution",
      "HR Metrics",
      "Employee Retention",
      "Diversity and Inclusion",
      "Compensation Management",
      "Organizational Development",
      "Workforce Planning",
      "HR Policies",
      "HR Strategy",
      "Job Descriptions",
      "Employee Surveys",
      "Exit Interviews",
      "Employee Recognition",
      "Team Building",
      "Workplace Safety",
      "HR Consulting",
      "Career Development"
    ]
  },
  {
    role: "Sales",
    keywords: [
      "Lead Generation",
      "Account Management",
      "CRM",
      "Salesforce",
      "Negotiation",
      "Cold Calling",
      "Pipeline Management",
      "B2B Sales",
      "Customer Acquisition",
      "Revenue Growth",
      "Sales Strategy",
      "Customer Relationship Management",
      "Sales Presentations",
      "Closing Deals",
      "Product Knowledge",
      "Market Research",
      "Prospecting",
      "Sales Targets",
      "Territory Management",
      "Client Retention",
      "Upselling",
      "Cross-Selling",
      "Sales Forecasting",
      "Networking",
      "Sales Training",
      "Customer Feedback",
      "Value Proposition",
      "Competitive Analysis",
      "Sales Reporting",
      "Sales Metrics"
    ]
  }
];
export const ProgrammingLanguage = [
  { value: "c", label: "C" },
  { value: "c++", label: "C++" },
  { value: "java", label: "Java" },
  { value: "python", label: "Python" },
  { value: "javaScript", label: "JavaScript" },
  { value: "ruby", label: "Ruby" },
  { value: "go", label: "Go" },
  { value: "swift", label: "Swift" },
  { value: "kotlin", label: "Kotlin" },
  { value: "rust", label: "Rust" },
  { value: "pHP", label: "PHP" },
  { value: "typeScript", label: "TypeScript" },
  { value: "haskell", label: "Haskell" },
  { value: "scala", label: "Scala" },
  { value: "perl", label: "Perl" },
  { value: "r", label: "R" },
  { value: "lua", label: "Lua" },
  { value: "d", label: "D" },
  { value: "scheme", label: "Scheme" },
  { value: "f#", label: "F#" },
  { value: "elixir", label: "Elixir" },
  { value: "c#", label: "C#" },
  { value: "bash", label: "Bash" },
  { value: "sql", label: "SQL" }
];

export const masterKeywords = [
  { value: "JavaScript", label: "JavaScript" },
  { value: "React", label: "React" },
  { value: "Node.js", label: "Node.js" },
  { value: "Python", label: "Python" },
  { value: "Java", label: "Java" },
  { value: "C++", label: "C++" },
  { value: "SQL", label: "SQL" },
  { value: "REST APIs", label: "REST APIs" },
  { value: "Microservices", label: "Microservices" },
  { value: "Agile", label: "Agile" },
  { value: "Git", label: "Git" },
  { value: "TypeScript", label: "TypeScript" },
  { value: "HTML", label: "HTML" },
  { value: "CSS", label: "CSS" },
  { value: "Angular", label: "Angular" },
  { value: "Vue.js", label: "Vue.js" },
  { value: "Django", label: "Django" },
  { value: "Spring Boot", label: "Spring Boot" },
  { value: "Ruby on Rails", label: "Ruby on Rails" },
  { value: "Unit Testing", label: "Unit Testing" },
  { value: "CI/CD", label: "CI/CD" },
  { value: "Cloud Computing", label: "Cloud Computing" },
  { value: "DevOps", label: "DevOps" },
  { value: "Docker", label: "Docker" },
  { value: "Kubernetes", label: "Kubernetes" },
  { value: "AWS", label: "AWS" },
  { value: "Azure", label: "Azure" },
  { value: "Firebase", label: "Firebase" },
  { value: "GraphQL", label: "GraphQL" },
  { value: "MongoDB", label: "MongoDB" },
  { value: "TensorFlow", label: "TensorFlow" },
  { value: "PyTorch", label: "PyTorch" },
  { value: "Machine Learning", label: "Machine Learning" },
  { value: "Deep Learning", label: "Deep Learning" },
  {
    value: "Natural Language Processing",
    label: "Natural Language Processing"
  },
  { value: "Computer Vision", label: "Computer Vision" },
  { value: "Artificial Intelligence", label: "Artificial Intelligence" },
  { value: "Big Data", label: "Big Data" },
  { value: "Data Mining", label: "Data Mining" },
  { value: "Data Science", label: "Data Science" },
  { value: "Blockchain", label: "Blockchain" },
  { value: "Cryptocurrency", label: "Cryptocurrency" },
  { value: "Cybersecurity", label: "Cybersecurity" },
  { value: "Ethical Hacking", label: "Ethical Hacking" },
  { value: "Internet of Things (IoT)", label: "Internet of Things (IoT)" },
  { value: "Augmented Reality", label: "Augmented Reality" },
  { value: "Virtual Reality", label: "Virtual Reality" },
  { value: "Quantum Computing", label: "Quantum Computing" },
  { value: "Robotics", label: "Robotics" },
  { value: "Automation", label: "Automation" },
  { value: "UI/UX Design", label: "UI/UX Design" },
  { value: "Product Management", label: "Product Management" },
  { value: "Project Management", label: "Project Management" },
  { value: "Leadership", label: "Leadership" },
  { value: "Problem Solving", label: "Problem Solving" },
  { value: "Creativity", label: "Creativity" },
  { value: "Communication Skills", label: "Communication Skills" },
  { value: "Teamwork", label: "Teamwork" },
  { value: "Time Management", label: "Time Management" },
  { value: "Agile Methodology", label: "Agile Methodology" },
  { value: "Scrum", label: "Scrum" },
  { value: "Kanban", label: "Kanban" },
  { value: "Continuous Integration", label: "Continuous Integration" },
  { value: "Continuous Deployment", label: "Continuous Deployment" },
  { value: "DevOps", label: "DevOps" },
  { value: "Microservices", label: "Microservices" },
  { value: "RESTful API", label: "RESTful API" },
  { value: "GraphQL", label: "GraphQL" },
  { value: "Serverless Architecture", label: "Serverless Architecture" },
  { value: "Containerization", label: "Containerization" },
  { value: "Docker", label: "Docker" },
  { value: "Kubernetes", label: "Kubernetes" },
  { value: "AWS", label: "AWS" },
  { value: "Azure", label: "Azure" },
  { value: "Google Cloud Platform", label: "Google Cloud Platform" },
  { value: "Microsoft Azure", label: "Microsoft Azure" },
  { value: "Amazon Web Services", label: "Amazon Web Services" },
  { value: "React Native", label: "React Native" },
  { value: "Flutter", label: "Flutter" },
  { value: "Ionic Framework", label: "Ionic Framework" },
  { value: "Mobile App Development", label: "Mobile App Development" },
  { value: "Responsive Web Design", label: "Responsive Web Design" },
  { value: "Cross-platform Development", label: "Cross-platform Development" },
  { value: "Frontend Development", label: "Frontend Development" },
  { value: "Backend Development", label: "Backend Development" },
  { value: "Full Stack Development", label: "Full Stack Development" },
  { value: "Web Development", label: "Web Development" },
  { value: "Software Engineering", label: "Software Engineering" },
  { value: "Machine Learning", label: "Machine Learning" },
  { value: "Artificial Intelligence", label: "Artificial Intelligence" },
  { value: "Data Science", label: "Data Science" },
  { value: "Big Data", label: "Big Data" },
  { value: "Blockchain", label: "Blockchain" },
  { value: "Cryptocurrency", label: "Cryptocurrency" },
  { value: "Cybersecurity", label: "Cybersecurity" },
  { value: "Ethical Hacking", label: "Ethical Hacking" },
  { value: "IoT (Internet of Things)", label: "IoT (Internet of Things)" },
  { value: "AR (Augmented Reality)", label: "AR (Augmented Reality)" },
  { value: "VR (Virtual Reality)", label: "VR (Virtual Reality)" },
  { value: "Quantum Computing", label: "Quantum Computing" },
  { value: "Edge Computing", label: "Edge Computing" },
  { value: "Bioinformatics", label: "Bioinformatics" },
  { value: "Genomics", label: "Genomics" },
  { value: "Biotechnology", label: "Biotechnology" },
  { value: "Robotics", label: "Robotics" },
  { value: "Autonomous Vehicles", label: "Autonomous Vehicles" },
  { value: "Renewable Energy", label: "Renewable Energy" },
  { value: "Green Technology", label: "Green Technology" },
  { value: "Remote Sensing", label: "Remote Sensing" },
  {
    value: "Geographic Information Systems (GIS)",
    label: "Geographic Information Systems (GIS)"
  },
  { value: "3D Printing", label: "3D Printing" },
  { value: "Nanotechnology", label: "Nanotechnology" },
  { value: "Wireless Communications", label: "Wireless Communications" },
  { value: "5G", label: "5G" },
  { value: "Fintech", label: "Fintech" },
  { value: "Insurtech", label: "Insurtech" },
  { value: "Healthtech", label: "Healthtech" },
  { value: "Edtech", label: "Edtech" },
  { value: "Aerospace Engineering", label: "Aerospace Engineering" },
  { value: "Agricultural Science", label: "Agricultural Science" },
  { value: "Anthropology", label: "Anthropology" },
  { value: "Archaeology", label: "Archaeology" },
  { value: "Astrophysics", label: "Astrophysics" },
  { value: "Biochemistry", label: "Biochemistry" },
  { value: "Chemical Engineering", label: "Chemical Engineering" },
  { value: "Civil Engineering", label: "Civil Engineering" },
  { value: "Cognitive Science", label: "Cognitive Science" },
  { value: "Criminology", label: "Criminology" },
  { value: "Ecology", label: "Ecology" },
  { value: "Economics", label: "Economics" },
  { value: "Environmental Science", label: "Environmental Science" },
  { value: "Geology", label: "Geology" },
  { value: "Linguistics", label: "Linguistics" },
  { value: "Marine Biology", label: "Marine Biology" },
  { value: "Materials Science", label: "Materials Science" },
  { value: "Mechanical Engineering", label: "Mechanical Engineering" },
  { value: "Meteorology", label: "Meteorology" },
  { value: "Neuroscience", label: "Neuroscience" },
  { value: "Nuclear Engineering", label: "Nuclear Engineering" },
  { value: "Oceanography", label: "Oceanography" },
  { value: "Paleontology", label: "Paleontology" },
  { value: "Philosophy", label: "Philosophy" },
  { value: "Political Science", label: "Political Science" },
  { value: "Psychology", label: "Psychology" },
  { value: "Sociology", label: "Sociology" },
  { value: "Statistics", label: "Statistics" },
  { value: "Zoology", label: "Zoology" },
  { value: "Artificial Intelligence", label: "Artificial Intelligence" },
  { value: "Blockchain Technology", label: "Blockchain Technology" },
  { value: "Computer Vision", label: "Computer Vision" },
  { value: "Data Analysis", label: "Data Analysis" },
  { value: "Digital Marketing", label: "Digital Marketing" },
  { value: "Electrical Engineering", label: "Electrical Engineering" },
  { value: "Fashion Design", label: "Fashion Design" },
  { value: "Game Development", label: "Game Development" },
  { value: "Human Resources", label: "Human Resources" },
  { value: "Information Security", label: "Information Security" },
  { value: "Machine Learning", label: "Machine Learning" },
  { value: "Mobile App Development", label: "Mobile App Development" },
  { value: "Network Administration", label: "Network Administration" },
  { value: "Product Management", label: "Product Management" },
  { value: "Quantum Computing", label: "Quantum Computing" },
  { value: "Robotics Engineering", label: "Robotics Engineering" },
  { value: "Software Development", label: "Software Development" },
  { value: "Supply Chain Management", label: "Supply Chain Management" },
  { value: "User Experience Design", label: "User Experience Design" },
  { value: "Virtual Reality", label: "Virtual Reality" }
];

export const MENTORPAL_OWNER_ROLES = [
  { label: "ATS Owner", value: "ats-owner" },
  { label: "ATS Admin", value: "ats-admin" },
  { label: "ATS Recruiter", value: "ats-recruiter" },
  { label: "Hiring Manager", value: "hiring-manager" },
  { label: "ATS Interviewer", value: "ats-interviewer" },
  { label: "Assessment Owner", value: "assessment-owner" },
  { label: "Assessment Admin", value: "assessment-admin" },
  { label: "Assessment Recruiter", value: "assessment-recruiter" },
  { label: "Mentorpal Admin", value: "mentorpal-admin" },
  {
    label: "Mentorpal Recruitment Manager",
    value: "mentorpal-recruitment-manager"
  },
  { label: "Mentorpal Recruiter", value: "mentorpal-recruiter" },
  { label: "Mentorpal Executive", value: "mentorpal-executive" }
];

export const ATS_OWNER_ROLES = [
  { label: "ATS Owner", value: "ats-owner" },
  { label: "ATS Admin", value: "ats-admin" },
  { label: "ATS Recruiter", value: "ats-recruiter" },
  { label: "Hiring Manager", value: "hiring-manager" },
  { label: "ATS Interviewer", value: "ats-interviewer" }
];
export const ASSESSMENT_OWNER_ROLES = [
  { label: "Assessment Owner", value: "assessment-owner" },
  { label: "Assessment Admin", value: "assessment-admin" },
  { label: "Assessment Recruiter", value: "assessment-recruiter" }
];

export const All_ROLES = [
  { label: "ATS Owner", value: "ats-owner" },
  { label: "ATS Admin", value: "ats-admin" },
  { label: "ATS Recruiter", value: "ats-recruiter" },
  { label: "Hiring Manager", value: "hiring-manager" },
  { label: "ATS Interviewer", value: "ats-interviewer" },
  { label: "Assessment Owner", value: "assessment-owner" },
  { label: "Assessment Admin", value: "assessment-admin" },
  { label: "Assessment Recruiter", value: "assessment-recruiter" },
  { label: "Mentorpal Owner", value: "mentorpal-owner" },
  { label: "Mentorpal Admin", value: "mentorpal-admin" },
  {
    label: "Mentorpal Recruitment Manager",
    value: "mentorpal-recruitment-manager"
  },
  { label: "Mentorpal Recruiter", value: "mentorpal-recruiter" },
  { label: "Mentorpal Executive", value: "mentorpal-executive" }
];
export const COMPANY_TYPES = [
  {
    label: "Information Technology (IT) and Software Development",
    value: "Technology"
  },
  { label: "Healthcare and Medical", value: "Medical" },
  { label: "Finance and Banking", value: "Finance" },
  { label: "Education and Training", value: "Education" },
  { label: "Marketing and Advertising", value: "Advertising" },
  { label: "Sales and Business Development", value: "Sales" },
  { label: "Engineering and Construction", value: "Engineering" },
  { label: "Retail and E-commerce", value: "Retail" },
  { label: "Manufacturing and Production", value: "Manufacturing" },
  { label: "Hospitality and Tourism", value: "Hospitality" },
  { label: "Telecommunications", value: "Telecommunications" },
  { label: "Media and Entertainment", value: "Media" },
  { label: "Legal Services", value: "Legal" },
  { label: "Human Resources and Recruitment", value: "HumanResources" },
  { label: "Customer Service and Support", value: "CustomerService" },
  { label: "Real Estate and Property Management", value: "RealEstate" },
  { label: "Transportation and Logistics", value: "Transportation" },
  { label: "Energy and Utilities", value: "Energy" },
  { label: "Government and Public Administration", value: "Government" },
  { label: "Non-profit and Social Services", value: "Nonprofit" },
  { label: "Agriculture and Farming", value: "Agriculture" },
  { label: "Construction and Architecture", value: "Construction" },
  { label: "Pharmaceuticals and Biotechnology", value: "Pharmaceuticals" },
  { label: "Automotive and Transportation", value: "Automotive" },
  { label: "Aerospace and Defense", value: "Aerospace" },
  { label: "Retail and Consumer Goods", value: "ConsumerGoods" },
  { label: "Food and Beverage", value: "FoodBeverage" },
  { label: "Textiles and Apparel", value: "Textiles" },
  { label: "Chemical Manufacturing", value: "Chemicals" },
  { label: "Consulting and Professional Services", value: "Consulting" },
  {
    label: "Property Development and Management",
    value: "PropertyDevelopment"
  },
  { label: "Entertainment and Leisure", value: "Leisure" },
  { label: "Health and Wellness", value: "Wellness" }
];
export const USER_STATUS_ENUMS = [
  { value: "signup", label: "Sign Up" },
  { value: "registered", label: "Registered" },
  { value: "hold", label: "On Hold" },
  { value: "suspended", label: "Suspended" },
  { value: "delete", label: "Deleted" },
  { value: "active", label: "Active" },
  { value: "inactive", label: "Inactive" }
];
export const INTERVIEW_ROUNDS = [
  { label: "Round 1", value: "Round 1" },
  { label: "Round 2", value: "Round 3" },
  { label: "Round 3", value: "Round 3" },
  { label: "Final Interview", value: "Final Interview" }
];
export const JOB_APPLICATION_STAGE = [
  { text: "Sourced", value: "sourced" },
  { text: "Applied", value: "applied" },
  { text: "Phone", value: "phone" },
  { text: "Assessment", value: "assessment" },
  { text: "Interview", value: "interview" },
  { text: "Offer", value: "offer" },
  { text: "Hired", value: "hired" },
  { text: "Disqualified", value: "disqualified" }
];
export const JOB_APPLICATION_STATUS = [
  { text: "Active", value: "active" },
  { text: "Inactive", value: "inactive" },
  { text: "Hold", value: "hold" },
  { text: "Delete", value: "delete" }
];
export const JOB_INTERVIEW_STATUS = [
  { text: "Scheduled", value: "scheduled" },
  { text: "Completed", value: "completed" },
  { text: "Cancelled", value: "cancelled" }
];

export const statusColor = {
  active: {
    backgroundColor: "#e8f5e9", // Light green
    color: "#005c53" // Main color
  },
  inactive: {
    backgroundColor: "#ffebee", // Light red (negative)
    color: "#c62828" // Dark red
  },
  "on hold": {
    backgroundColor: "#f1f8e9", // Very light green
    color: "#005c53" // Main color
  },
  scheduled: {
    backgroundColor: "#e1f5fe", // Light blue
    color: "#005c53" // Main color
  },
  pending: {
    backgroundColor: "#fff3e0", // Light orange (negative)
    color: "#ff8c00" // Dark orange
  },
  completed: {
    backgroundColor: "#e8f5e9", // Light green
    color: "#005c53" // Main color
  },
  "on track": {
    backgroundColor: "#e8f5e9", // Light green
    color: "#005c53" // Main color
  },
  cancelled: {
    backgroundColor: "#ffebee", // Light red
    color: "#c62828" // Dark red
  },
  'no show': {
    backgroundColor: "#ffebee", // Light red
    color: "#c62828" // Dark red
  },
  "off track": {
    backgroundColor: "#ffebee", // Light red
    color: "#c62828" // Dark red
  },
  published: {
    backgroundColor: "#e8f5e9", // Light green
    color: "#005c53" // Main color
  },
  unpublished: {
    backgroundColor: "#f1f1f1", // Light grey
    color: "#a0a0a0" // Darker grey
  },
  draft: {
    backgroundColor: "#e8f5e9", // Light green
    color: "#005c53" // Main color
  },
  delete: {
    backgroundColor: "#ffebee", // Light red (negative)
    color: "#c62828" // Dark red
  },
  signup: {
    backgroundColor: "#e8f5e9", // Light green
    color: "#005c53" // Main color
  },
  registered: {
    backgroundColor: "#e1f5fe", // Light blue
    color: "#005c53" // Main color
  },
  suspended: {
    backgroundColor: "#ffebee", // Light red (negative)
    color: "#c62828" // Dark red
  },
  resolved: {
    backgroundColor: "#e8f5e9", // Light green
    color: "#005c53" // Main color
  },
  unresolved: {
    backgroundColor: "#fff3e0", // Light orange (negative)
    color: "#ff8c00" // Dark orange
  },
  locked: {
    backgroundColor: "#ffebee", // Light red (negative)
    color: "#c62828" // Dark red
  }
};
