import { Disclosure } from "@headlessui/react";
import { Button, Card, DatePicker, Form, Input, message } from "antd";
import React, { useEffect, useState } from "react";
import { TailSpin } from "react-loader-spinner";
import { useDispatch, useSelector } from "react-redux";
import headerLogo from "../../Assets/img/e7s7pbbz.png";
import { updateUserDetailsAction } from "../../redux/actions/user";
import CustomSelect from "../../Utils/CustomSelect";
import { REACT_USER_ID } from "../Auth/tokenProvider";
import "../../Assets/css/custom.css";
import LocationSelect from "../../Utils/locationSelector";
import { countries, languages } from "../../Assets/data/common";
import ImageUploadComponent from "../Upload";

const Registration = () => {
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();

  const updateUserDetailReducer = useSelector(
    (state) => state.updateUserDetailReducer
  );

  useEffect(() => {
    const { loading, status, error, data } = updateUserDetailReducer;
    setLoading(loading);
    if (!loading && data && !error) {
      message.success("Registration completed!");
      window.location.href = "/dashboard/jobs";
    }
    if (!loading && !data && error) {
      console.warn("error", error);
      message.error(error);
    }
  }, [updateUserDetailReducer]);

  const [form] = Form.useForm();

  const onFinish = async () => {
    try {
      const values = await form.validateFields();
      const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

      const data = {
        user_id: REACT_USER_ID(),
        ...values,
        status: "active",
        timezone
      };

      dispatch(updateUserDetailsAction(data));
    } catch (error) {
      console.warn("Error:", error);
      message.error(error);
    }
  };

  return (
    <>
      {loading ? (
        <div
          style={{
            position: "fixed",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            zIndex: 9999
          }}
        >
          <TailSpin ariaLabel="Loading..." color="#005c53" />
        </div>
      ) : null}
      <Disclosure as="nav" className="bg-gray-800">
        {({ open }) => (
          <>
            <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
              <div className="flex h-16 items-center justify-between">
                <div className="flex items-center">
                  <div className="flex-shrink-0">
                    <img
                      style={{ cursor: "pointer" }}
                      className="h-8 w-full"
                      src={headerLogo}
                      alt="Your Company"
                    />
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
      </Disclosure>
      <div className="d-flex justify-content-center align-items-center ">
        <Card className="registration-card pb-20">
          <h2 className="text-center mb-4">Registration</h2>

          <Form
            form={form}
            layout="vertical"
            onFinish={onFinish}
            initialValues={""}
          >
            {/* Render form fields directly without steps */}
            {[
              {
                name: "photo",
                title: "Profile Photo",
                type: "file",
                file_type: "Photo",
                accept: "image/*",
                rules: [
                  { required: true, message: "Please upload Profile photo" }
                ]
              },
              {
                name: "contact",
                title: "Contact Number",
                type: "number",
                rules: [
                  {
                    len: 10,
                    message: "Contact Number must be exactly 10 digits"
                  }
                ]
              },
              {
                name: "address",
                title: "Address",
                type: "location"
              },
              {
                name: "country",
                title: "Country",
                type: "select",
                mode: "single",
                options: countries
              },
              {
                name: "dateOfBirth",
                title: "Date of Birth",
                disabledDate: false,
                type: "date"
              },
              {
                name: "languagePreference",
                title: "Languages Preference",
                type: "select",
                mode: "single",
                options: languages
              }
            ].map((field) => (
              <Form.Item
                key={field.name}
                name={field.name}
                label={field.title}
                rules={field.rules}
              >
                {field.type === "select" ? (
                  <CustomSelect field={field} form={form} />
                ) : field.type === "location" ? (
                  <LocationSelect field={field} form={form} />
                ) : field.type === "file" ? (
                  <ImageUploadComponent
                    uploadPath="hire_talent/profile/"
                    acceptedFileTypes="image/*"
                    uploadType="Photo"
                    onFileUpload={(profileImageUrl) =>
                      form.setFieldsValue({ [field.name]: profileImageUrl })
                    }
                  />
                ) : field.type === "date" ? (
                  <DatePicker
                    disabledDate={(current) =>
                      current &&
                      (field.disabledDate
                        ? current < new Date().setHours(0, 0, 0, 0)
                        : current > new Date().setHours(0, 0, 0, 0))
                    }
                    style={{ width: "100%" }}
                    size="large"
                  />
                ) : (
                  <Input type={field.type} size="large" />
                )}
              </Form.Item>
            ))}
          </Form>
        </Card>
        <footer className="footer d-block">
          <div
            className="button-container"
            style={{ display: "flex", justifyContent: "flex-end" }}
          >
            <Button
              loading={loading}
              className="custom-button"
              onClick={onFinish}
            >
              Submit
            </Button>
          </div>
        </footer>
      </div>
    </>
  );
};

export default Registration;
